import React from 'react';
import PropTypes from 'prop-types';
import figureRed from '../../../assets/images/icons/figure_red.svg';
import figureGrey from '../../../assets/images/icons/figure_grey.svg';
import figureYellow from '../../../assets/images/icons/figure_yellow.svg';

export const RiskFigures = ({invasiveRisk, conservativeRisk}) => {
  const aryImg = [];
  const invRisk = parseInt(invasiveRisk, 10);
  const consRisk = parseInt(conservativeRisk, 10);

  for (let i = 0; i < consRisk; i++) {
    aryImg.push(figureYellow);
  }

  for (let i = 0; i < invRisk - consRisk; i++) {
    aryImg.push(figureRed);
  }

  if (invRisk === 0) {
    for (let k = 0; k < 100 - consRisk; k++) {
      aryImg.push(figureGrey);
    }
  }
  else {
    for (let k = 0; k < 100 - invRisk; k++) {
      aryImg.push(figureGrey);
    }
  }

  return (
    <div>
      {aryImg.map((ele, idx) =>
        // eslint-disable-next-line react/no-array-index-key
        <span key={idx}>
          <img
            alt='Icon image'
            className='figure-style'
            src={ele}/>
          {(idx + 1) % 10 === 0 && <br/>}
        </span>)}
    </div>
  );
};

RiskFigures.propTypes = {
  conservativeRisk: PropTypes.string.isRequired,
  invasiveRisk: PropTypes.string.isRequired
};
