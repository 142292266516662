import React, {useEffect} from 'react';
import ROUTES from '../../../routes';
import BackNextButtons from '../../components/common/backNextButtons';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import WeighingInfo from '../../components/common/weighingInfo';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return <>
    <section>
      <div className='grid-container'>
        <h1>Helping you weigh the information</h1>
        <p>
          In the following screens you will see three main categories of information: potential benefits, potential
          harms, and what treatments might happen next for each option.
        </p>
        <p>
          Weighing the information from each of these three categories can help you make an informed decision about
          which treatment option is best for you.
        </p>
      </div>
    </section>
    <section>
      <div className='grid-container'>
        <WeighingInfo/>
      </div>
    </section>
    <BackNextButtons
      backButtonRoute={ROUTES.MY_RISK_FACTORS}
      nextButtonRoute={ROUTES.BENEFITS_OVERVIEW}/>
  </>;
};

export default Index;
