import {INITIAL_OPTIONS} from '../constants';
const initialState = null;

export default function heartCondition(state = initialState, action) {
  switch (action.type) {
  case INITIAL_OPTIONS.SAVE_INITIAL_OPTIONS: {
    return action.data.initialOption;
  }

  case INITIAL_OPTIONS.RESET_INITIAL_OPTIONS: {
    return initialState;
  }

  default:
    return state;
  }
}
