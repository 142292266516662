import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          Spertus JA, Jones PG, Maron DJ, O&apos;Brien SM, Reynolds HR, Rosenberg Y, Stone GW,
          Harrell FE Jr, Boden WE, Weintraub WS, Baloch K, Mavromatis K, Diaz A, Gosselin G, Newman JD,
          Mavromichalis S, Alexander KP, Cohen DJ, Bangalore S, Hochman JS, Mark DB; ISCHEMIA Research
          Group. Health-Status Outcomes with Invasive or Conservative Care in Coronary Disease. N Engl
          J Med. 2020 Apr 9;382(15):1408-1419. 14
        </p>
      </li>
      <li>
        <p>
          Spertus JA, Jones PG, Maron DJ, Mark DB, O&apos;Brien SM, Fleg JL, Reynolds HR, Stone GW,
          Sidhu MS, Chaitman BR, Chertow GM, Hochman JS, Bangalore S; ISCHEMIA-CKD Research Group.
          Health Status after Invasive or Conservative Care in Coronary and Advanced Kidney Disease.
          N Engl J Med. 2020 Apr 23;382(17):1619-1628.
        </p>
      </li>
    </ol>
  </div>;
