import React from 'react';

export const AdditionalInfo = () =>
  <p>
    Results from randomized control trials were used to inform treatment effect estimates for most of
    the outcomes presented in this decision aid, including effects on survival free of heart attack,
    and on chest pain symptoms, end-stage kidney disease, and likelihood of subsequent procedures
    (angioplasty or surgery). The overall rating of the strength of evidence for this outcome is
    “moderate” (meaning the true effect is probably close to the estimated effect). The certainty
    of this evidence was affected by imprecision (there is a wide range of plausible estimates
    around the best estimates of the effects of treatment) and inconsistency However, the certainty
    f this evidence was rated down due to imprecision (wide range of plausible estimates around the
    best estimates of the effects) and inconsistency (differences in effects on chest pain according
    to chest pain severity) resulting in an overall rating of the certainty of evidence for this
    outcome as “moderate” (the true effect is probably close to the estimated effect). Estimates
    for acute kidney injury requiring dialysis were obtained from observational studies resulting
    in a rating of the certainty of evidence as “low” for this outcome (the true effect might be
    markedly different from the estimated effect).
  </p>;
