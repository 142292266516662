import {CHOICES} from '../constants';

export const saveChoices = choices => ({
  type: CHOICES.SAVE_CHOICES,
  choices
});

export const resetChoices = () => ({
  type: CHOICES.RESET_CHOICES
});
