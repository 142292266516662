import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {HEART_CONDITIONS} from '../appConstants';
import ROUTES from '../../../routes';
import {
  BenefitsDetails as SCHDBenefitsDetails
} from '../../components/StableCoronaryHeartDisease/Benefits/benefitsDetails';
import {BenefitsDetails as HABenefitsDetails} from '../../components/HeartAttack/Benifits/benefitsDetails';
import BackNextButtons from '../../components/common/backNextButtons';
import {useHistory, useLocation} from 'react-router-dom';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const heartCondition = useSelector(state => state.heartCondition);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return (
    <>
      <section>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell text-left'>
              <h1>Potential benefits of the treatment options</h1>
              <h3 className='text-uppercase primary-color'>
                Your Personalized Comparisons - The benefits of each option:
              </h3>
              <p>
                The decision aid has personalized your risk factors based on your health factor information
                (age, sex, medical conditions, and lab tests). It will compare the likelihood of potential
                benefits for the two treatment options:
              </p>
            </div>
          </div>
        </div>
      </section>
      {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
        <HABenefitsDetails/>
      }
      {heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
        <SCHDBenefitsDetails/>
      }
      <BackNextButtons
        backButtonRoute={ROUTES.MY_RISK_FACTORS}
        nextButtonRoute={ROUTES.RISKS_OVERVIEW}/>
    </>
  );
};

export default Index;
