/* eslint-disable camelcase,max-len */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {_PRAIP_SWHA} from '../../../equations/stableCornaryBranch/likelihoodOfSurvivingWithoutHeartAttack/PRAIP_SWHA';
import {_PRACM_SWHA} from '../../../equations/stableCornaryBranch/likelihoodOfSurvivingWithoutHeartAttack/PRACM_SWHA';
import benefitsInfo from '../../../../assets/images/icons/benefits_info.svg';
import {
  _PRAIP_IOCP_above_30,
  _PRAIP_IOCP_below_30
} from '../../../equations/stableCornaryBranch/likelyImpactOnChestPain/PRAIP_IOCP';
import {
  _PRACM_IOCP_above_30,
  _PRACM_IOCP_below_30
} from '../../../equations/stableCornaryBranch/likelyImpactOnChestPain/PRACM_IOCP';
import {GLOSSARY_KEYS, HEALTH_FACTORS} from '../../../containers/appConstants';
import {References as SurvivingWithoutHeartAttackReferences} from './SurvivingWithoutHeartAttack/references';
import {AdditionalInfo as SurvivingWithoutHeartAttackAddInfo} from './SurvivingWithoutHeartAttack/additionalInfo';
import {Details as SurvivingWithoutHeartAttackDetails} from './SurvivingWithoutHeartAttack/details';
import {Details as ChestPainDetails} from './ChestPain/details';
import {References as ChestPainReferences} from './ChestPain/references';
import {AdditionalInfo as ChestPainAddInfo} from './ChestPain/additionalInfo';
import benefitsSymbol from '../../../../assets/images/icons/benefits_symbol.svg';
import {Tooltip} from '../../common/tooltip';
import {getFormattedResult} from '../../../utils/utils';

export const BenefitsDetails = () => {
  const healthFactors = useSelector(state => state.healthFactors);
  const [
    survivingWithoutHeartAttackInvasiveDetails,
    setSurvivingWithoutHeartAttackInvasiveDetails
  ] = useState({});
  const [
    survivingWithoutHeartAttackConservativeDetails,
    setSurvivingWithoutHeartAttackConservativeDetails
  ] = useState({});

  const [chestPainInvasiveDetails, setChestPainInvasiveDetails] = useState({});
  const [chestPainConservativeDetails, setChestPainConservativeDetails] = useState({});

  useEffect(() => {
    setSurvivingWithoutHeartAttackInvasiveDetails(_PRAIP_SWHA(healthFactors));
    setSurvivingWithoutHeartAttackConservativeDetails(_PRACM_SWHA(healthFactors));

    if (healthFactors.egfr >= 30) {
      setChestPainInvasiveDetails(_PRAIP_IOCP_above_30(healthFactors));
      setChestPainConservativeDetails(_PRACM_IOCP_above_30(healthFactors));
    }

    if (healthFactors.egfr < 30) {
      setChestPainInvasiveDetails(_PRAIP_IOCP_below_30(healthFactors));
      setChestPainConservativeDetails(_PRACM_IOCP_below_30(healthFactors));
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const renderBelowThirty = chestPain => {
    if (chestPain === HEALTH_FACTORS.DAILY || chestPain === HEALTH_FACTORS.WEEKLY) {
      return <div className='grid-x margin-top-1 align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={benefitsInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an invasive heart procedure may result in&nbsp;<span className='benefits-text'>10% less</span>&nbsp;
            chest pain at&nbsp;<span className='benefits-text'>3 months</span>&nbsp;than with optimal medication management
            alone, but&nbsp;<span className='benefits-text'>no difference</span>&nbsp;in chest pain symptoms remain
            at&nbsp;<span className='benefits-text'>one year</span>&nbsp;– a short term benefit that wears off over time.
          </p>
        </div>
      </div>;
    }

    if (chestPain === HEALTH_FACTORS.MONTHLY || chestPain === HEALTH_FACTORS.NONE) {
      return <div className='grid-x margin-top-1 align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={benefitsInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an invasive heart procedure results in&nbsp;<span className='benefits-text'>no difference</span>&nbsp;
            in chest pain symptoms at&nbsp;<span className='benefits-text'>3 months or one year</span>&nbsp;than with
            optimal medical therapy alone.
          </p>
        </div>
      </div>;
    }
  };

  return (
    <>
      <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
        <div className='grid-container'>
          <div className='text-center'>
            <h2>Likelihood of survival without a heart attack in the next year</h2>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(survivingWithoutHeartAttackInvasiveDetails.probability).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(survivingWithoutHeartAttackInvasiveDetails.probability).toFixed(0))}/100)
                  </p>
                </div>
              </div>
              <div className='cell'>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(survivingWithoutHeartAttackConservativeDetails.probability).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(survivingWithoutHeartAttackConservativeDetails.probability).toFixed(0))}/100)
                  </p>
                </div>
              </div>
            </div>
            <div className='grid-x align-center-middle'>
              <div className='cell shrink margin-0'>
                <img
                  alt='Icon image'
                  className='margin-right-1'
                  src={benefitsInfo}/>
              </div>
              <div className='cell auto margin-0 text-left'>
                <p className='margin-0'>
                  There is&nbsp;<span className='benefits-text'>no difference</span>&nbsp;in the likelihood of
                  survival without a heart attack in the next year with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;or&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>.
                </p>
              </div>
            </div>
          </div>
          {/* The below section is only visible on screen, not while printing */}
          <div className='grid-x text-left margin-vertical-1 hide-for-print'>
            <div className='cell bordered all-rounded-borders'>
              <details>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>What does this look like?</h3>
                </summary>
                <SurvivingWithoutHeartAttackDetails
                  conservativeProb={Number(survivingWithoutHeartAttackConservativeDetails.probability).toFixed(0)}
                  invasiveProb={Number(survivingWithoutHeartAttackInvasiveDetails.probability).toFixed(0)}
                />
              </details>
            </div>
          </div>
          {/* The below section is only visible while printing */}
          <div className='margin-vertical-2 padding-vertical-2 show-for-print bordered all-rounded-borders text-left'>
            <SurvivingWithoutHeartAttackDetails
              conservativeProb={Number(survivingWithoutHeartAttackConservativeDetails.probability).toFixed(0)}
              invasiveProb={Number(survivingWithoutHeartAttackInvasiveDetails.probability).toFixed(0)}
            />
            <div className='padding-horizontal-2'>
              <SurvivingWithoutHeartAttackAddInfo/>
            </div>
          </div>
          {/* The below section is only visible on screen */}
          <div className='grid-x text-left margin-vertical-1 hide-for-print'>
            <div className='cell bordered all-rounded-borders'>
              <details>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>
                    What is this information based on? (Show me the references)
                  </h3>
                </summary>
                <SurvivingWithoutHeartAttackReferences/>
              </details>
            </div>
          </div>
        </div>
      </section>
      <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
        <div className='grid-container'>
          <div className='text-center'>
            <h2>Likely impact on chest pain (at three months and one year)</h2>
            {
              healthFactors.egfr >= 30 &&
              <div>
                <div className='grid-x medium-up-2 margin-vertical-1'>
                  <div className='cell'>
                    <div className='card align-center border-right'>
                      <h4 className='margin-bottom-1 height-50-px'>
                        Invasive Heart Procedure plus<br/> Optimal Medication Management
                      </h4>
                      <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                        <div className='rounded inside-circle'>
                          <img
                            alt='Icon image'
                            src={benefitsSymbol}/>
                          <p className='margin-0'>
                            {getFormattedResult(Number(chestPainInvasiveDetails).toFixed(0))}%
                          </p>
                        </div>
                      </div>
                      <p>
                        ({getFormattedResult(Number(chestPainInvasiveDetails).toFixed(0))}/100)
                      </p>
                    </div>
                  </div>
                  <div className='cell'>
                    <div className='card align-center'>
                      <h4 className='margin-bottom-1 height-50-px'>
                        Optimal Medication Management
                      </h4>
                      <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                        <div className='rounded inside-circle'>
                          <img
                            alt='Icon image'
                            src={benefitsSymbol}/>
                          <p className='margin-0'>
                            {getFormattedResult(Number(chestPainConservativeDetails).toFixed(0))}%
                          </p>
                        </div>
                      </div>
                      <p>
                        ({getFormattedResult(Number(chestPainConservativeDetails).toFixed(0))}/100)
                      </p>
                    </div>
                  </div>
                </div>
                <div className='grid-x margin-top-1 align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={benefitsInfo}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Treatment with an&nbsp;
                      <Tooltip
                        id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                        title='invasive heart procedure'/>&nbsp;resulted in an additional
                      &nbsp;<span className='benefits-text'>
                        {chestPainInvasiveDetails - chestPainConservativeDetails} people being chest pain-free
                      </span>&nbsp;
                      for every&nbsp;<span className='benefits-text'>100 patients</span>&nbsp;
                      like you compared to&nbsp;
                      <Tooltip
                        id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                        title='optimal medication management'/>.
                    </p>
                  </div>
                </div>
              </div>
            }
            {
              healthFactors.egfr < 30 && renderBelowThirty(healthFactors.chestPainSymptoms)
            }
            {/* The below section is only visible on screen, not while printing */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>What does this look like?</h3>
                  </summary>
                  <ChestPainDetails
                    conservativeData={chestPainConservativeDetails}
                    invasiveData={chestPainInvasiveDetails}
                  />
                </details>
              </div>
            </div>
            {/* The below section is only visible while printing */}
            <div
              className='padding-vertical-2 show-for-print bordered all-rounded-borders text-left'
            >
              <ChestPainDetails
                conservativeData={chestPainConservativeDetails}
                invasiveData={chestPainInvasiveDetails}
              />
              <div className='padding-horizontal-2'>
                <ChestPainAddInfo/>
              </div>
            </div>
            {/* The below section is only visible on screen */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>
                      What is this information based on? (Show me the references)
                    </h3>
                  </summary>
                  <ChestPainReferences/>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
