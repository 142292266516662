import React from 'react';
import PropTypes from 'prop-types';
import {Figures} from '../../../common/figures';
import benefitsInfo from '../../../../../assets/images/icons/benefits_info.svg';
import figureBlue from '../../../../../assets/images/icons/figure_blue.svg';
import figureGreen from '../../../../../assets/images/icons/figure_green.svg';

export const Details = ({invasiveProb, conservativeProb}) =>
  <div className='grid-x padding-horizontal-2 padding-bottom-2 print-paddings'>
    <div className='cell'>
      <div className='padding-bottom-1'>
        <h2>Outcome: Likelihood of surviving the next year</h2>
        <p>
            These blocks of 100 faces show a best estimate of what happens to 100 people like you after 1
            year who chose different treatment options. Each face stands for 1 person. The colored
            areas show the estimated number of people like you affected.
        </p>
      </div>
      <div className='grid-x margin-bottom-2'>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={figureBlue}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Patients who survived the next year are highlighted in blue.
            </p>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={figureGreen}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Additional patients who benefited from an invasive heart procedure are highlighted in green.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='grid-x text-center page-break-before'>
          <div className='auto cell'>
            <h4 className='height-50-px'>Invasive Heart Procedure <br/>
                plus Optimal Medication Management</h4>
            <div className='card padding-top-1'>
              <Figures
                conservative={conservativeProb}
                invasive={invasiveProb}
              />
            </div>
          </div>
          <div className='auto cell'>
            <h4 className='height-50-px'>Optimal Medication Management</h4>
            <div className='card padding-top-1'>
              <Figures
                conservative={conservativeProb}
                invasive={0}
              />
            </div>
          </div>
        </div>
        <div className='grid-x margin-top-1 align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={benefitsInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Treatment with an invasive heart procedure avoided&nbsp;<span className='benefits-text'>
                { invasiveProb - conservativeProb }
              &nbsp;deaths for every 100 patients</span>&nbsp;like you compared to optimal medication management.

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>;

Details.propTypes = {
  conservativeProb: PropTypes.string.isRequired,
  invasiveProb: PropTypes.string.isRequired
};
