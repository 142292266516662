import React from 'react';
import MedicinesImage from '../../../assets/images/pexels-anna-shvets-3683074.jpg';
import {Tooltip} from '../common/tooltip';
import {GLOSSARY_KEYS} from '../../containers/appConstants';

const MedicationOnlyDetails = () =>
  <>
    <div className='grid-container padding-horizontal-2'>
      <div className='grid-x'>
        <div className='cell'>
          <h2>
            What other treatment options are there for coronary heart disease?
          </h2>
        </div>
      </div>
      <div className='text-center margin-3'>
        <img
          alt='medicines'
          className='img-print-styles'
          src={MedicinesImage}
          width={600}/>
      </div>
      <div>
        <p>
          The alternative to invasive management is to treat with medications that slow the heart rate, lower
          blood pressure, prevent blood clotting, dilate the blood vessels, and lower cholesterol.
          These medications can reduce symptoms, and improve outcomes and are thus standard treatments
          for all patients with stable coronary heart disease. This is typically referred to as&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
            title='optimal medication management'/>&nbsp;.
        </p>
        <p>
          If you choose optimal medication management, your doctor will likely perform other&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.NON_INVASIVE_TESTS}
            title='non-invasive tests '/>&nbsp;on
          your heart. If these test results are very abnormal or if you continue to have unacceptable chest pain
          despite the use of medications at maximum acceptable doses, an invasive approach might still be
          recommended later.
        </p>
      </div>
    </div>
  </>;

export default MedicationOnlyDetails;
