import React from 'react';

export const AdditionalInfo = () =>
  <>
    <p>
      Individualized estimates of survival without a heart attack were determined using multivariable
      prediction models developed from a population-based cohort of 17,236 adult patients with chronic
      kidney disease with a diagnosis of stable coronary heart disease in Alberta, Canada between
      2004-2017. Treatment effects for invasive heart procedures were then applied to these estimates
      for each participant to obtain personalized estimates of the outcome for an individual under the
      two treatment options (invasive heart procedure plus optimal medical therapy versus optimal
      medical therapy).
    </p>
    <p>
      Results from randomized control trials were used to inform treatment effect estimates for survival
      without a heart attack. The overall rating of the strength of evidence for this outcome is
      “moderate” (meaning the true effect is probably close to the estimated effect). The certainty
      of this evidence was affected by imprecision (there is a wide range of plausible estimates
      around the best estimates of the effect of treatment).
    </p>
  </>;
