import {MY_PRIORITIES} from '../constants';

export const saveMyPriorities = priorities => ({
  type: MY_PRIORITIES.SAVE_MY_PRIORITIES,
  priorities
});

export const resetMyPriorities = () => ({
  type: MY_PRIORITIES.RESET_MY_PRIORITIES
});
