import React from 'react';
import ROUTES from '../../../routes';
import {useSelector} from 'react-redux';
import HeartAttackEarlyInvasiveDetails from '../../components/HeartAttack/earlyInvasiveDetails';
import StableCoronaryHeartDiseaseEarlyInvasiveDetails from
'../../components/StableCoronaryHeartDisease/earlyInvasiveDetails';
import {HEART_CONDITIONS} from '../appConstants';
import BackNextButtons from '../../components/common/backNextButtons';

const Index = () => {
  const heartCondition = useSelector(state => state.heartCondition);

  return <>
    {
      heartCondition === HEART_CONDITIONS.HEART_ATTACK
        ? <HeartAttackEarlyInvasiveDetails/>
        : <StableCoronaryHeartDiseaseEarlyInvasiveDetails/>
    }
    <BackNextButtons
      backButtonRoute={ROUTES.INITIAL_OPTIONS}
      nextButtonRoute={ROUTES.MEDICATION_ONLY_DETAILS}/>
  </>;
};

export default Index;
