import React from 'react';

export const AdditionalInfo = () =>
  <p>
    Individualized estimates of risk of acute kidney injury requiring dialysis were determined using
    multivariable prediction models developed from a population-based cohort of 17,236 adult patients
    with chronic kidney disease with a diagnosis of stable coronary heart disease in Alberta, Canada
    between 2004-2017. Treatment effects for invasive heart procedures were obtained from observational
    studies. The overall rating of the strength of evidence for this outcome is “low” (meaning the true
    effect might be markedly different from the estimated effect). The certainty of this evidence was
    affected by risk of bias (because treatment effects were estimated from observational studies).
  </p>;
