import React from 'react';

export const SummaryNotes = () =>
  <section className='shadow all-rounded-borders white-bg-color margin-bottom-2'>
    <div className='grid-container'>
      <div className='cell large-9'>
        <h1>Notes</h1>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
        <hr className='notes-lines'/>
      </div>
    </div>
  </section>;
