import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import SPOR_LOGO from '../../../assets/images/SPOR logo.jpg';
import APPROACH_LOGO from '../../../assets/images/approach.jpg';
import CAN_SOLVE_LOGO from '../../../assets/images/Can-SOLVE logo colour.png';
import U_OF_CALGARY_LOGO from '../../../assets/images/uc-horz-rgb.png';
import U_OF_ALBERTA_LOGO from '../../../assets/images/UA-COLOUR.png';
import {useSelector} from 'react-redux';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return <div>
    <section>
      <div className='grid-container'>
        <h2 className='font-bold'>About This Tool</h2>
        <p>
          This decision aid was developed to provide patients with coronary heart disease and chronic kidney disease
          (CKD) information about their treatment options, share personalized risks and benefits of treatment options,
          and communicate the treatment preferences. The decision aid is intended to be used by patients and health
          care providers together within a shared decision-making framework to improve knowledge exchange of
          benefit/risk information, and communication of patient values and preferences.
        </p>
        <p>
          This decision aid was developed by the Can-SOLVE CKD Network APPROACH research team in collaboration
          with key stakeholders including patients, physicians, nurses, and health services researchers from
          across Canada.
        </p>
        <p>
          This work is a funded by the&nbsp;<a
            className='success-color'
            href='https://cansolveckd.ca/'
            rel='noreferrer'
            target='_blank'>Can-SOLVE CKD Chronic Disease Network</a>, supported by the&nbsp;<a
            className='success-color'
            href='https://cihr-irsc.gc.ca/'
            rel='noreferrer'
            target='_blank'>Canadian Institutes of Health Research Strategic Patient
          Oriented Research (SPOR) grant 20R26070</a>&nbsp;and the&nbsp;<a
            className='success-color'
            href='https://approach.org/'
            rel='noreferrer'
            target='_blank'>Alberta
          Provincial Project for Outcomes Assessment in Coronary Heart Disease (APPROACH)</a>.
        </p>
        <p>
          If you have any questions or would like to know more, please contact us at
          <a
            className='success-color'
            href='mailTo:info@myheartandckd.ca'>&nbsp;info@myheartandckd.ca.</a>

        </p>
      </div>
    </section>
    <section className='white-bg-color'>
      <div className='grid-container'>
        <div className='grid-x medium-up-3 align-middle align-center grid-margin-x grid-margin-y text-center'>
          <div className='cell small-logo'>
            <a
              href='https://approach.org/'
              rel='noreferrer'
              target='_blank'>
              <img src={APPROACH_LOGO}/>
            </a>
          </div>
          <div className='cell small-logo'>
            <a
              href='https://cihr-irsc.gc.ca/'
              rel='noreferrer'
              target='_blank'>
              <img src={SPOR_LOGO}/>
            </a>
          </div>
          <div className='cell small-logo'>
            <a
              href='https://cansolveckd.ca/'
              rel='noreferrer'
              target='_blank'>
              <img src={CAN_SOLVE_LOGO}/>
            </a>
          </div>
          <div className='cell small-logo'>
            <a
              href='https://www.ucalgary.ca/'
              rel='noreferrer'
              target='_blank'>
              <img src={U_OF_CALGARY_LOGO}/>
            </a>
          </div>
          <div className='cell small-logo'>
            <a
              href='https://www.ualberta.ca/'
              rel='noreferrer'
              target='_blank'>
              <img src={U_OF_ALBERTA_LOGO}/>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='grid-container'>
        <div className='grid-x small-up-2 align-center text-center'>
          <div className='cell'>
            <button
              className='hollow secondary button large rounded padding-horizontal-2'
              onClick={() => history.goBack()}
              type='button'>
              Back
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>;
};

export default Index;
