import React from 'react';
import {Tooltip} from '../../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../../containers/appConstants';

export const AdditionalInfo = () =>
  <p>
    Individualized estimates of survival for the next year were determined using multivariable
    prediction models developed from a population-based cohort of 11,980 adult patients with
    chronic kidney disease hospitalized with a heart attack in Alberta, Canada between 2004-2017.
    Treatment effects from invasive heart procedures were then applied to these risks for each
    participant to obtain personalized estimates of absolute survival for an individual under
    each of the two treatment options. Published systematic reviews of&nbsp;<Tooltip
      id={GLOSSARY_KEYS.RANDOMIZED_TRIALS}
      title='randomized trials'/>&nbsp;were
    used to determine the effects of the treatment option on survival. The overall rating of
    the strength of evidence for these outcomes is “moderate” (meaning the true effect is
    probably close to the estimated effect). The certainty of this evidence was affected by
    imprecision (meaning there is a wide range of plausible estimates around the best estimates
    of the effects of treatment) and inconsistency (meaning there are differences in effects
    between individual trials).
  </p>;
