import React, {useEffect} from 'react';
import {Switch, useHistory} from 'react-router-dom';
import NoRoute from './app/containers/404';
import ROUTES from './routes';
import RouteWrapper from './app/components/RouteWrapper';
import Home from '../src/app/containers/Home/Index';
import Disclaimer from './app/containers/Disclaimer';
import PrivacyPolicy from './app/containers/PrivacyPolicy';
import Introduction from './app/containers/Introduction';
import heartCondition from './app/containers/HeartCondition';
import HealthFactors from './app/containers/HealthFactors';
import ProceedOnPaper from './app/containers/ProceedOnPaper';
import AboutThisTool from './app/containers/AboutThisTool';
import InitialOptions from './app/containers/InitialOptions';
import EarlyInvasiveDetails from './app/containers/EarlyInvasiveDetails';
import MedicationOnlyDetails from './app/containers/MedicationOnlyDetails';
import MyRiskFactors from './app/containers/MyRiskFactors';
import WeighingInformation from './app/containers/WeighingInformation';
import BenefitsOverview from './app/containers/BenefitsOverview';
import RisksOverview from './app/containers/RisksOverview';
import SubsequentCareOverview from './app/containers/SubsequentCareOverview';
import MyPriorities from './app/containers/MyPriorities';
import Choices from './app/containers/Choices';
import DecisionAidSummary from './app/containers/DecisionAidSummary';
import SummaryReport from './app/containers/SummaryReport';
import Glossary from './app/containers/Glossary';
import NextSteps from './app/containers/NextSteps';

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => unlisten();
  }, []);
  return null;
};

const App = function() {
  return <main className='gradient'>
    <ScrollToTop/>
    <Switch>
      <RouteWrapper
        component={Home}
        exact
        path={ROUTES.ROOT}
        title='Home'
      />
      <RouteWrapper
        component={Introduction}
        exact
        path={ROUTES.INTRODUCTION}
        title='Decision Aid Or Not'
      />
      <RouteWrapper
        component={heartCondition}
        exact
        path={ROUTES.HEART_CONDITION}
        title='Heart Condition'
      />
      <RouteWrapper
        component={PrivacyPolicy}
        exact
        path={ROUTES.PRIVACY_POLICY}
        title='Privacy Policy'
      />
      <RouteWrapper
        component={Disclaimer}
        exact
        path={ROUTES.DISCLAIMER}
        title='Disclaimer'
      />
      <RouteWrapper
        component={HealthFactors}
        exact
        path={ROUTES.HEALTH_FACTORS}
        title='Health Factors'
      />
      <RouteWrapper
        component={ProceedOnPaper}
        exact
        path={ROUTES.PROCEED_ON_PAPER}
        title='Proceed On Paper'
      />
      <RouteWrapper
        component={InitialOptions}
        exact
        path={ROUTES.INITIAL_OPTIONS}
        title='Initial Options'
      />
      <RouteWrapper
        component={EarlyInvasiveDetails}
        exact
        path={ROUTES.EARLY_INVASIVE_DETAILS}
        title='Early invasive details'
      />
      <RouteWrapper
        component={MedicationOnlyDetails}
        exact
        path={ROUTES.MEDICATION_ONLY_DETAILS}
        title='Medication Only Details'
      />
      <RouteWrapper
        component={MyRiskFactors}
        exact
        path={ROUTES.MY_RISK_FACTORS}
        title='My Risk Factors'
      />
      <RouteWrapper
        component={WeighingInformation}
        exact
        path={ROUTES.WEIGHING_INFORMATION}
        title='Weighing information'
      />
      <RouteWrapper
        component={AboutThisTool}
        exact
        path={ROUTES.ABOUT_THIS_TOOL}
        title='ABOUT This Tool'
      />
      <RouteWrapper
        component={BenefitsOverview}
        exact
        path={ROUTES.BENEFITS_OVERVIEW}
        title='Benefits Overview'
      />
      <RouteWrapper
        component={RisksOverview}
        exact
        path={ROUTES.RISKS_OVERVIEW}
        title='Risks Overview'
      />
      <RouteWrapper
        component={SubsequentCareOverview}
        exact
        path={ROUTES.SUBSEQUENT_CARE_OVERVIEW}
        title='Subsequent Care'
      />
      <RouteWrapper
        component={MyPriorities}
        exact
        path={ROUTES.MY_PRIORITIES}
        title='My Priorities'
      />
      <RouteWrapper
        component={Choices}
        exact
        path={ROUTES.CHOICES}
        title='Choices'
      />
      <RouteWrapper
        component={NextSteps}
        exact
        path={ROUTES.NEXT_STEPS}
        title='Next Steps'
      />
      <RouteWrapper
        component={DecisionAidSummary}
        exact
        path={ROUTES.DECISION_AID_SUMMARY}
        title='Decision Aid Summary'
      />
      <RouteWrapper
        component={SummaryReport}
        exact
        path={ROUTES.SUMMARY_REPORT}
        title='Summary Report'
      />
      <RouteWrapper
        component={Glossary}
        exact
        path={ROUTES.GLOSSARY}
        title='Glossary'
      />
      <RouteWrapper
        component={NoRoute}
        path='*'
        title='404'
      />
    </Switch>
  </main>;
};

export default App;
