export const HEART_CONDITIONS = {
  HEART_ATTACK: 'Heart Attack',
  STABLE_CORONARY_HEART_DISEASE: 'Stable coronary heart disease'
};

export const HEALTH_FACTORS = {
  AGE: 'age',
  EGFR: 'eGFR',
  HEMOGLOBIN: 'hemoglobin',
  NSTEMI: 'NSTEMI',
  UNSTABLE_ANGINA: 'Unstable_Angina',
  MALE: 'male',
  FEMALE: 'female',
  NORMAL: 'normal',
  MILD: 'mild',
  HEAVY: 'heavy',
  DIABETES_MELLITUS: 'Diabetes',
  CONGESTIVE_HEART_FAILURE: 'Charlson_CHF',
  CHRONIC_PULMONARY_DISEASE: 'Charlson_COPD',
  LIVER_DISEASE: 'Liver_Disease',
  MYOCARDIAL_INFARCTION: 'Myocardial_Infarction',
  PERIPHERAL_VASCULAR_DISEASE: 'Charlson_PVD',
  PRIOR_CARDIAC_CATHETERIZATION: 'Prior_Invasive',
  NONE: 'None',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
  CHEST_PAIN_SYMPTOMS: 'chestPainSymptoms',
  DIAGNOSIS: 'diagnosis',
  ALBUMINURIA: 'albuminuria',
  CLINICAL_HISTORY: 'clinicalHistory',
  INVASIVE: 'invasive',
  CONSERVATIVE: 'conservative',
  DONOT_KNOW: 'I don\'t know'
};

// The below values ae just used to display detailed values while displaying patient risk factors
/* eslint-disable camelcase */
export const HEALTH_FACTORS_VALUES = {
  NSTEMI: 'Non-ST Elevation Myocardial Infarction (heart attack)',
  Unstable_Angina: 'Unstable Angina',
  male: 'Male',
  female: 'Female',
  normal: 'Normal (Albumin/Creatinine ratio < 3mg/mmoL or urine dipstick NEGATIVE)',
  mild: 'Mild (Albumin/Creatinine ratio 3-30mg/mmoL or urine dipstick TRACE/1+)',
  heavy: 'Heavy (Albumin/Creatinine ratio > 30mg/mmoL or urine dipstick >= 2+)',
  Diabetes: 'Diabetes Mellitus',
  Charlson_CHF: 'Congestive Heart Failure',
  Charlson_COPD: 'Chronic Obstructive Pulmonary Disease',
  Liver_Disease: 'Liver Disease',
  Myocardial_Infarction: 'Myocardial Infarction',
  Charlson_PVD: 'Peripheral Vascular Disease',
  Prior_Invasive: 'Prior Cardiac Catheterization, Angioplasty, or Coronary Artery Bypass Surgery'
};

export const MENU_ITEMS = {
  HOME: 'Home',
  INTRODUCTION: 'Introduction',
  HEART_CONDITIONS: 'Heart Conditions',
  HEALTH_FACTORS: 'Health Factors',
  PROCEED_ON_PAPER: 'Proceed on Paper / Screen',
  INITIAL_OPTIONS: 'Your Options',
  MY_RISK_FACTORS: 'My Risk Factors',
  WEIGHING_INFORMATION: 'Weighing Information',
  BENEFITS_OVERVIEW: 'Benefits Overview',
  RISKS_OVERVIEW: 'Risks Overview',
  SUBSEQUENT_CARE: 'Subsequent Care',
  MY_PRIORITIES: 'My Priorities',
  DECISION_AID_SUMMARY: 'Decision Aid Summary',
  SUMMARY_REPORT: 'Summary Report',
  CHOICES: 'Choices',
  NEXT_STEPS: 'Next Steps',
  ABOUT: 'About',
  GLOSSARY: 'Glossary',
  DISCLAIMER: 'Disclaimer',
  PRIVACY_POLICY: 'Privacy Policy'
};

export const CHOICES = {
  CHOICES: 'choices',
  INVASIVE_HEART_PROCEDURE: 'Invasive Heart Procedure plus Optimal Medication Management',
  OPTIMAL_MEDICATION_MANAGEMENT: 'Optimal Medication Management',
  DISCUSS_WITH_DOCTOR: 'I need to discuss further with my doctor',
  DISCUSS_WITH_FAMILY: 'I need to discuss further with my family',
  NEED_MORE_INFO: 'I need more information about my options'
};

export const HEART_ATTACK_PRIORITIES = {
  LIVE_LONGER: 'Live longer',
  AVOID_HEART_ATTACK: 'Avoid another heart attack',
  AVOID_DIALYSIS: 'Avoid dialysis or end-stage kidney failure',
  AVOID_ANGIOGRAM: 'Avoid an angiogram, angioplasty procedure, or heart surgery'
};

export const STABLE_CORONARY_HEART_DISEASE_PRIORITIES = {
  REDUCE_CHEST_PAIN: 'Reduce frequency of chest pain or amount of medication needed for chest pain',
  DO_I_HAVE_SERIOUS_CORONARY_DISEASE: 'Determine whether or not I have a serious form of coronary heart' +
    ' disease that could impact my eligibility for a kidney transplant',
  AVOID_KIDNEY_FAILURE: 'Avoid end stage kidney failure and its impact on quality of life from treatment' +
    ' such as dialysis',
  AVOID_HEART_SURGERY: 'Avoid an angiogram, angioplasty procedure, or heart surgery'
};

export const NEXT_STEPS = {
  YES: 'YES',
  NO: 'NO',
  KNOW_POTENTIAL_BENEFITS_AND_RISKS: 'KNOW_POTENTIAL_BENEFITS_AND_RISKS',
  BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU: 'BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU',
  HAVE_ENOUGH_SUPPORT_AND_ADVICE: 'HAVE_ENOUGH_SUPPORT_AND_ADVICE',
  SURE_ABOUT_BEST_CHOICE_FOR_YOU: 'SURE_ABOUT_BEST_CHOICE_FOR_YOU'
};

export const GLOSSARY_KEYS = {
  ANGIOGRAM: 'angiogram',
  ANGIOPLASTY: 'angioplasty',
  CHRONIC_KIDNEY_DISEASE: 'chronic_kidney_disease',
  OPTIMAL_MEDICATION_MANAGEMENT: 'optimal_medication_management',
  DIALYSIS: 'dialysis',
  HEART_ATTACK: 'heart_attack',
  MEDICATIONS: 'medications',
  INVASIVE_HEART_PROCEDURE: 'invasive_heart_procedure',
  ANGINA: 'angina',
  END_STAGE_KIDNEY_FAILURE: 'end_stage_kidney_failure',
  EGFR: 'egfr',
  ALBUMINURIA: 'albuminuria',
  CORONARY_ARTERY_BYPASS_SURGERY: 'coronary_artery_bypass_surgery',
  NON_INVASIVE_TESTS: 'non_invasive_tests',
  ACUTE_KIDNEY_INJURY: 'acute_kidney_injury',
  MULTIVARIABLE_PREDICTION_MODEL: 'multivariable_prediction_model',
  RANDOMIZED_TRIALS: 'randomized_trials',
  RISK_FACTOR: 'risk_factor'
};
