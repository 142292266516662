/* eslint-disable camelcase,no-unused-vars, max-len */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {_PRAIP_SHA} from '../../../equations/heartAttackBranch/likelihoodOfSurvivingTheNextYear/PRAIP_SHA';
import {_PRACM_SHA} from '../../../equations/heartAttackBranch/likelihoodOfSurvivingTheNextYear/PRACM_SHA';
import {_PRAIP_AHT} from '../../../equations/heartAttackBranch/likelihoodOfAvoidingHeartAttack/PRAIP_AHT';
import {_PRACM_AHT} from '../../../equations/heartAttackBranch/likelihoodOfAvoidingHeartAttack/PRACM_AHT';
import benefitsInfo from '../../../../assets/images/icons/benefits_info.svg';
import benefitsSymbol from '../../../../assets/images/icons/benefits_symbol.svg';
import {Details as SurvivingHeartAttackDetails} from './SurvivingHeartAttack/details';
import {References as SurvivingHeartAttackReferences} from './SurvivingHeartAttack/references';
import {AdditionalInfo as SurvivingHeartAttackAddInfo} from './SurvivingHeartAttack/additionalInfo';
import {References as AvoidingHeartAttackReferences} from './AvoidingHeartAttack/references';
import {Details as AvoidingHeartAttackDetails} from './AvoidingHeartAttack/details';
import {AdditionalInfo as AvoidingHeartAttackAddInfo} from './AvoidingHeartAttack/additionalInfo';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';
import {getFormattedResult} from '../../../utils/utils';

export const BenefitsDetails = () => {
  const healthFactors = useSelector(state => state.healthFactors);

  const [survivingHeartAttackInvasiveDetails, setSurvivingHeartAttackInvasiveDetails] = useState({});
  const [survivingHeartAttackConservativeDetails, setSurvivingHeartAttackConservativeDetails] = useState({});

  const [avoidingHeartAttackInvasiveDetails, setAvoidingHeartAttackInvasiveDetails] = useState({});
  const [avoidingHeartAttackConservativeDetails, setAvoidingHeartAttackConservativeDetails] = useState({});

  useEffect(() => {
    setSurvivingHeartAttackInvasiveDetails(_PRAIP_SHA(healthFactors));
    setSurvivingHeartAttackConservativeDetails(_PRACM_SHA(healthFactors));
    setAvoidingHeartAttackInvasiveDetails(_PRAIP_AHT(healthFactors));
    setAvoidingHeartAttackConservativeDetails(_PRACM_AHT(healthFactors));
  }, []);

  return (
    <>
      <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
        <div className='grid-container'>
          <div className='text-center'>
            <h2>Likelihood of surviving the next year</h2>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(survivingHeartAttackInvasiveDetails.probability).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(survivingHeartAttackInvasiveDetails.probability).toFixed(0))}/100)
                  </p>
                </div>
              </div>
              <div className='cell'>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(survivingHeartAttackConservativeDetails.probability).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(survivingHeartAttackConservativeDetails.probability).toFixed(0))}/100)
                  </p>
                </div>
              </div>
            </div>
            <div className='grid-x align-center-middle'>
              <div className='cell shrink margin-0'>
                <img
                  alt='Icon image'
                  className='margin-right-1'
                  src={benefitsInfo}/>
              </div>
              <div className='cell auto margin-0 text-left'>
                <p className='margin-0'>
                  Treatment with an&nbsp;<Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;avoided&nbsp;<span className='benefits-text'>
                    {
                      Number(survivingHeartAttackInvasiveDetails.probability).toFixed(0) -
                          Number(survivingHeartAttackConservativeDetails.probability).toFixed(0)
                    }
                  &nbsp;deaths for every 100 patients</span>&nbsp;like you compared to&nbsp;<Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>.

                </p>
              </div>
            </div>
            {/* The below section is only visible on screen, not while printing */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>What does this look like?</h3>
                  </summary>
                  <SurvivingHeartAttackDetails
                    conservativeProb={Number(survivingHeartAttackConservativeDetails.probability).toFixed(0)}
                    invasiveProb={Number(survivingHeartAttackInvasiveDetails.probability).toFixed(0)}
                  />
                </details>
              </div>
            </div>
            {/* The below section is only visible while printing */}
            <div className='margin-vertical-2 show-for-print text-left'>
              <SurvivingHeartAttackDetails
                conservativeProb={Number(survivingHeartAttackConservativeDetails.probability).toFixed(0)}
                invasiveProb={Number(survivingHeartAttackInvasiveDetails.probability).toFixed(0)}
              />
              <div className='padding-horizontal-2'>
                <SurvivingHeartAttackAddInfo/>
              </div>
            </div>
            {/* The below section is only visible on screen */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>
                      What is this information based on? (Show me the references)
                    </h3>
                  </summary>
                  <SurvivingHeartAttackReferences/>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
        <div className='grid-container'>
          <div className='text-center'>
            <h2>Likelihood of avoiding another heart attack in the next year</h2>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(avoidingHeartAttackInvasiveDetails.probability).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(avoidingHeartAttackInvasiveDetails.probability).toFixed(0))}/100)
                  </p>
                </div>
              </div>
              <div className='cell '>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(avoidingHeartAttackConservativeDetails.probability).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(avoidingHeartAttackConservativeDetails.probability).toFixed(0))}/100)
                  </p>
                </div>
              </div>
            </div>
            <div className='grid-x align-center-middle'>
              <div className='cell shrink margin-0'>
                <img
                  alt='Icon image'
                  className='margin-right-1'
                  src={benefitsInfo}/>
              </div>
              <div className='cell auto margin-0 text-left'>
                <p className='margin-0'>
                  Treatment with an&nbsp;<Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;avoided&nbsp;<span className='benefits-text'>
                    {
                      Number(avoidingHeartAttackInvasiveDetails.probability).toFixed(0) -
                      Number(avoidingHeartAttackConservativeDetails.probability).toFixed(0)
                    }
                  &nbsp;deaths for every 100 patients</span>&nbsp;like you compared to&nbsp;<Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>.
                </p>
              </div>
            </div>
          </div>
          {/* The below section is only visible on screen, not while printing */}
          <div className='grid-x text-left margin-vertical-1 hide-for-print'>
            <div className='cell bordered all-rounded-borders'>
              <details>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>What does this look like?</h3>
                </summary>
                <AvoidingHeartAttackDetails
                  conservativeProb={Number(avoidingHeartAttackConservativeDetails.probability).toFixed(0)}
                  invasiveProb={Number(avoidingHeartAttackInvasiveDetails.probability).toFixed(0)}
                />
              </details>
            </div>
          </div>
          {/* The below section is only visible while printing */}
          <div className='margin-vertical-2 show-for-print text-left'>
            <AvoidingHeartAttackDetails
              conservativeProb={Number(avoidingHeartAttackConservativeDetails.probability).toFixed(0)}
              invasiveProb={Number(avoidingHeartAttackInvasiveDetails.probability).toFixed(0)}
            />
            <div className='padding-horizontal-2'>
              <AvoidingHeartAttackAddInfo/>
            </div>
          </div>
          {/* The below section is only visible on screen */}
          <div className='grid-x text-left margin-vertical-1 hide-for-print'>
            <div className='cell bordered all-rounded-borders'>
              <details>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>
                    What is this information based on? (Show me the references)
                  </h3>
                </summary>
                <AvoidingHeartAttackReferences/>
              </details>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
