export const COEFFICIENTS = {
  age: -0.0664,
  sex: -0.0212,
  eGFR: -0.1284,
  hemo: -0.0159,
  albuminuriaMild: 0.6342,
  albuminuriaHEavy: 1.4851,
  priorInv: 0.8161
};

export const BASELINES = {
  age: 73.32,
  eGFR: 53.87,
  hemo: 131.68
};

export const FACTOR = {
  conservativeMgmt: 1 / 1.47
};
