export const COEFFICIENTS = {
  age: -0.0435,
  sex: 0.2495,
  nstemi: 1.0872,
  eGFR: -0.0254,
  hemo: -0.0155,
  albuminuriaMild: 0.1100,
  albuminuriaHEavy: 0.8522,
  diabetes: 0.9560,
  priorInv: -0.0270
};

export const BASELINES = {
  age: 77.14,
  eGFR: 51.41,
  hemo: 126.74
};

export const FACTOR = {
  conservativeMgmt: 1 / 1.20
};
