import ROUTES from '../../routes';
import {useSelector} from 'react-redux';
import {MENU_ITEMS} from '../containers/appConstants';

const MENU = () => {
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  const menuSectionMain = [
    {
      path: ROUTES.ROOT,
      title: MENU_ITEMS.HOME,
      isLocked: !unlockedNavItems.includes(ROUTES.ROOT)
    },
    {
      path: ROUTES.INTRODUCTION,
      title: MENU_ITEMS.INTRODUCTION,
      isLocked: !unlockedNavItems.includes(ROUTES.INTRODUCTION)
    },
    {
      path: ROUTES.HEART_CONDITION,
      title: MENU_ITEMS.HEART_CONDITIONS,
      isLocked: !unlockedNavItems.includes(ROUTES.HEART_CONDITION)
    },
    {
      path: ROUTES.HEALTH_FACTORS,
      title: MENU_ITEMS.HEALTH_FACTORS,
      isLocked: !unlockedNavItems.includes(ROUTES.HEALTH_FACTORS)
    },
    {
      path: ROUTES.PROCEED_ON_PAPER,
      title: MENU_ITEMS.PROCEED_ON_PAPER,
      isLocked: !unlockedNavItems.includes(ROUTES.PROCEED_ON_PAPER)
    },
    {
      path: ROUTES.INITIAL_OPTIONS,
      title: MENU_ITEMS.INITIAL_OPTIONS,
      isLocked: !unlockedNavItems.includes(ROUTES.INITIAL_OPTIONS)
    },
    {
      path: ROUTES.MY_RISK_FACTORS,
      title: MENU_ITEMS.MY_RISK_FACTORS,
      isLocked: !unlockedNavItems.includes(ROUTES.MY_RISK_FACTORS)
    },
    {
      path: ROUTES.WEIGHING_INFORMATION,
      title: MENU_ITEMS.WEIGHING_INFORMATION,
      isLocked: !unlockedNavItems.includes(ROUTES.WEIGHING_INFORMATION)
    }
  ];

  const comparingOptions = [
    {
      path: ROUTES.BENEFITS_OVERVIEW,
      title: MENU_ITEMS.BENEFITS_OVERVIEW,
      isLocked: !unlockedNavItems.includes(ROUTES.BENEFITS_OVERVIEW)
    },
    {
      path: ROUTES.RISKS_OVERVIEW,
      title: MENU_ITEMS.RISKS_OVERVIEW,
      isLocked: !unlockedNavItems.includes(ROUTES.RISKS_OVERVIEW)
    },
    {
      path: ROUTES.SUBSEQUENT_CARE_OVERVIEW,
      title: MENU_ITEMS.SUBSEQUENT_CARE,
      isLocked: !unlockedNavItems.includes(ROUTES.SUBSEQUENT_CARE_OVERVIEW)
    }
  ];

  const myPriorities = [
    {
      path: ROUTES.DECISION_AID_SUMMARY,
      title: MENU_ITEMS.DECISION_AID_SUMMARY,
      isLocked: !unlockedNavItems.includes(ROUTES.DECISION_AID_SUMMARY)
    },
    {
      path: ROUTES.MY_PRIORITIES,
      title: MENU_ITEMS.MY_PRIORITIES,
      isLocked: !unlockedNavItems.includes(ROUTES.MY_PRIORITIES)
    },
    {
      path: ROUTES.CHOICES,
      title: MENU_ITEMS.CHOICES,
      isLocked: !unlockedNavItems.includes(ROUTES.CHOICES)
    },
    {
      path: ROUTES.NEXT_STEPS,
      title: MENU_ITEMS.NEXT_STEPS,
      isLocked: !unlockedNavItems.includes(ROUTES.NEXT_STEPS)
    }
  ];

  const mySummary = [
    {
      path: ROUTES.SUMMARY_REPORT,
      title: MENU_ITEMS.SUMMARY_REPORT,
      isLocked: !unlockedNavItems.includes(ROUTES.SUMMARY_REPORT)
    }
  ];

  const additionalInfo = [
    {
      path: ROUTES.ABOUT_THIS_TOOL,
      title: MENU_ITEMS.ABOUT,
      isLocked: !unlockedNavItems.includes(ROUTES.ABOUT_THIS_TOOL)
    },
    {
      path: ROUTES.GLOSSARY,
      title: MENU_ITEMS.GLOSSARY,
      isLocked: !unlockedNavItems.includes(ROUTES.GLOSSARY)
    },
    {
      path: ROUTES.DISCLAIMER,
      title: MENU_ITEMS.DISCLAIMER,
      isLocked: !unlockedNavItems.includes(ROUTES.DISCLAIMER)
    },
    {
      path: ROUTES.PRIVACY_POLICY,
      title: MENU_ITEMS.PRIVACY_POLICY,
      isLocked: !unlockedNavItems.includes(ROUTES.PRIVACY_POLICY)
    }
  ];

  return {
    menuSectionMain,
    comparingOptions,
    myPriorities,
    mySummary,
    additionalInfo
  };
};

export default MENU;
