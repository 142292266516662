// _PRAIP_SHA stands for Predicted Risk Assuming Invasive Procedure - Surviving Heart Attack
/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {HEALTH_FACTORS} from '../../../containers/appConstants';
import {COEFFICIENTS, BASELINES} from './eqConstants';

export const _PRAIP_SHA = patientHealthFactors => {
  const age = patientHealthFactors.age;
  const sex = patientHealthFactors.sex === HEALTH_FACTORS.MALE ? 1 : 0;
  const nstemi = patientHealthFactors.diagnosis === HEALTH_FACTORS.NSTEMI ? 1 : 0;
  const eGFR = patientHealthFactors.egfr;
  const haemoglobin = patientHealthFactors.hemoglobin;
  const albuminuria_mild = patientHealthFactors.albuminuria === HEALTH_FACTORS.MILD ? 1 : 0;
  const albuminuria_heavy = patientHealthFactors.albuminuria === HEALTH_FACTORS.HEAVY ? 1 : 0;
  const charlson_CHF = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.CONGESTIVE_HEART_FAILURE) ? 1 : 0;
  const charlson_COPD = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.CHRONIC_PULMONARY_DISEASE) ? 1 : 0;
  const liver_disease = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.LIVER_DISEASE) ? 1 : 0;
  const charlson_PVD = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.PERIPHERAL_VASCULAR_DISEASE) ? 1 : 0;
  const diabetes = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.DIABETES_MELLITUS) ? 1 : 0;
  const prior_invasive = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.PRIOR_CARDIAC_CATHETERIZATION) ? 1 : 0;
  const S0 = 0.9191;

  const eGFR_c2 = (
    Math.max(0, Math.pow(eGFR - 28.34551, 3)) - 1 / (76.10282 - 50.77166) *
    (
      Math.max(0, Math.pow(eGFR - 50.77166, 3)) *
      (76.10282 - 28.34551) - Math.max(0, Math.pow(eGFR - 76.10282, 3)) *
      (50.77166 - 28.34551)
    )
  ) / Math.pow(76.10282 - 28.34551, 2);

  const EBX = -0.9185 +
    COEFFICIENTS.age * (age - BASELINES.age) +
    COEFFICIENTS.sex * sex +
    COEFFICIENTS.nstemi * nstemi +
    COEFFICIENTS.eGFR * (eGFR - BASELINES.eGFR) +
    COEFFICIENTS.eGFRc2 * eGFR_c2 +
    COEFFICIENTS.hemo * (haemoglobin - BASELINES.hemo) +
    COEFFICIENTS.albuminuriaMild * albuminuria_mild +
    COEFFICIENTS.albuminuriaHEavy * albuminuria_heavy +
    COEFFICIENTS.charlsonCHF * charlson_CHF +
    COEFFICIENTS.charlsonCOPD * charlson_COPD +
    COEFFICIENTS.liverDis * liver_disease +
    COEFFICIENTS.charlsonPVD * charlson_PVD +
    COEFFICIENTS.diabetes * diabetes +
    COEFFICIENTS.priorInv * prior_invasive;

  /*
   * Math.pow requires that its argument be positive, and since EBX can be either positive or negative
   * we need to raise e to the absolute value of EBX and then raise it to the power of +1 or -1 depending on the sign
   */
  const predictedRiskInvasive = 1 - Math.pow(S0, Math.pow(Math.exp(Math.abs(EBX)), Math.sign(EBX)));

  const survivingNextYear = {
    PRI: predictedRiskInvasive,
    mortality: 1 - predictedRiskInvasive,
    probability: (1 - predictedRiskInvasive) * 100
  };

  return survivingNextYear;
};
