import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore, {history} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import {ConnectedRouter} from 'connected-react-router';
import {Provider as RollbarProvider, ErrorBoundary} from '@rollbar/react';
import './assets/stylesheets/application.scss';
import {HelmetProvider} from 'react-helmet-async';
import Header from './app/containers/Header';

const {store, persistor} = configureStore();

const rollbarConfig = {
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'development',
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureIp: false,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT
  }
};

ReactDOM.render(<React.StrictMode>
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <HelmetProvider>
            <ConnectedRouter history={history}>
              <Header/>
              <App/>
            </ConnectedRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
</React.StrictMode>,
document.getElementById('root'));

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
