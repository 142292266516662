import React from 'react';
import {Tooltip} from '../../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../../containers/appConstants';

export const AdditionalInfo = () =>
  <p>
    A published systematic review of&nbsp;<Tooltip
      id={GLOSSARY_KEYS.RANDOMIZED_TRIALS}
      title='randomized trials'/>&nbsp;of heart attack treatment in patients with chronic
    kidney disease was used to determine the effect of the treatment option on the frequency of receiving an
    angioplasty procedure. The overall rating of the strength of evidence for this outcome is “moderate”
    (meaning the true effect is probably close to the estimated effect). The certainty of this evidence
    was affected by imprecision (there is a wide range of plausible estimates around the best estimates of
    the effects of treatment) and inconsistency (there are differences in effects between individual trials).
  </p>;
