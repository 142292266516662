// PRACM_SWHA stands for Predicted Risk Assuming Conservative Management - Surviving Without Heart Attack
/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {_PRAIP_SWHA} from './PRAIP_SWHA';
import {FACTOR} from './eqConstants';

export const _PRACM_SWHA = patientHealthFactors => {
  const PRIData = _PRAIP_SWHA(patientHealthFactors);
  const predictedRiskConservative = PRIData.PRI * FACTOR.conservativeMgmt;

  const survivingNextYearWithoutHeartAttack = {
    PRC: predictedRiskConservative,
    mortality: 1 - predictedRiskConservative,
    probability: (1 - predictedRiskConservative) * 100
  };

  return survivingNextYearWithoutHeartAttack;
};
