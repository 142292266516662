import React, {useEffect, useState} from 'react';
import subsequentCareInfo from '../../../../assets/images/icons/subsequent_care_info.svg';
import SubsequentEquationResult from '../../../components/common/subsequentEquationResult';
import {useSelector} from 'react-redux';
import {SUBSEQUENT_CARE} from '../../../equations/subsequentCareEqConstants';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';

const SubsequentCareSummary = () => {
  const eGFR = useSelector(state => state.healthFactors.egfr);
  const [angioPlastyInvasiveDetails, setAngioPlastyInvasiveDetails] = useState(0);
  const [angioPlastyConservativeDetails, setAngioPlastyConservativeDetails] = useState(0);
  const [heartSurgeryInvasiveDetails, setHeartSurgeryInvasiveDetails] = useState(0);
  const [heartSurgeryConservativeDetails, setHeartSurgeryConservativeDetails] = useState(0);

  useEffect(() => {
    if (eGFR < 30) {
      setAngioPlastyInvasiveDetails(SUBSEQUENT_CARE.FORTY_THREE);
      setAngioPlastyConservativeDetails(SUBSEQUENT_CARE.SEVENTEEN);
      setHeartSurgeryInvasiveDetails(SUBSEQUENT_CARE.EIGHT);
      setHeartSurgeryConservativeDetails(SUBSEQUENT_CARE.THREE);
    }
    else {
      setAngioPlastyInvasiveDetails(SUBSEQUENT_CARE.FIFTY_EIGHT);
      setAngioPlastyConservativeDetails(SUBSEQUENT_CARE.SIXTEEN);
      setHeartSurgeryInvasiveDetails(SUBSEQUENT_CARE.TWENTY);
      setHeartSurgeryConservativeDetails(SUBSEQUENT_CARE.FOUR);
    }
  }, []);

  return (
    <>
      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <div className='text-center'>
          <h2>How likely you are to go on to have an angioplasty procedure</h2>
          <div className='grid-x medium-up-2 margin-vertical-1'>
            <div className='cell'>
              <SubsequentEquationResult
                className='border-right'
                resultDetails={angioPlastyInvasiveDetails}/>
            </div>
            <div className='cell'>
              <SubsequentEquationResult resultDetails={angioPlastyConservativeDetails}/>
            </div>
          </div>
          <div className='grid-x align-center-middle'>
            <div className='cell shrink margin-0'>
              <img
                alt='Icon image'
                className='margin-right-1'
                src={subsequentCareInfo}/>
            </div>
            <div className='cell auto margin-0 text-left'>
              {eGFR < 30
                ? <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                    26 more angioplasty procedures per 100 patients</span>&nbsp;like you compared to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>&nbsp;.
                </p>
                : <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                    42 more angioplasty procedures per 100 patients</span>&nbsp; like you compared to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>&nbsp;.
                </p>
              }
            </div>
          </div>
        </div>
      </div>

      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <div className='text-center'>
          <h2>How likely you are of going on to have heart surgery</h2>
          <div className='grid-x medium-up-2 margin-vertical-1'>
            <div className='cell'>
              <SubsequentEquationResult
                className='border-right'
                resultDetails={heartSurgeryInvasiveDetails}/>
            </div>
            <div className='cell'>
              <SubsequentEquationResult resultDetails={heartSurgeryConservativeDetails}/>
            </div>
          </div>
          <div className='grid-x align-center-middle'>
            <div className='cell shrink margin-0'>
              <img
                alt='Icon image'
                className='margin-right-1'
                src={subsequentCareInfo}/>
            </div>
            <div className='cell auto margin-0 text-left'>
              {eGFR < 30
                ? <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                    5 more heart surgeries per 100 patients</span>&nbsp; like you compared to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>&nbsp;.
                </p>
                : <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                    16 more heart surgeries per 100 patients</span>&nbsp; like you compared to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>&nbsp;.
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubsequentCareSummary;
