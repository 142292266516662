import React from 'react';
import risksSymbol from '../../../assets/images/icons/risks_symbol.svg';
import PropTypes from 'prop-types';
import {getFormattedResult} from '../../utils/utils';

const RisksEquationResult = ({resultDetails, className}) =>
  <div className={`card align-center ${className}`}>
    <div className='rounded percentage-circle bg-color-red margin-vertical-1'>
      <div className='rounded inside-circle'>
        <img
          alt='Icon image'
          src={risksSymbol}/>
        <p className='margin-0'>
          {getFormattedResult(Number(resultDetails * 100).toFixed(0))}%
        </p>
      </div>
    </div>
    <p>
        ({getFormattedResult(Number(resultDetails * 100).toFixed(0))}/100)
    </p>
  </div>;

export default RisksEquationResult;

RisksEquationResult.propTypes = {
  className: PropTypes.string,
  resultDetails: PropTypes.number.isRequired
};
