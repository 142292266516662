export const COEFFICIENTS = {
  age: 0.0446,
  sex: 0.1229,
  nstemi: 0.6324,
  eGFR: -0.0139,
  eGFRc2: 0.0191,
  hemo: -0.0131,
  albuminuriaMild: 0.1055,
  albuminuriaHEavy: 0.0296,
  charlsonCHF: 0.4350,
  charlsonCOPD: 0.2790,
  liverDis: 0.4347,
  charlsonPVD: 0.1483,
  diabetes: 0.0892,
  priorInv: -0.2921
};

export const BASELINES = {
  age: 77.14,
  eGFR: 51.41,
  hemo: 126.74
};

export const FACTOR = {
  conservativeMgmt: 1 / 0.87
};
