import React from 'react';
import MedicinesImage from '../../../assets/images/pexels-anna-shvets-3683074.jpg';
import {Tooltip} from '../common/tooltip';
import {GLOSSARY_KEYS} from '../../containers/appConstants';

const MedicationOnlyDetails = () =>
  <>
    <div className='grid-container'>
      <div className='grid-x'>
        <div className='cell'>
          <h2>
            What other treatment options are there for a heart attack?
          </h2>
        </div>
      </div>
      <div className='text-center margin-3'>
        <img
          alt='medicines'
          className='img-print-styles'
          src={MedicinesImage}
          width={600}/>
      </div>
      <div>
        <p>
          The alternative to treatment with an invasive heart procedure is to treat with medications that slow the
          heart rate, lower blood pressure, prevent blood clotting, dilate the blood vessels, and lower
          cholesterol. These medications can reduce symptoms, improve outcomes, and are thus standard treatments
          for all patients with a heart attack. This treatment approach is typically referred to as &nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
            title='optimal medication management'/>.
        </p>
        <p>
          If you choose optimal medication management, your doctor will likely perform other&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.NON_INVASIVE_TESTS}
            title='non-invasive tests'/>&nbsp;on
          your heart. If these test results are very abnormal or if the symptoms of a heart attack return, an
          invasive heart procedure might still be recommended later.
        </p>
      </div>
    </div>
  </>;

export default MedicationOnlyDetails;
