import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {Route} from 'react-router-dom';

const RouteWrapper = function({component: Component, title, content, name, ...rest}) {
  return (
    <Route
      {...rest}
      render={matchProps =>
        <>
          <Helmet prioritizeSeoTags>
            <link
              href={window.location.href}
              rel='Canonical'/>
            <title>{title}</title>
            <meta
              content={content}
              name={name}/>
          </Helmet>
          <Component {...matchProps}/>
        </>
      }
    />
  );
};

export default RouteWrapper;

RouteWrapper.propTypes = {
  component: PropTypes.any,
  content: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string
};

RouteWrapper.defaultProps = {
  component: null,
  content: '',
  name: '',
  title: ''
};
