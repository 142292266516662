import {NEXT_STEPS} from '../constants';

export const saveNextSteps = nextSteps => ({
  type: NEXT_STEPS.SAVE_NEXT_STEPS,
  nextSteps
});

export const resetNextSteps = () => ({
  type: NEXT_STEPS.RESET_NEXT_STEPS
});
