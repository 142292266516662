import React from 'react';
import PropTypes from 'prop-types';
import {HEALTH_FACTORS} from '../appConstants';
import nonePainGraph from '../../../assets/images/graphs/none.png';
import dailyPainGraph from '../../../assets/images/graphs/daily.png';
import weeklyPainGraph from '../../../assets/images/graphs/weekly.png';
import monthlyPainGraph from '../../../assets/images/graphs/monthly.png';

const staticGraphs = chestPain => {
  if (chestPain === HEALTH_FACTORS.NONE) {
    return <div className='text-center'>
      <img
        alt='None'
        src={nonePainGraph}/>
    </div>;
  }
  else if (chestPain === HEALTH_FACTORS.DAILY) {
    return <div className='text-center'>
      <img
        alt='None'
        src={dailyPainGraph}/>
    </div>;
  }
  else if (chestPain === HEALTH_FACTORS.WEEKLY) {
    return <div className='text-center'>
      <img
        alt='None'
        src={weeklyPainGraph}/>
    </div>;
  }

  return <div className='text-center'>
    <img
      alt='None'
      src={monthlyPainGraph}/>
  </div>;
};

staticGraphs.propTypes = {
  chestPain: PropTypes.string.isRequired
};

export default staticGraphs;
