import React, {useEffect, useState} from 'react';
import LOGO from '../../../assets/images/headers/KDA-Logo.jpg';
import ROUTES from '../../../routes';
import {Link, useLocation} from 'react-router-dom';
import Menu from '../Menu/menu';
import Navigation from '../../../assets/images/menu_image.svg';

const Index = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return <>
    <div className='white-bg-color padding-2 hide-for-print'>
      <div className='grid-container'>
        <div className='grid-x small-up-2 align-justify'>
          <div className='cell'>
            <Link to={ROUTES.ROOT}>
              <img
                alt='logo'
                className='width-50'
                src={LOGO}
              />
            </Link>
          </div>
          {
            !showMenu &&
            <div className='cell text-right align-self-middle'>
              <span
                onClick={() => setShowMenu(!showMenu)}>
                <img
                  alt='Navigation'
                  className='navigation-logo'
                  src={Navigation}
                />
              </span>
            </div>
          }
        </div>
      </div>
    </div>
    <div className='show-for-print margin-bottom-1'>
      <img
        alt='logo'
        className='width-50'
        src={LOGO}
      />
    </div>
    <Menu
      isShown={showMenu}
      setShowMenu={setShowMenu}/>
  </>;
};

export default Index;
