import React from 'react';
import {useSelector} from 'react-redux';
import {
  HEALTH_FACTORS,
  HEART_ATTACK_PRIORITIES,
  HEART_CONDITIONS,
  STABLE_CORONARY_HEART_DISEASE_PRIORITIES
} from '../appConstants';
import HeartIcon from '../../../assets/images/Heart.png';
import PillIcon from '../../../assets/images/Pill.png';

export const SummaryPriorities = () => {
  const heartCondition = useSelector(state => state.heartCondition);
  const myPriorities = useSelector(state => state.myPriorities);
  let priorities;

  if (heartCondition === HEART_CONDITIONS.HEART_ATTACK) {
    priorities = [
      {title: HEART_ATTACK_PRIORITIES.LIVE_LONGER, icon: HEALTH_FACTORS.INVASIVE},
      {title: HEART_ATTACK_PRIORITIES.AVOID_HEART_ATTACK, icon: HEALTH_FACTORS.INVASIVE},
      {title: HEART_ATTACK_PRIORITIES.AVOID_DIALYSIS, icon: HEALTH_FACTORS.CONSERVATIVE},
      {title: HEART_ATTACK_PRIORITIES.AVOID_ANGIOGRAM, icon: HEALTH_FACTORS.CONSERVATIVE}
    ];
  }
  else if (heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE) {
    priorities = [
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.DO_I_HAVE_SERIOUS_CORONARY_DISEASE,
        icon: HEALTH_FACTORS.INVASIVE},
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.REDUCE_CHEST_PAIN,
        icon: HEALTH_FACTORS.INVASIVE},
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.AVOID_HEART_SURGERY,
        icon: HEALTH_FACTORS.CONSERVATIVE},
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.AVOID_KIDNEY_FAILURE,
        icon: HEALTH_FACTORS.CONSERVATIVE}
    ];
  }

  return (
    <section className='shadow all-rounded-borders white-bg-color margin-bottom-2 page-break-before'>
      <div className='grid-container'>
        <div className='cell large-9'>
          <h1>Rank your values and preferences</h1>
          <p>
            This section is designed for you, as a patient, to indicate what is the most important to you.
            Four values are listed below with a symbol showing the treatment option that favours each of them.
          </p>
          <p>
            Write the numbers from 1 (most important) to 4 (least important) to rank your preferences.
          </p>
          <p>
            <img
              className='margin-right-1 priorities-icon'
              src={HeartIcon}
            />
            indicates values that favour the invasive heart procedure option, and
          </p>
          <p>
            <img
              className='margin-right-1 priorities-icon'
              src={PillIcon}/>
            indicates values that favour the optimal medication management option.
          </p>
        </div>
        <div className='cell large-9 margin-vertical-2'>
          <table className='unstriped width-100 table-print-styles'>
            <tbody>
              {priorities.map(ele => <tr
                className='bordered'
                key={ele.title}>
                <td className='bordered width-10'>
                  <div className='text-center'>
                    {ele.icon === HEALTH_FACTORS.INVASIVE
                      ? <img
                        alt='Heart Icon'
                        className='priorities-icon'
                        src={HeartIcon}/>
                      : <img
                        alt='Pill Icon'
                        className='priorities-icon'
                        src={PillIcon}/>}
                  </div>
                </td>
                <td className='padding-1 bordered'>
                  {ele.title}
                </td>
                <td className='padding-1 bordered width-10 text-center'>
                  {
                    myPriorities.map((priority, index) => {
                      if (priority.title === ele.title) {
                        return index + 1;
                      }

                      return '';
                    })
                  }
                </td>
              </tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};
