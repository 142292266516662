import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {HEART_CONDITIONS} from '../appConstants';
import ROUTES from '../../../routes';
import BackNextButtons from '../../components/common/backNextButtons';
import {RisksDetails as HARisksDetails} from '../../components/HeartAttack/Risks/risksDetails';
import {RisksDetails as SCHDRisksDetails} from '../../components/StableCoronaryHeartDisease/Risks/risksDetails';
import {useHistory, useLocation} from 'react-router-dom';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const heartCondition = useSelector(state => state.heartCondition);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return (
    <>
      <section>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell'>
              <h1>
                Potential harms of the treatment options
              </h1>
              <h3 className='text-uppercase primary-color'>
                Your Personalized Comparisons - The potential harms of each option:
              </h3>
              <p>
                The decision aid has personalized your risk factors based on your submitted health factor information
                (age, sex, medical conditions, and lab tests). It will compare the likelihood of potential harms for
                the two treatment options:
              </p>
            </div>
          </div>
        </div>
      </section>
      {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
        <HARisksDetails/>
      }
      {
        heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
        <SCHDRisksDetails/>
      }
      <BackNextButtons
        backButtonRoute={ROUTES.BENEFITS_OVERVIEW}
        nextButtonRoute={ROUTES.SUBSEQUENT_CARE_OVERVIEW}/>
    </>
  );
};

export default Index;
