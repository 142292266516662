/*
 * _PRAIP_SWHA stands for Predicted Risk Assuming Invasive Procedure - Acute Kidney Inhury Requiring Dialysis
 *  Treatment
 */
/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {HEALTH_FACTORS} from '../../../containers/appConstants';
import {COEFFICIENTS, BASELINES} from './eqConstants';

export const _PRAIP_AKIRD = patientHealthFactors => {
  const age = patientHealthFactors.age;
  const sex = patientHealthFactors.sex === HEALTH_FACTORS.MALE ? 1 : 0;
  const eGFR = patientHealthFactors.egfr;
  const haemoglobin = patientHealthFactors.hemoglobin;
  const albuminuria_mild = patientHealthFactors.albuminuria === HEALTH_FACTORS.MILD ? 1 : 0;
  const albuminuria_heavy = patientHealthFactors.albuminuria === HEALTH_FACTORS.HEAVY ? 1 : 0;
  const diabetes = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.DIABETES_MELLITUS) ? 1 : 0;
  const prior_invasive = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.PRIOR_CARDIAC_CATHETERIZATION) ? 1 : 0;

  const aPlusEBX =
    COEFFICIENTS.interTerm +
    COEFFICIENTS.age * (age - BASELINES.age) +
    COEFFICIENTS.sex * sex +
    COEFFICIENTS.eGFR * (eGFR - BASELINES.eGFR) +
    COEFFICIENTS.hemo * (haemoglobin - BASELINES.hemo) +
    COEFFICIENTS.albuminuriaMild * albuminuria_mild +
    COEFFICIENTS.albuminuriaHeavy * albuminuria_heavy +
    COEFFICIENTS.diabetes * diabetes +
    COEFFICIENTS.priorInv * prior_invasive;

  /*
   * Math.pow requires that its argument be positive, and since aPlusEBX can be either positive or negative
   * we need to raise e to the absolute value of aPlusEBX and then raise it to the power of +1 or -1 depending
   * on the sign
   */
  const expoAPlusEBX = Math.pow(Math.exp(Math.abs(aPlusEBX)), Math.sign(aPlusEBX));
  const predictedRiskInvasive = expoAPlusEBX / (1 + expoAPlusEBX);

  const survivingNextYear = {
    PRI: predictedRiskInvasive,
    probability: predictedRiskInvasive * 100
  };

  return survivingNextYear;
};
