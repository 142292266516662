/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {REDUX_STORE} from '../../../redux/constants';
import {useHistory} from 'react-router-dom';
import ROUTES from '../../../routes';

const DataResetModal = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!props.show) {
    return null;
  }

  const handleReset = () => {
    dispatch({type: REDUX_STORE.RESET_REDUX_STORE});
    history.push(ROUTES.ROOT);
    props.onClose();
  };

  return (
    <div className='modal hide-for-print' onClick={props.onClose}>
      <div className='padding-3 width-50 white-bg-color all-rounded-borders' onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <h2>Confirm Decision Aid Reset</h2>
        </div>
        <div className='modal-content'>
          <h3 className='info-color text-uppercase'>
            Are you sure you want to reset the Decision Aid?
          </h3>
          <p>
            You will lose your progress and inputs, and will have to re-do the &quot;Making a Choice&quot; section
            to complete another summary page.
          </p>
        </div>
        <div className='modal-footer margin-top-2'>
          <button
            className='button large hollow rounded padding-horizontal-2'
            onClick={props.onClose}
            type='button'>
            Close
          </button>
          <button
            className='button large rounded padding-horizontal-2'
            onClick={() => handleReset()}
            type='button'>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataResetModal;

DataResetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
