/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import risksInfo from '../../../../assets/images/icons/risks_info.svg';
import RisksEquationResult from '../../../components/common/risksEquationResult';
import {
  _PRAIP_AKIRD as _PRAIP_AKIRD_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfAcuteKidneyInjuryRequiringDialysis/PRAIP_AKIRD';
import {
  _PRACM_AKIRD as _PRACM_AKIRD_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfAcuteKidneyInjuryRequiringDialysis/PRACM_AKIRD';
import {
  _PRAIP_ESKF as _PRAIP_ESKF_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfEndStageKidneyFailureWithInYear/PRAIP_ESKF';
import {
  _PRACM_ESKF as _PRACM_ESKF_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfEndStageKidneyFailureWithInYear/PRACM_ESKF';
import {useSelector} from 'react-redux';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';

const RisksSummary = () => {
  const healthFactors = useSelector(state => state.healthFactors);
  const [
    acuteKidneyInjuryDialysisInvasiveDetailsForSCHD,
    setAcuteKidneyInjuryDialysisInvasiveDetailsForSCHD] = useState({});
  const [
    acuteKidneyInjuryDialysisConservativeDetailsForSCHD,
    setAcuteKidneyInjuryDialysisConservativeDetailsForSCHD] = useState({});
  const [
    endStageKidneyFailureInvasiveDetailsForSCHD,
    setEndStageKidneyFailureInvasiveDetailsForSCHD] = useState({});
  const [
    endStageKidneyFailureConservativeDetailsForSCHD,
    setEndStageKidneyFailureConservativeDetailsForSCHD] = useState({});

  useEffect(() => {
    setAcuteKidneyInjuryDialysisInvasiveDetailsForSCHD(_PRAIP_AKIRD_SCHD(healthFactors));
    setAcuteKidneyInjuryDialysisConservativeDetailsForSCHD(_PRACM_AKIRD_SCHD(healthFactors));
    setEndStageKidneyFailureInvasiveDetailsForSCHD(_PRAIP_ESKF_SCHD(healthFactors));
    setEndStageKidneyFailureConservativeDetailsForSCHD(_PRACM_ESKF_SCHD(healthFactors));
  }, []);

  return (
    <>
      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of acute kidney injury requiring dialysis treatment</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <RisksEquationResult
              className='border-right'
              resultDetails={acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI}/>
          </div>
          <div className='cell'>
            <RisksEquationResult
              resultDetails={acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={risksInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Treatment with an&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>
                {
                  Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0) -
                  Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0)
                }&nbsp;kidney injuries requiring dialysis per 100 patients</span>&nbsp; like you
            </p>
          </div>
        </div>
      </div>

      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of end-stage kidney failure within one year</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <RisksEquationResult
              className='border-right'
              resultDetails={endStageKidneyFailureInvasiveDetailsForSCHD.PRI}/>
          </div>
          <div className='cell'>
            <RisksEquationResult
              resultDetails={endStageKidneyFailureConservativeDetailsForSCHD.PRC}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={risksInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Treatment with an&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>
                {
                  Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0) -
                  Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0)
                }&nbsp; additional end-stage kidney failures per 100 patients</span> like you
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RisksSummary;
