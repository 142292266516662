/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import benefitsInfo from '../../../../assets/images/icons/benefits_info.svg';
import {_PRAIP_SHA} from '../../../equations/heartAttackBranch/likelihoodOfSurvivingTheNextYear/PRAIP_SHA';
import {_PRACM_SHA} from '../../../equations/heartAttackBranch/likelihoodOfSurvivingTheNextYear/PRACM_SHA';
import {_PRAIP_AHT} from '../../../equations/heartAttackBranch/likelihoodOfAvoidingHeartAttack/PRAIP_AHT';
import {_PRACM_AHT} from '../../../equations/heartAttackBranch/likelihoodOfAvoidingHeartAttack/PRACM_AHT';
import {useSelector} from 'react-redux';
import BenefitsEquationResult from '../../common/benefitsEquationResult';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';

const BenefitsSummary = () => {
  const healthFactors = useSelector(state => state.healthFactors);
  const [survivingHeartAttackInvasiveDetails, setSurvivingHeartAttackInvasiveDetails] = useState({});
  const [survivingHeartAttackConservativeDetails, setSurvivingHeartAttackConservativeDetails] = useState({});

  const [avoidingHeartAttackInvasiveDetails, setAvoidingHeartAttackInvasiveDetails] = useState({});
  const [avoidingHeartAttackConservativeDetails, setAvoidingHeartAttackConservativeDetails] = useState({});

  useEffect(() => {
    setSurvivingHeartAttackInvasiveDetails(_PRAIP_SHA(healthFactors));
    setSurvivingHeartAttackConservativeDetails(_PRACM_SHA(healthFactors));
    setAvoidingHeartAttackInvasiveDetails(_PRAIP_AHT(healthFactors));
    setAvoidingHeartAttackConservativeDetails(_PRACM_AHT(healthFactors));
  }, []);

  return (
    <>
      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of surviving the next year</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <BenefitsEquationResult
              className='border-right'
              resultDetails={survivingHeartAttackInvasiveDetails.probability}/>
          </div>
          <div className='cell'>
            <BenefitsEquationResult
              resultDetails={survivingHeartAttackConservativeDetails.probability}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={benefitsInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Treatment with an&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                title='invasive heart procedure'/>&nbsp;avoided&nbsp;<span className='benefits-text'>
                {
                  Number(survivingHeartAttackInvasiveDetails.probability).toFixed(0) -
                  Number(survivingHeartAttackConservativeDetails.probability).toFixed(0)
                }
              &nbsp;deaths for every 100 patients</span>&nbsp;like you compared to&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                title='optimal medication management'/>.
            </p>
          </div>
        </div>
      </div>

      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of avoiding another heart attack in the next year</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <BenefitsEquationResult
              className='border-right'
              resultDetails={avoidingHeartAttackInvasiveDetails.probability}/>
          </div>
          <div className='cell'>
            <BenefitsEquationResult
              resultDetails={avoidingHeartAttackConservativeDetails.probability}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={benefitsInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Treatment with an&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                title='invasive heart procedure'/>&nbsp;avoided&nbsp;<span className='benefits-text'>
                {
                  Number(avoidingHeartAttackInvasiveDetails.probability).toFixed(0) -
                  Number(avoidingHeartAttackConservativeDetails.probability).toFixed(0)
                }
              &nbsp;deaths for every 100 patients</span>&nbsp;like you compared to&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                title='optimal medication management'/>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BenefitsSummary;
