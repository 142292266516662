import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {HEART_CONDITIONS} from '../appConstants';
import {BenefitsDetails as HABenefitsDetails} from '../../components/HeartAttack/Benifits/benefitsDetails';
import {
  BenefitsDetails as SCHDBenefitsDetails
} from '../../components/StableCoronaryHeartDisease/Benefits/benefitsDetails';
import {RisksDetails as HARisksDetails} from '../../components/HeartAttack/Risks/risksDetails';
import {RisksDetails as SCHDRisksDetails} from '../../components/StableCoronaryHeartDisease/Risks/risksDetails';
import {
  SubsequentCareDetails as HASubsequentCareDetails
} from '../../components/HeartAttack/SubsequentCare/subsequentCareDetails';
import {
  SubsequentCareDetails as SCHDSubsequentCareDetails
} from '../../components/StableCoronaryHeartDisease/SubsequentCare/subsequentCareDetails';
import {SummaryPriorities} from './summaryPriorities';
import {SummaryChoices} from './summaryChoices';
import {SummaryReferences} from './summaryReferences';
import {SummaryAbout} from './summaryAbout';
import {SummaryNotes} from './summaryNotes';
import {SummaryInitialOptions} from './summaryInitialOptions';
import {SummaryHealthFactors} from './summaryHealthFactors';
import PrintStepsModal from '../../components/common/printStepsModal';
import DataResetModal from '../../components/common/dataResetModal';
import SummaryNextSteps from './summaryNextSteps';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const heartCondition = useSelector(state => state.heartCondition);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  // The below lines are for scrolling the users back to top
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300) {
      setVisible(true);
    }
    else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener('scroll', toggleVisible);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return (
    <>
      <div className='grid-container hide-for-print'>
        <div className='text-right'>
          <button
            className='button rounded primary large width-25 margin-right-1'
            onClick={() => setShowPrintModal(true)}
            type='button'>
              Print / Save as PDF
          </button>
          <button
            className='button rounded alert hollow large width-25'
            onClick={() => setShowResetModal(true)}
            type='button'>
              Reset Data
          </button>
        </div>
      </div>
      {/* Informational Section */}
      <section>
        <div className='grid-container'>
          <div>
            <h1>
              Personalized Decision Support Summary
            </h1>
            <h3 className='text-uppercase primary-color'>
              This decision aid helps you and your care team make the following decision:
            </h3>
            <i>
              &quot;Should I have an invasive heart procedure in addition to optimal medication management
              after my heart attack, or attempt to treat it with optimal medication only?&quot;
            </i>
          </div>
        </div>
      </section>

      {/* Options Sections */}
      <SummaryInitialOptions heartCondition={heartCondition}/>

      {/* Risks Section and Weighing informational sections */}
      <SummaryHealthFactors heartCondition={heartCondition}/>

      {/* Benefits Overview */}
      <section className='shadow all-rounded-borders white-bg-color margin-bottom-2 page-break-before'>
        <div className='grid-container'>
          <div>
            <div className='cell large-9 padding-vertical-1'>
              <h1>Potential benefits of the treatment options</h1>
              <h3 className='text-uppercase primary-color'>
                Your Personalized Comparisons - The benefits of each option:
              </h3>
              <p>
                The decision aid has personalized your risk factors based on your health factor information
                (age, sex, medical conditions, and lab tests). It will compare the likelihood of potential
                benefits for the two treatment options:
              </p>
            </div>
            {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
              <HABenefitsDetails/>
            }
            {heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
              <SCHDBenefitsDetails/>
            }
          </div>
        </div>
      </section>

      {/* Risks Overview */}
      <section className='shadow all-rounded-borders white-bg-color margin-bottom-2 page-break-before'>
        <div className='grid-container'>
          <div className='cell large-9'>
            <h1>
              Potential harms of the treatment options
            </h1>
            <h3 className='text-uppercase primary-color'>
              Your Personalized Comparisons - The potential harms of each option:
            </h3>
            <p>
              The decision aid has personalized your risk factors based on your submitted health factor
              information (age, sex, medical conditions, and lab tests). It will compare the likelihood
              of potential harms for the two treatment options:
            </p>
          </div>
          {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
            <HARisksDetails/>
          }
          {
            heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
            <SCHDRisksDetails/>
          }
        </div>
      </section>

      {/* Subsequent Care Overview */}
      <section className='shadow all-rounded-borders white-bg-color margin-bottom-2 page-break-before'>
        <div className='grid-container'>
          <div className='cell large-9'>
            <h1>
              What treatment might happen next with each option
            </h1>
            <h3 className='text-uppercase primary-color'>
              Your Personalized Comparisons - What might happen next:
            </h3>
            <p>
              The decision aid has personalized your risk factors based on your submitted health factor
              information (age, sex, medical conditions, and lab tests). It will compare the likelihood
              of potential additional treatments you may receive with the two treatment options:
            </p>
          </div>
          {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
            <HASubsequentCareDetails/>
          }
          {heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
            <SCHDSubsequentCareDetails/>
          }
        </div>
      </section>

      {/* Priorities Section */}
      <SummaryPriorities/>

      {/* Initial Choices Section */}
      <SummaryChoices/>

      {/* How Comfertable are you in your Choices Section */}
      <SummaryNextSteps/>

      {/* Refrences section */}
      <SummaryReferences/>

      {/* About this tool */}
      <SummaryAbout/>

      {/* Notes Section */}
      <SummaryNotes/>

      <PrintStepsModal
        onClose={() => setShowPrintModal(false)}
        show={showPrintModal}
      />

      <DataResetModal
        onClose={() => setShowResetModal(false)}
        show={showResetModal}
      />

      {visible &&
        <button
          className='scroll-to-top-button button rounded large padding-horizontal-2 hide-for-print'
          id='back_to_top_button'
          onClick={() => scrollToTop()}
          title='Top'
          type='button'
        >Return to top</button>
      }
    </>
  );
};

export default Index;
