import React from 'react';
import subsequentCareInfo from '../../../../assets/images/icons/subsequent_care_info.svg';
import SubsequentEquationResult from '../../../components/common/subsequentEquationResult';
import {SUBSEQUENT_CARE} from '../../../equations/subsequentCareEqConstants';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';

const SubsequentCareSummary = () => <>
  <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
    <div className='text-center'>
      <h2>How likely you are to go on to have an angioplasty (stent) procedure</h2>
      <div className='grid-x medium-up-2 margin-vertical-1'>
        <div className='cell'>
          <SubsequentEquationResult
            className='border-right'
            resultDetails={SUBSEQUENT_CARE.FIFTY_EIGHT}/>
        </div>
        <div className='cell'>
          <SubsequentEquationResult resultDetails={SUBSEQUENT_CARE.TWENTY_EIGHT}/>
        </div>
      </div>
      <div className='grid-x align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={subsequentCareInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an&nbsp;
            <Tooltip
              id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
              title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
            30 more angioplasty procedures per 100 patients</span>&nbsp;like you compared to&nbsp;
            <Tooltip
              id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
              title='optimal medication management'/>.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
    <h2>How likely you are to go on to have heart surgery</h2>
    <div className='grid-x medium-up-2 margin-vertical-1'>
      <div className='cell'>
        <SubsequentEquationResult
          className='border-right'
          resultDetails={SUBSEQUENT_CARE.TWENTY_EIGHT}/>
      </div>
      <div className='cell'>
        <SubsequentEquationResult resultDetails={SUBSEQUENT_CARE.FIFTEEN}/>
      </div>
    </div>
    <div className='grid-x align-center-middle'>
      <div className='cell shrink margin-0'>
        <img
          alt='Icon image'
          className='margin-right-1'
          src={subsequentCareInfo}/>
      </div>
      <div className='cell auto margin-0 text-left'>
        <p className='margin-0'>
          Treatment with an&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
            title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                13 more heart surgeries per 100 patients</span>&nbsp;like you compared to&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
            title='optimal medication management'/>.
        </p>
      </div>
    </div>
  </div>

</>;

export default SubsequentCareSummary;
