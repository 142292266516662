import React from 'react';

export const AdditionalInfo = () =>
  <>
    <p>
      Individualized estimates of the risk of end-stage kidney disease were determined using multivariable
      prediction models developed from a population-based cohort of 17,236 adult patients with chronic kidney
      disease with a diagnosis of stable coronary heart disease in Alberta, Canada between 2004-2017. Treatment
      effects for invasive heart procedures were then applied to these absolute risks for each participant to
      obtain personalized estimates of absolute risk for an individual under the two treatment options
      (invasive heart procedure plus optimal medical therapy versus optimal medical therapy).
    </p>
    <p>
      Randomized clinical trials were used to inform treatment effect estimates for end-stage kidney disease.
      The overall rating of the strength of evidence for this outcome is “moderate” (meaning the true effect
      is probably close to the estimated effect). The certainty of this evidence was affected by imprecision
      (wide range of plausible estimates around the best estimate of the effect).
    </p>
  </>;
