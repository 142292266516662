export const COEFFICIENTS = {
  age: -0.0522,
  sex: 0.2343,
  eGFR: -0.1273,
  hemo: -0.0215,
  albuminuriaMild: 0.1943,
  albuminuriaHEavy: 1.1553,
  priorInv: 0.8999
};

export const BASELINES = {
  age: 77.14,
  eGFR: 51.41,
  hemo: 126.74
};

export const FACTOR = {
  conservativeMgmt: 1 / 1.47
};
