import React, {useEffect} from 'react';
import ROUTES from '../../../routes';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import HeartAttackProceedOnPaper from '../../components/HeartAttack/proceedOnPaper';
import StableCoronaryHeartDiseaseProceedOnPaper from '../../components/StableCoronaryHeartDisease/proceedOnPaper';
import {HEART_CONDITIONS} from '../appConstants';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const heartCondition = useSelector(state => state.heartCondition);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return <>
    <section>
      <div className='grid-container'>
        <p>
          This decision aid supports shared decision making between patients and their care team.
          It does this by providing risk information under different treatment options,
          and clarifying values and preferences that are important to each patient.
        </p>
      </div>
    </section>
    {
      heartCondition === HEART_CONDITIONS.HEART_ATTACK
        ? <HeartAttackProceedOnPaper/>
        : <StableCoronaryHeartDiseaseProceedOnPaper/>
    }
    <section>
      <div className='grid-container padding-1 text-center'>
        <div className='grid-x medium-up-3'>
          <div className='auto cell'>
            <Link
              className='hollow secondary large button rounded padding-horizontal-2'
              to={ROUTES.HEALTH_FACTORS}>Back</Link>
          </div>
          <div className='auto cell'>
            <Link
              className='primary large button rounded'
              to={ROUTES.SUMMARY_REPORT}>Proceed On Paper</Link>
          </div>
          <div className='auto cell'>
            <Link
              className='primary large button rounded'
              to={ROUTES.INITIAL_OPTIONS}>Proceed On Screen</Link>
          </div>
        </div>
        <p className='text-center margin-top-2'>
          By proceeding you agree to the&nbsp;<Link to={ROUTES.DISCLAIMER}>disclaimer</Link>&nbsp;and&nbsp;
          <Link to={ROUTES.PRIVACY_POLICY}>&nbsp;privacy policy</Link>.
        </p>
      </div>
    </section>
    <div className='grid-container'>
      <div className='cell text-center'>
        <Link
          className='hollow button large rounded padding-horizontal-2'
          to={ROUTES.ABOUT_THIS_TOOL}>About This Tool</Link>
      </div>
    </div>
  </>;
};

export default Index;
