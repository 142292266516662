import React from 'react';
import ROUTES from '../../../routes';
import {useSelector} from 'react-redux';
import HeartAttackMedicationOnlyDetails from '../../components/HeartAttack/medicationOnlyDetails';
import StableCoronaryHeartDiseaseMedicationOnlyDetails from
'../../components/StableCoronaryHeartDisease/medicationOnlyDetails';
import {HEART_CONDITIONS} from '../appConstants';
import BackNextButtons from '../../components/common/backNextButtons';

const Index = () => {
  const heartCondition = useSelector(state => state.heartCondition);

  return <>
    {
      heartCondition === HEART_CONDITIONS.HEART_ATTACK
        ? <HeartAttackMedicationOnlyDetails/>
        : <StableCoronaryHeartDiseaseMedicationOnlyDetails/>
    }
    <BackNextButtons
      backButtonRoute={ROUTES.EARLY_INVASIVE_DETAILS}
      nextButtonRoute={ROUTES.MY_RISK_FACTORS}/>
  </>;
};

export default Index;
