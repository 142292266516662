export const REDUX_STORE = {
  RESET_REDUX_STORE: 'RESET_REDUX_STORE'
};

export const HEALTH_FACTORS = {
  SAVE_HEALTH_FACTORS: 'SAVE_HEALTH_FACTORS',
  RESET_HEALTH_FACTORS: 'RESET_HEALTH_FACTORS'
};

export const HEALTH_CONDITIONS = {
  SET_HEALTH_CONDITIONS: 'SET_HEALTH_CONDITIONS',
  RESET_HEALTH_CONDITIONS: 'RESET_HEALTH_CONDITIONS'
};

export const UNLOCK_NAV_ITEMS = {
  SET_UNLOCK_ITEM: 'SET_UNLOCK_ITEMS',
  RESET_UNLOCK_ITEM: 'RESET_UNLOCK_ITEMS'
};

export const INITIAL_OPTIONS = {
  SAVE_INITIAL_OPTIONS: 'SAVE_INITIAL_OPTIONS',
  RESET_INITIAL_OPTIONS: 'RESET_INITIAL_OPTIONS'
};

export const MY_PRIORITIES = {
  SAVE_MY_PRIORITIES: 'SAVE_MY_PRIORITIES',
  RESET_MY_PRIORITIES: 'RESET_MY_PRIORITIES'
};

export const CHOICES = {
  SAVE_CHOICES: 'SAVE_CHOICES',
  RESET_CHOICES: 'RESET_CHOICES'
};

export const NEXT_STEPS = {
  SAVE_NEXT_STEPS: 'SAVE_NEXT_STEPS',
  RESET_NEXT_STEPS: 'RESET_NEXT_STEPS'
};
