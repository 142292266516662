/* eslint-disable camelcase */
import React from 'react';
import BenefitsSummary from './benefitsSummary';
import RisksSummary from './risksSummary';
import SubsequentCareSummary from './subsequentCareSummary';

const StableCoronary = () =>
  <>
    <BenefitsSummary/>
    <RisksSummary/>
    <SubsequentCareSummary/>
  </>;

export default StableCoronary;
