const ROUTES = {
  ROOT: '/',
  PRIVACY_POLICY: '/privacy-policy',
  DISCLAIMER: '/disclaimer',
  INTRODUCTION: '/introduction',
  HEART_CONDITION: '/heart-condition',
  HEALTH_FACTORS: '/health-factors',
  PROCEED_ON_PAPER: '/proceed-on-paper',
  ABOUT_THIS_TOOL: '/about-this-tool',
  EARLY_INVASIVE_DETAILS: '/early-invasive-details',
  INITIAL_OPTIONS: '/initial-options',
  MEDICATION_ONLY_DETAILS: '/medication-only-details',
  MY_RISK_FACTORS: '/my-risk-factors',
  WEIGHING_INFORMATION: '/weighing-information',
  BENEFITS_OVERVIEW: '/benefits-overview',
  RISKS_OVERVIEW: '/risks-overview',
  SUBSEQUENT_CARE_OVERVIEW: '/subsequent-care-overview',
  MY_PRIORITIES: '/my-priorities',
  CHOICES: '/choices',
  NEXT_STEPS: '/next-steps',
  DECISION_AID_SUMMARY: '/decision-aid-summary',
  SUMMARY_REPORT: '/summary-report',
  GLOSSARY: '/glossary/:id?'
};

export default ROUTES;
