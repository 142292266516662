import React from 'react';
import PropTypes from 'prop-types';
import figureBlue from '../../../assets/images/icons/figure_blue.svg';
import figureGreen from '../../../assets/images/icons/figure_green.svg';
import figureGrey from '../../../assets/images/icons/figure_grey.svg';

export const Figures = ({invasive, conservative}) => {
  const aryImg = [];
  const inv = parseInt(invasive, 10);
  const cons = parseInt(conservative, 10);

  for (let i = 0; i < cons; i++) {
    aryImg.push(figureBlue);
  }

  for (let j = 0; j < inv - cons; j++) {
    aryImg.push(figureGreen);
  }

  if (inv === 0) {
    for (let k = 0; k < 100 - cons; k++) {
      aryImg.push(figureGrey);
    }
  }
  else {
    for (let k = 0; k < 100 - inv; k++) {
      aryImg.push(figureGrey);
    }
  }

  return (
    <div>
      {aryImg.map((ele, idx) =>
        // eslint-disable-next-line react/no-array-index-key
        <span key={idx}>
          <img
            alt='Icon image'
            className='figure-style'
            src={ele}/>
          {(idx + 1) % 10 === 0 && <br/>}
        </span>)}
    </div>
  );
};

Figures.propTypes = {
  conservative: PropTypes.number.isRequired,
  invasive: PropTypes.string.isRequired
};
