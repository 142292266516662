import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          Hoenig MR, Aroney CN and Scott IA. Early invasive versus conservative strategies for
          unstable angina and non-ST elevation myocardial infarction in the stent era. Cochrane
          Database Syst Rev 2010.
        </p>
      </li>
      <li>
        <p>
          Shaw C, Nitsch D, Lee J, Fogarty D, Sharpe CC. Impact of an early invasive strategy
          versus conservative strategy for unstable angina and non-ST elevation acute coronary
          syndrome in patients with chronic kidney disease: A systematic review.
          PLoS One 2016;11:e0153478.
        </p>
      </li>
      <li>
        Charytan DM, Wallentin L, Lagerqvist B et al. Early angiography in patients with chronic
        kidney disease: a collaborative systematic review. Clin J Am Soc Nephrol 2009;4:1032-43.
      </li>
    </ol>
  </div>;
