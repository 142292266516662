// _PRACM_AHT stands for Predicted Risk Assuming Conservative Management - Avoiding Heart Attack
/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {_PRAIP_AHT} from './PRAIP_AHT';
import {FACTOR} from './eqConstants';

export const _PRACM_AHT = patientHealthFactors => {
  const PRIData = _PRAIP_AHT(patientHealthFactors);
  const predictedRiskConservative = PRIData.PRI * FACTOR.conservativeMgmt;

  const survivingNextYear = {
    PRC: predictedRiskConservative,
    mortality: 1 - predictedRiskConservative,
    probability: (1 - predictedRiskConservative) * 100
  };

  return survivingNextYear;
};
