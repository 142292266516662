import React, {useEffect} from 'react';
import {useHistory, useLocation, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ROUTES from '../../../routes';
import {useForm} from 'react-hook-form';
import {CHOICES} from '../appConstants';
import {resetChoices, saveChoices} from '../../../redux/actions/choices';
import {unlockNavItem} from '../../../redux/actions/unlockedNavItems';

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const choices = useSelector(state => state.choices);
  const {handleSubmit, register, formState: {errors}} = useForm({
    defaultValues: {
      choices: choices
    }
  });

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  const onSubmit = data => {
    dispatch(saveChoices(data));
    dispatch(unlockNavItem([
      ROUTES.NEXT_STEPS]));
    history.push(ROUTES.NEXT_STEPS);
  };

  const handleReset = () => {
    dispatch(resetChoices());
  };

  return (
    <>
      <section>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell'>
              <h1>What option are you leaning towards?</h1>
              <p>
                Check off one (or more) of the following five items. This will help you and your care team understand
                what you might still need to help make your care decision.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='top-rounded-borders'>
        <div className='grid-container'>
          <h2 className='required-field'>
            Please select one or more from the below options:
          </h2>
          <div>
            <p className='margin-0 explanation'>
              &apos;<span className='required-field-explanation'>*</span>&apos; indicates required field
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className='form-card'>
                <div className='grid-x align-middle'>
                  <div className='cell flex-align'>
                    <input
                      className='margin-1'
                      id={CHOICES.INVASIVE_HEART_PROCEDURE}
                      name='choices'
                      type='checkbox'
                      value={CHOICES.INVASIVE_HEART_PROCEDURE}
                      {...register(CHOICES.CHOICES, {required: true})}
                    />
                    <label
                      className='padding-vertical-1'
                      htmlFor={CHOICES.INVASIVE_HEART_PROCEDURE}>
                      <p className='margin-bottom-0'>
                        {CHOICES.INVASIVE_HEART_PROCEDURE}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-card'>
                <div className='grid-x align-middle'>
                  <div className='cell flex-align'>
                    <input
                      className='margin-1'
                      id={CHOICES.OPTIMAL_MEDICATION_MANAGEMENT}
                      name='choices'
                      type='checkbox'
                      value={CHOICES.OPTIMAL_MEDICATION_MANAGEMENT}
                      {...register(CHOICES.CHOICES, {required: true})}
                    />
                    <label
                      className='padding-vertical-1'
                      htmlFor={CHOICES.OPTIMAL_MEDICATION_MANAGEMENT}>
                      <p className='margin-bottom-0'>
                        {CHOICES.OPTIMAL_MEDICATION_MANAGEMENT}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-card'>
                <div className='grid-x align-middle'>
                  <div className='cell flex-align'>
                    <input
                      className='margin-1'
                      id={CHOICES.DISCUSS_WITH_DOCTOR}
                      name='choices'
                      type='checkbox'
                      value={CHOICES.DISCUSS_WITH_DOCTOR}
                      {...register(CHOICES.CHOICES, {required: true})}
                    />
                    <label
                      className='padding-vertical-1'
                      htmlFor={CHOICES.DISCUSS_WITH_DOCTOR}>
                      <p className='margin-bottom-0'>
                        {CHOICES.DISCUSS_WITH_DOCTOR}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-card'>
                <div className='grid-x align-middle'>
                  <div className='cell flex-align'>
                    <input
                      className='margin-1'
                      id={CHOICES.DISCUSS_WITH_FAMILY}
                      name='choices'
                      type='checkbox'
                      value={CHOICES.DISCUSS_WITH_FAMILY}
                      {...register(CHOICES.CHOICES, {required: true})}
                    />
                    <label
                      className='padding-vertical-1'
                      htmlFor={CHOICES.DISCUSS_WITH_FAMILY}>
                      <p className='margin-bottom-0'>
                        {CHOICES.DISCUSS_WITH_FAMILY}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-card'>
                <div className='grid-x align-middle'>
                  <div className='cell flex-align'>
                    <input
                      className='margin-1'
                      id={CHOICES.NEED_MORE_INFO}
                      name='choices'
                      type='checkbox'
                      value={CHOICES.NEED_MORE_INFO}
                      {...register(CHOICES.CHOICES, {required: true})}
                    />
                    <label
                      className='padding-vertical-1'
                      htmlFor={CHOICES.NEED_MORE_INFO}>
                      <p className='margin-bottom-0'>
                        {CHOICES.NEED_MORE_INFO}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {errors.choices && <span className='error'>This field is required</span>}
            <div className='grid-x small-up-4 align-spaced text-center margin-top-3'>
              <div className='cell'>
                <Link
                  className='hollow secondary button large rounded padding-horizontal-2'
                  to={ROUTES.MY_PRIORITIES}>Back</Link>
              </div>
              <div className='cell text-center'>
                <input
                  className='alert button large hollow rounded padding-horizontal-2'
                  onClick={() => handleReset()}
                  type='reset'
                  value='Reset Choices'
                />
              </div>
              <div className='cell'>
                <input
                  className='button large primary rounded padding-horizontal-2'
                  type='submit'
                  value='Next'/>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Index;
