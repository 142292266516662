import React from 'react';

export const SummaryReferences = () =>
  <section className='shadow all-rounded-borders white-bg-color margin-bottom-2'>
    <div className='grid-container'>
      <div className='cell large-9'>
        <h1>References</h1>
        <ol>
          <li>
            <p>
              Bangalore S, Maron DJ, O&apos;Brien SM, Fleg JL, Kretov EI, Briguori C, Kaul U, Reynolds HR, Mazurek T,
              Sidhu MS, Berger JS, Mathew RO, Bockeria O, Broderick S, Pracon R, Herzog CA, Huang Z, Stone GW,
              Boden WE, Newman JD, Ali ZA, Mark DB, Spertus JA, Alexander KP, Chaitman BR, Chertow GM, Hochman JS;
              ISCHEMIA-CKD Research Group. Management of Coronary Disease in Patients with Advanced Kidney Disease.
              N Engl J Med. 2020 Apr 23;382(17):1608-1618.
            </p>
          </li>
          <li>
            <p>
              Charytan DM, Wallentin L, Lagerqvist B et al. Early angiography in patients with chronic
              kidney disease: a collaborative systematic review. Clin J Am Soc Nephrol 2009;4:1032-43.
            </p>
          </li>
          <li>
            <p>
              Hoenig MR, Aroney CN and Scott IA. Early invasive versus conservative strategies for unstable
              angina and non-ST elevation myocardial infarction in the stent era. Cochrane Database
              Syst Rev 2010.
            </p>
          </li>
          <li>
            <p>
              James M., Tonelli M, Ghali W, Knudtson M, Faris P, Manns B. et al. Renal outcomes associated with
              invasive versus conservative management of acute coronary syndrome: propensity matched cohort study.
              BMJ 2013, 347(jul05 1), f4151-f4151. doi: 10.1136/bmj.f4151
            </p>
          </li>
          <li>
            <p>
              Ma B, Allen DW, Graham MM, Har BJ, Tyrrell B, Tan Z, Spertus JA, Brown JR, Matheny ME, Hemmelgarn BR,
              Pannu N, James MT. Comparative Performance of Prediction Models for Contrast-Associated Acute Kidney
              Injury After Percutaneous Coronary Intervention. Circ Cardiovasc Qual Outcomes. 2019 Nov;12(11):e005854.
            </p>
          </li>
          <li>
            <p>
              Shaw C, Nitsch D, Lee J, Fogarty D, Sharpe CC. Impact of an early invasive strategy
              versus conservative strategy for unstable angina and non-ST elevation acute coronary
              syndrome in patients with chronic kidney disease: A systematic review.
              PLoS One 2016;11:e0153478.
            </p>
          </li>
          <li>
            <p>
              Tsai, Thomas T, et al. “Validated Contemporary Risk Model of Acute Kidney Injury in Patients Undergoing
              Percutaneous Coronary Interventions: Insights from the National Cardiovascular Data Registry Cath-PCI
              Registry.” Journal of the American Heart Association, vol. 3, no. 6, 2014, p. e001380,
              www.ncbi.nlm.nih.gov/pubmed/25516439, 10.1161/JAHA.114.001380.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </section>;
