import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          Charytan DM, Wallentin L, Lagerqvist B et al. Early angiography in patients with chronic kidney
          disease: a collaborative systematic review. Clin J Am Soc Nephrol 2009;4:1032-43.
        </p>
      </li>
    </ol>
  </div>;
