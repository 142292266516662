import React, {useEffect, useState} from 'react';
import ROUTES from '../../../routes';
import {Link, useHistory} from 'react-router-dom';
import {saveMyPriorities, resetMyPriorities} from '../../../redux/actions/myPriorities';
import {useDispatch, useSelector} from 'react-redux';
import {unlockNavItem} from '../../../redux/actions/unlockedNavItems';
import {HEART_CONDITIONS, HEART_ATTACK_PRIORITIES, STABLE_CORONARY_HEART_DISEASE_PRIORITIES, HEALTH_FACTORS}
from '../../containers/appConstants';
import HeartIcon from '../../../assets/images/Heart.png';
import PillIcon from '../../../assets/images/Pill.png';
import QuestionMark from '../../../assets/images/_.png';

const MyPriorities = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const heartCondition = useSelector(state => state.heartCondition);
  let Priorities;

  if (heartCondition === HEART_CONDITIONS.HEART_ATTACK) {
    Priorities = [
      {title: HEART_ATTACK_PRIORITIES.LIVE_LONGER, icon: HEALTH_FACTORS.INVASIVE},
      {title: HEART_ATTACK_PRIORITIES.AVOID_HEART_ATTACK, icon: HEALTH_FACTORS.INVASIVE},
      {title: HEART_ATTACK_PRIORITIES.AVOID_DIALYSIS, icon: HEALTH_FACTORS.CONSERVATIVE},
      {title: HEART_ATTACK_PRIORITIES.AVOID_ANGIOGRAM, icon: HEALTH_FACTORS.CONSERVATIVE}
    ];
  }
  else if (heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE) {
    Priorities = [
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.DO_I_HAVE_SERIOUS_CORONARY_DISEASE,
        icon: HEALTH_FACTORS.INVASIVE},
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.REDUCE_CHEST_PAIN,
        icon: HEALTH_FACTORS.INVASIVE},
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.AVOID_HEART_SURGERY,
        icon: HEALTH_FACTORS.CONSERVATIVE},
      {title: STABLE_CORONARY_HEART_DISEASE_PRIORITIES.AVOID_KIDNEY_FAILURE,
        icon: HEALTH_FACTORS.CONSERVATIVE}
    ];
  }

  const myPriorities = useSelector(state => state.myPriorities);
  const [priorities, setPriorities] = useState(myPriorities);
  const [options, setOptions] = useState(myPriorities.length > 0 ? [] : Priorities);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(!(myPriorities.length > 0));

  const handlePriorities = priority => {
    if (!priorities.includes(priority)) {
      setPriorities([...priorities, priority]);
      setOptions(options.filter(o => o !== priority));
    }
  };

  const handleSave = () => {
    if (!isNextButtonDisabled) {
      if (options.length === 0) {
        dispatch(saveMyPriorities(priorities));
        dispatch(unlockNavItem([
          ROUTES.CHOICES
        ]));
        history.push(ROUTES.CHOICES);
      }
    }
  };

  const handleReset = () => {
    setPriorities([]);
    dispatch(resetMyPriorities());
    setOptions(Priorities);
    setIsNextButtonDisabled(true);
  };

  useEffect(() => {
    if (options.length === 1) {
      setPriorities([...priorities, options[0]]);
      setOptions([]);
      setIsNextButtonDisabled(false);
    }
  }, [options, priorities]);

  return (
    <>
      <section className='white-bg-color shadow all-rounded-borders'>
        <div className='grid-container margin-top-2 margin-bottom-3'>
          {
            options.length > 0 && <div>
              <h2>From the list below, please select in order of priority (tap what&apos;s most important to you first):
              </h2>
              {
                options.map(o => <div
                  className='form-card extra-round-borders'
                  key={o.title}
                  onClick={() => handlePriorities(o)}>
                  <div className='grid-x align-middle text-center'>
                    <div className='cell small-1'>
                      <img src={QuestionMark}/>
                    </div>
                    <div className='cell small-10 padding-1 text-left'>
                      {o.title}
                    </div>
                    <div className='cell small-1'>
                      {o.icon === HEALTH_FACTORS.INVASIVE ? <img src={HeartIcon}/> : <img src={PillIcon}/>}
                    </div>
                  </div>
                </div>)
              }
            </div>
          }
          <div>
            <h2>Your Priorities in Order</h2>
            {priorities.map((p, index) => <div
              className='form-card extra-round-borders padding-0'
              key={p.title}>
              <div className='grid-x align-middle text-center'>
                <div className='cell small-1 index align-self-stretch align-self-middle'>
                  {index + 1}
                </div>
                <div className='cell small-10 padding-1 text-left'>
                  {p.title}
                </div>
                <div className='cell small-1'>
                  {p.icon === HEALTH_FACTORS.INVASIVE ? <img src={HeartIcon}/> : <img src={PillIcon}/>}
                </div>
              </div>
            </div>)}
            {
              options.map(o => <div
                className='empty extra-round-borders form-card padding-0'
                key={o.title}/>)
            }
          </div>
        </div>
      </section>
      <section>
        <div className='grid-container'>
          <div className='grid-x small-up-4 align-spaced text-center margin-vertical-2'>
            <div className='cell'>
              <Link
                className='hollow secondary button large rounded padding-horizontal-2'
                to={ROUTES.DECISION_AID_SUMMARY}>Back</Link>
            </div>
            <div className='cell text-center'>
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                className='alert button large hollow rounded padding-horizontal-2'
                onClick={() => handleReset()}
              >Reset Priorities</button>
            </div>
            <div className='cell'>
              <button
                className={
                  `button large primary rounded padding-horizontal-2 ${isNextButtonDisabled
                    ? 'disabled'
                    : ''}`
                }
                onClick={() => handleSave()}
                type='button'>Next</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyPriorities;
