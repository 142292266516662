import React from 'react';
import MyInformation from '../common/myInformation';
import {Tooltip} from '../common/tooltip';
import {GLOSSARY_KEYS} from '../../containers/appConstants';

const MyRiskFactors = () =>
  <>
    <section>
      <div className='grid-container'>
        <div className='grid-x small-up-2 align-stretch'>
          <div className='cell'>
            <h1>Your Health Factors</h1>
          </div>
        </div>
        <h2>
            What other health factors may affect this choice?
        </h2>
        <div className='margin-vertical-2'>
          <p>
            The presence of
            &nbsp;<Tooltip
              id={GLOSSARY_KEYS.CHRONIC_KIDNEY_DISEASE}
              title='chronic kidney disease'/>&nbsp;
            may affect this choice because the heart procedures may cause damage to your kidneys (called an
            &nbsp;<Tooltip
              id={GLOSSARY_KEYS.ACUTE_KIDNEY_INJURY}
              title='acute kidney injury'/>&nbsp;). This can
            cause sudden kidney failure that requires treatment with
            &nbsp;<Tooltip
              id={GLOSSARY_KEYS.DIALYSIS}
              title='dialysis'/>. Your doctors can take steps to
            prevent kidney injury during these procedures by limiting the amount of dye used.
            They can also provide additional hydration to protect the kidneys.
          </p>
          <p>
            For patients who do develop a kidney injury, this is most often temporary. Kidney function will most likely
            improve after several days or weeks. Dialysis is rarely required and is usually only needed for a short
            time. However, for some patients with chronic kidney disease, improvement may not be complete, resulting
            in the development of permanent
            &nbsp;<Tooltip
              id={GLOSSARY_KEYS.END_STAGE_KIDNEY_FAILURE}
              title='(end-stage) kidney failure'/>.
          </p>
          <p>
            Your doctors have entered the following information to help give you more personalized information about
            the potential benefits and risks (harms) of
            &nbsp;<Tooltip
              id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
              title='invasive heart procedures'/>&nbsp;or
            &nbsp;<Tooltip
              id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
              title='optimal medication therapy'/>.
          </p>
        </div>
        <div>
          <MyInformation/>
        </div>
      </div>
    </section>
  </>;

export default MyRiskFactors;
