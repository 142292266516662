import {applyMiddleware, createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createRootReducer from './reducers';
import {routerMiddleware} from 'connected-react-router';
import googleAnalyticsMiddleware from './middleware/googleanalytics';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();
const middlewares = [
  routerMiddleware(history),
  googleAnalyticsMiddleware
];
const enhancers = [applyMiddleware(...middlewares)];
const persistConfig = {key: 'root', storage, blacklist: ['router']};
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

export default preloadedState => {
  const store = createStore(persistedReducer,
    preloadedState,
    composeWithDevTools(...enhancers),);
  const persistor = persistStore(store);

  // persistor.purge();

  return {store, persistor};
};
