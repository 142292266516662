import {CHOICES} from '../constants';
const initialState = null;

export default function heartCondition(state = initialState, action) {
  switch (action.type) {
  case CHOICES.SAVE_CHOICES: {
    return action.choices.choices;
  }

  case CHOICES.RESET_CHOICES: {
    return initialState;
  }

  default:
    return state;
  }
}
