import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import BackNextButtons from '../../components/common/backNextButtons';
import ROUTES from '../../../routes';
import {HEART_CONDITIONS} from '../appConstants';
import HeartAttack from '../../components/HeartAttack/DecisionAidSummary/index';
import StableCoronary from '../../components/StableCoronaryHeartDisease/DecisionAidSummary/index';

const Index = () => {
  const heartCondition = useSelector(state => state.heartCondition);
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return (
    <>
      <section>
        <div className='grid-container'>
          <div>
            <h1>
              Your Decision Aid Summary
            </h1>
            <h3 className='text-uppercase primary-color'>
              Your treatment options:
            </h3>
          </div>
        </div>
      </section>
      <section className='all-rounded-borders'>
        <div className='grid-container'>
          <div className='text-center margin-vertical-2'>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <p className='margin-1'>
                    Procedures to find and unblock blood vessels
                    combined with medications that aim to
                    protect the heart from further injury.
                  </p>
                </div>
              </div>
              <div className='cell'>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <p className='margin-1'>
                    Attempt to treat with medications alone and
                    avoid invasive heart procedures.
                  </p>
                </div>
              </div>
            </div>
            {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
              <HeartAttack/>
            }
            {heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
              <StableCoronary/>
            }
          </div>
        </div>
      </section>
      <BackNextButtons
        backButtonRoute={ROUTES.SUBSEQUENT_CARE_OVERVIEW}
        nextButtonRoute={ROUTES.MY_PRIORITIES}/>
    </>
  );
};

export default Index;
