/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import risksInfo from '../../../../assets/images/icons/risks_info.svg';
import RisksEquationResult from '../../../components/common/risksEquationResult';
import {
  _PRAIP_AKIRD
} from '../../../equations/heartAttackBranch/likelihoodOfAcuteKidneyInjuryRequiringDialysis/PRAIP_AKIRD';
import {
  _PRACM_AKIRD
} from '../../../equations/heartAttackBranch/likelihoodOfAcuteKidneyInjuryRequiringDialysis/PRACM_AKIRD';
import {_PRAIP_ESKF} from '../../../equations/heartAttackBranch/likelihoodOfEndStageKidneyFailureWithInYear/PRAIP_ESKF';
import {_PRACM_ESKF} from '../../../equations/heartAttackBranch/likelihoodOfEndStageKidneyFailureWithInYear/PRACM_ESKF';
import {useSelector} from 'react-redux';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';

const RisksSummary = () => {
  const healthFactors = useSelector(state => state.healthFactors);
  const [
    acuteKidneyInjuryDialysisInvasiveDetails,
    setAcuteKidneyInjuryDialysisInvasiveDetails] = useState({});
  const [
    acuteKidneyInjuryDialysisConservativeDetails,
    setAcuteKidneyInjuryDialysisConservativeDetails] = useState({});
  const [
    endStageKidneyFailureInvasiveDetails,
    setEndStageKidneyFailureInvasiveDetails] = useState({});
  const [
    endStageKidneyFailureConservativeDetails,
    setEndStageKidneyFailureConservativeDetails] = useState({});

  useEffect(() => {
    setAcuteKidneyInjuryDialysisInvasiveDetails(_PRAIP_AKIRD(healthFactors));
    setAcuteKidneyInjuryDialysisConservativeDetails(_PRACM_AKIRD(healthFactors));
    setEndStageKidneyFailureInvasiveDetails(_PRAIP_ESKF(healthFactors));
    setEndStageKidneyFailureConservativeDetails(_PRACM_ESKF(healthFactors));
  }, []);

  return (
    <>
      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of an acute kidney injury requiring dialysis</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <RisksEquationResult
              className='border-right'
              resultDetails={acuteKidneyInjuryDialysisInvasiveDetails.PRI}/>
          </div>
          <div className='cell'>
            <RisksEquationResult
              resultDetails={acuteKidneyInjuryDialysisConservativeDetails.PRC}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={risksInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            {
              Number(acuteKidneyInjuryDialysisInvasiveDetails.PRI * 100).toFixed(0) -
              Number(acuteKidneyInjuryDialysisConservativeDetails.PRC * 100).toFixed(0) === 0
                ? <p className='margin-0'>
                  Treatment with an&nbsp;<Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>no additional
                      acute kidney injuries requiring dialysis for every 100 patients</span>&nbsp; like you.
                </p>
                : <p className='margin-0'>
                  Treatment with an&nbsp;<Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>{
                    Number(acuteKidneyInjuryDialysisInvasiveDetails.PRI * 100).toFixed(0) -
                  Number(acuteKidneyInjuryDialysisConservativeDetails.PRC * 100).toFixed(0)
                  } additional
                      acute kidney injuries requiring dialysis for every 100 patients</span>&nbsp; like you compared
                  to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>.
                </p>
            }
          </div>
        </div>
      </div>

      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of End Stage Kidney Failure within one year</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <RisksEquationResult
              className='border-right'
              resultDetails={endStageKidneyFailureInvasiveDetails.PRI}/>
          </div>
          <div className='cell'>
            <RisksEquationResult
              resultDetails={endStageKidneyFailureConservativeDetails.PRC}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={risksInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            {
              Number(acuteKidneyInjuryDialysisInvasiveDetails.PRI * 100).toFixed(0) -
              Number(acuteKidneyInjuryDialysisConservativeDetails.PRC * 100).toFixed(0) === 0
                ? <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>no additional
                      end-stage kidney failures for every 100 patients</span>&nbsp; like you.
                </p>
                : <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>
                    {Number(acuteKidneyInjuryDialysisInvasiveDetails.PRI * 100).toFixed(0) -
                  Number(acuteKidneyInjuryDialysisConservativeDetails.PRC * 100).toFixed(0)} additional
                      end-stage kidney failures for every 100 patients</span>&nbsp; like you compared to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>.
                </p>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default RisksSummary;
