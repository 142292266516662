/* eslint-disable react/prop-types */
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

const sample = [83.33, 50, 16.66, 0];
const days = ['Daily', 'Weekly', 'Monthy', 'None'];

const fun1 = (a, b) => days[b];

// eslint-disable-next-line react/prop-types
const CustomizedAxisTick = ({x, y, payload}) =>
  <g transform={`translate(${x},${y})`}>
    <text
      dy={16}
      fill='#666'
      x={0}
      y={0}>
      <tspan
        textAnchor='middle'
        x='0'>
        {payload.value.split(' ')[0]}
      </tspan>
      <tspan
        dy='20'
        textAnchor='middle'
        x='0'>
        {payload.value.split(' ')[1]}
      </tspan>
    </text>
  </g>;

const CustomizedDot = ({cx, cy, payload}) => {
  if (payload.dataLabel === 'Before Treatment') {
    return (
      <svg
        fill='none'
        height='18'
        viewBox='0 0 18 18'
        width='18'
        x={cx - 10}
        xmlns='http://www.w3.org/2000/svg'
        y={cy - 10}
      >
        <circle
          cx='9'
          cy='9'
          r='7'
          stroke='#585865'
          strokeWidth='4'/>
      </svg>
    );
  }
  else if (payload.dataLabel === '3 months') {
    return (
      <svg
        fill='none'
        height='18'
        viewBox='0 0 18 18'
        width='18'
        x={cx - 10}
        xmlns='http://www.w3.org/2000/svg'
        y={cy - 10}>
        <circle
          cx='9'
          cy='9'
          r='7'
          stroke='#279D33'
          strokeWidth='4'/>
      </svg>
    );
  }

  return (
    <svg
      fill='none'
      height='18'
      viewBox='0 0 18 18'
      width='18'
      x={cx - 10}
      xmlns='http://www.w3.org/2000/svg'
      y={cy - 10}>
      <circle
        cx='9'
        cy='9'
        r='7'
        stroke='#0D71E5'
        strokeWidth='4'/>
    </svg>
  );
};

export const Graphs = data =>
  <ResponsiveContainer
    aspect={2 / 1.5}
    width='100%'>
    <LineChart
      data={data.data}
      isAnimationActive={false}
    >
      <CartesianGrid vertical={false}/>
      <XAxis
        dataKey='dataLabel'
        height={50}
        padding={{left: 50, right: 50}}
        style={{fontSize: 10}}
        tick={<CustomizedAxisTick/>}
        tickLine={false}
        type='category'
      />
      <YAxis
        domain={[0, 100]}
        hide
        ticks={[33.33, 66.66, 100]}
        yAxisId={0}/>
      <YAxis
        axisLine={false}
        domain={[0, 100]}
        tickFormatter={fun1}
        tickLine={false}
        ticks={sample}
        yAxisId={1}
      />
      <Line
        dataKey='dataValue'
        dot={<CustomizedDot/>}
        stroke='#585865'
        strokeDasharray='5 5'
        /*
         * dot={{strokeWidth: 4, r: 10}}
         * stroke='#82ca9d'
         * strokeWidth={3}
         */
        strokeWidth={1}
      />
    </LineChart>
  </ResponsiveContainer>;
