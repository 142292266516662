import React from 'react';
import {useHistory} from 'react-router-dom';

const Index = () => {
  const history = useHistory();

  return (
    <section>
      <div className='grid-container'>
        <h2 className='font-bold'>Privacy Policy</h2>
        <p>We are committed to protecting the privacy of people using this decision aid. Some aspects of this
          decision aid invite you to enter personal information about you or your medical history. Although this
          tool allows you and your health care provider to enter your health information to personalize the
          information for you and display it or print it, it does not save, store, or transmit your information to
          anyone else, and all information is erased after closing or resetting the application to ensure privacy.
        </p>
        <p>For full functionality of this website, we use a browser cookie that is saved only on your computer. You
          may easily clear all data by clicking a button on the concluding page of the website. Clearing your web
          browser cache will also delete your answers.
        </p>
        <div className='text-center margin-3'>
          <button
            className='hollow secondary button large rounded padding-horizontal-3'
            onClick={() => history.goBack()}
            type='button'>
            Back
          </button>
        </div>
      </div>
    </section>
  );
};

export default Index;
