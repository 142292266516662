export const COEFFICIENTS = {
  age: -0.0678,
  sex: 0.0416,
  eGFR: -0.0746,
  hemo: -0.0230,
  albuminuriaMild: 0.1172,
  albuminuriaHeavy: 1.3711,
  diabetes: 0.2573,
  priorInv: 0.0480,
  interTerm: -7.6689
};

export const BASELINES = {
  age: 73.32,
  eGFR: 53.87,
  hemo: 131.68
};

export const FACTOR = {
  conservativeMgmt: 1 / 1.20
};
