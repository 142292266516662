import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          Ma B, Allen DW, Graham MM, Har BJ, Tyrrell B, Tan Z, Spertus JA, Brown JR, Matheny ME, Hemmelgarn BR,
          Pannu N, James MT. Comparative Performance of Prediction Models for Contrast-Associated Acute Kidney
          Injury After Percutaneous Coronary Intervention. Circ Cardiovasc Qual Outcomes. 2019 Nov;12(11):e005854.
        </p>
      </li>
      <li>
        <p>
          James MT, et al. “Associations between Acute Kidney Injury and Cardiovascular and Renal Outcomes after
          Coronary Angiography.” Circulation, vol. 123, no. 4, 2011, pp. 409–16,
          www.ncbi.nlm.nih.gov/pubmed/21242477, 10.1161/CIRCULATIONAHA.110.970160.
        </p>
      </li>
      <li>
        <p>
          Tsai, Thomas T, et al. “Validated Contemporary Risk Model of Acute Kidney Injury in Patients Undergoing
          Percutaneous Coronary Interventions: Insights from the National Cardiovascular Data Registry Cath-PCI
          Registry.” Journal of the American Heart Association, vol. 3, no. 6, 2014, p. e001380,
          www.ncbi.nlm.nih.gov/pubmed/25516439, 10.1161/JAHA.114.001380.
        </p>
      </li>
    </ol>
  </div>;
