import React from 'react';
import {useSelector} from 'react-redux';

const SummaryNextSteps = () => {
  const nextSteps = useSelector(state => state.nextSteps);
  return (
    <>
      <section className='shadow all-rounded-borders white-bg-color margin-bottom-2 page-break-before'>
        <div className='grid-container'>
          <div>
            <h1>How comfortable are you in your choice?</h1>
            <p>
              Making a decision like this is not easy, and your care team wants to make sure you are comfortable
              with the decision you make. The following questions will help you and your care team understand how
              comfortable you are with your choice.
            </p>
          </div>
          <div className='cell large-9 margin-vertical-2'>
            <table className='unstriped width-100 table-print-styles'>
              <tbody>
                {
                  !nextSteps &&
                <tr>
                  <td className='padding-1 bordered font-bold'>Question</td>
                  <td className='padding-1 bordered width-10 font-bold'>Yes</td>
                  <td className='padding-1 bordered width-10 font-bold'>No</td>
                </tr>
                }
                <tr>
                  <td className='padding-1 bordered'>
                    Do you feel you know the potential benefits and risks of each option?
                  </td>
                  {
                    nextSteps
                      ? <td className='padding-1 bordered width-10'>
                        {nextSteps.KNOW_POTENTIAL_BENEFITS_AND_RISKS}
                      </td>
                      : <>
                        <td className='padding-1 bordered width-10'/>
                        <td className='padding-1 bordered width-10'/>
                      </>
                  }
                </tr>
                <tr>
                  <td className='padding-1 bordered'>
                    Are you clear about which benefits and risks matter most to you?
                  </td>
                  {
                    nextSteps
                      ? <td className='padding-1 bordered width-10'>
                        {nextSteps.BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU}
                      </td>
                      : <>
                        <td className='padding-1 bordered width-10'/>
                        <td className='padding-1 bordered width-10'/>
                      </>
                  }
                </tr>
                <tr>
                  <td className='padding-1 bordered'>
                    Do you have enough support and advice to make a choice?
                  </td>
                  {
                    nextSteps
                      ? <td className='padding-1 bordered width-10'>
                        {nextSteps.HAVE_ENOUGH_SUPPORT_AND_ADVICE}
                      </td>
                      : <>
                        <td className='padding-1 bordered width-10'/>
                        <td className='padding-1 bordered width-10'/>
                      </>
                  }
                </tr>
                <tr>
                  <td className='padding-1 bordered'>
                    Do you feel sure about the best choice for you?
                  </td>
                  {
                    nextSteps
                      ? <td className='padding-1 bordered width-10'>
                        {nextSteps.SURE_ABOUT_BEST_CHOICE_FOR_YOU}
                      </td>
                      : <>
                        <td className='padding-1 bordered width-10'/>
                        <td className='padding-1 bordered width-10'/>
                      </>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default SummaryNextSteps;
