import {UNLOCK_NAV_ITEMS} from '../constants';

export const unlockNavItem = pages => ({
  type: UNLOCK_NAV_ITEMS.SET_UNLOCK_ITEM,
  pages
});

export const resetUnlockedNavItems = () => ({
  type: UNLOCK_NAV_ITEMS.RESET_UNLOCK_ITEM
});
