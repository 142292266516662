// _PRAIP_IOCP stands for Predicted Risk Assuming Invasive Procedure - Impact On Chest Pain
/* eslint-disable camelcase */

import {HEALTH_FACTORS} from '../../../containers/appConstants';

export const _PRAIP_IOCP_above_30 = patientHealthFactors => {
  const eGFR = patientHealthFactors.egfr;
  const chestPain = patientHealthFactors.chestPainSymptoms;
  let IHP = 0;

  if (eGFR >= 30) {
    if (chestPain === HEALTH_FACTORS.DAILY) {
      IHP = 24;
    }
    else if (chestPain === HEALTH_FACTORS.WEEKLY) {
      IHP = 45;
    }
    else if (chestPain === HEALTH_FACTORS.MONTHLY) {
      IHP = 75;
    }
    else if (chestPain === HEALTH_FACTORS.NONE) {
      IHP = 92;
    }
  }

  return IHP;
};

export const _PRAIP_IOCP_below_30 = patientHealthFactors => {
  const eGFR = patientHealthFactors.egfr;
  const chestPain = patientHealthFactors.chestPainSymptoms;
  let IHP = {};

  if (eGFR < 30) {
    if (chestPain === HEALTH_FACTORS.DAILY) {
      IHP = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 85
        },
        {
          dataLabel: '3 months',
          dataValue: 67
        },
        {
          dataLabel: '1 year',
          dataValue: 77
        }
      ];
    }
    else if (chestPain === HEALTH_FACTORS.WEEKLY) {
      IHP = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 55
        },
        {
          dataLabel: '3 months',
          dataValue: 37
        },
        {
          dataLabel: '1 year',
          dataValue: 47
        }
      ];
    }
    else if (chestPain === HEALTH_FACTORS.MONTHLY) {
      IHP = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 20
        },
        {
          dataLabel: '3 months',
          dataValue: 10
        },
        {
          dataLabel: '1 year',
          dataValue: 12
        }
      ];
    }
    else if (chestPain === HEALTH_FACTORS.NONE) {
      IHP = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 0
        },
        {
          dataLabel: '3 months',
          dataValue: 0
        },
        {
          dataLabel: '1 year',
          dataValue: 0
        }
      ];
    }
  }

  return IHP;
};
