import {HEALTH_CONDITIONS} from '../constants';
const initialState = null;

export default function heartCondition(state = initialState, action) {
  switch (action.type) {
  case HEALTH_CONDITIONS.SET_HEALTH_CONDITIONS: {
    return action.condition;
  }

  case HEALTH_CONDITIONS.RESET_HEALTH_CONDITIONS: {
    return initialState;
  }

  default:
    return state;
  }
}
