import {MY_PRIORITIES} from '../constants';
const initialState = [];

export default function heartCondition(state = initialState, action) {
  switch (action.type) {
  case MY_PRIORITIES.SAVE_MY_PRIORITIES: {
    return action.priorities;
  }

  case MY_PRIORITIES.RESET_MY_PRIORITIES: {
    return initialState;
  }

  default:
    return state;
  }
}
