import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  _PRAIP_AKIRD as _PRAIP_AKIRD_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfAcuteKidneyInjuryRequiringDialysis/PRAIP_AKIRD';
import {
  _PRACM_AKIRD as _PRACM_AKIRD_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfAcuteKidneyInjuryRequiringDialysis/PRACM_AKIRD';
import {
  _PRAIP_ESKF as _PRAIP_ESKF_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfEndStageKidneyFailureWithInYear/PRAIP_ESKF';
import {
  _PRACM_ESKF as _PRACM_ESKF_SCHD
} from '../../../equations/stableCornaryBranch/likelihoodOfEndStageKidneyFailureWithInYear/PRACM_ESKF';
import {Details as AcutekidneyInjuryDetails} from './AcuteKidneyInjury/details';
import {References as AcuteKidneyInjuryReferences} from './AcuteKidneyInjury/references';
import {AdditionalInfo as AcuteKidneyInjuryAddInfo} from './AcuteKidneyInjury/additionalInfo';
import {Details as EndStageKidneyFailureDetails} from './EndStageKidneyFailure/details';
import {References as EndStageKidneyFailureReferences} from './EndStageKidneyFailure/references';
import {AdditionalInfo as EndStageKidneyFailureAddInfo} from './EndStageKidneyFailure/additionalInfo';
import risksInfo from '../../../../assets/images/icons/risks_info.svg';
import risksSymbol from '../../../../assets/images/icons/risks_symbol.svg';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';
import {getFormattedResult} from '../../../utils/utils';

export const RisksDetails = () => {
  const healthFactors = useSelector(state => state.healthFactors);

  const [
    acuteKidneyInjuryDialysisInvasiveDetailsForSCHD,
    setAcuteKidneyInjuryDialysisInvasiveDetailsForSCHD] = useState({});
  const [
    acuteKidneyInjuryDialysisConservativeDetailsForSCHD,
    setAcuteKidneyInjuryDialysisConservativeDetailsForSCHD] = useState({});
  const [
    endStageKidneyFailureInvasiveDetailsForSCHD,
    setEndStageKidneyFailureInvasiveDetailsForSCHD] = useState({});
  const [
    endStageKidneyFailureConservativeDetailsForSCHD,
    setEndStageKidneyFailureConservativeDetailsForSCHD] = useState({});

  useEffect(() => {
    setAcuteKidneyInjuryDialysisInvasiveDetailsForSCHD(_PRAIP_AKIRD_SCHD(healthFactors));
    setAcuteKidneyInjuryDialysisConservativeDetailsForSCHD(_PRACM_AKIRD_SCHD(healthFactors));
    setEndStageKidneyFailureInvasiveDetailsForSCHD(_PRAIP_ESKF_SCHD(healthFactors));
    setEndStageKidneyFailureConservativeDetailsForSCHD(_PRACM_ESKF_SCHD(healthFactors));
  }, []);

  return (
    <>
      <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
        <div className='grid-container'>
          <div className='text-center'>
            <h2>Likelihood of acute kidney injury requiring dialysis treatment</h2>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-red margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={risksSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0))}/100)
                  </p>
                </div>
              </div>
              <div className='cell'>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-red margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={risksSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0))}/100)
                  </p>
                </div>
              </div>
            </div>
            <div className='grid-x align-center-middle'>
              <div className='cell shrink margin-0'>
                <img
                  alt='Icon image'
                  className='margin-right-1'
                  src={risksInfo}/>
              </div>
              <div className='cell auto margin-0 text-left'>
                {
                  Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0) -
                Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0) === 0
                    ? <p className='margin-0'>
                    Treatment with an&nbsp;
                      <Tooltip
                        id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                        title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>&nbsp;
                    no additional acute kidney injuries requiring dialysis per 100 patients</span>&nbsp;like you.

                    </p>
                    : <p className='margin-0'>
                    Treatment with an&nbsp;
                      <Tooltip
                        id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                        title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>
                        {
                          Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0) -
                     Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0)
                        }
                        &nbsp;additional acute kidney injuries requiring dialysis per 100 patients</span>&nbsp;like you.
                    </p>
                }
              </div>
            </div>
            {/* The below section is only visible on screen, not while printing */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>What does this look like?</h3>
                  </summary>
                  <AcutekidneyInjuryDetails
                    conservativeProb={Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0)}
                    invasiveProb={Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0)}
                  />
                </details>
              </div>
            </div>
            {/* The below section is only visible while printing */}
            <div className='margin-vertical-2 padding-vertical-2 show-for-print bordered all-rounded-borders text-left'>
              <AcutekidneyInjuryDetails
                conservativeProb={Number(acuteKidneyInjuryDialysisConservativeDetailsForSCHD.PRC * 100).toFixed(0)}
                invasiveProb={Number(acuteKidneyInjuryDialysisInvasiveDetailsForSCHD.PRI * 100).toFixed(0)}
              />
              <div className='padding-horizontal-2'>
                <AcuteKidneyInjuryAddInfo/>
              </div>
            </div>
            {/* The below section is only visible on screen */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>
                      What is this information based on? (Show me the references)
                    </h3>
                  </summary>
                  <AcuteKidneyInjuryReferences/>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
        <div className='grid-container'>
          <div className='text-center'>
            <h2>Likelihood of end-stage kidney failure within one year</h2>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-red margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={risksSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0))}/100)
                  </p>
                </div>
              </div>
              <div className='cell '>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-red margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={risksSymbol}/>
                      <p className='margin-0'>
                        {getFormattedResult(Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0))}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({getFormattedResult(Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0))}/100)
                  </p>
                </div>
              </div>
            </div>
            <div className='grid-x align-center-middle'>
              <div className='cell shrink margin-0'>
                <img
                  alt='Icon image'
                  className='margin-right-1'
                  src={risksInfo}/>
              </div>
              <div className='cell auto margin-0 text-left'>
                {
                  Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0) -
                  Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0) === 0
                    ? <p>
                      Treatment with an&nbsp;
                      <Tooltip
                        id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                        title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>
                    &nbsp;no additional end-stage kidney failures per 100 patients</span>&nbsp;like you

                    </p>
                    : <p className='margin-0'>
                      Treatment with an&nbsp;
                      <Tooltip
                        id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                        title='invasive heart procedure'/>&nbsp;caused&nbsp;<span className='risks-text'>{
                        Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0) -
                      Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0)
                      }&nbsp;additional end-stage kidney failures per 100 patients</span>&nbsp;like you.
                    </p>
                }
              </div>
            </div>
            {/* The below section is only visible on screen, not while printing */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>What does this look like?</h3>
                  </summary>
                  <EndStageKidneyFailureDetails
                    conservativeProb={Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0)}
                    invasiveProb={Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0)}
                  />
                </details>
              </div>
            </div>
            {/* The below section is only visible while printing */}
            <div className='margin-vertical-2 padding-vertical-2 show-for-print bordered all-rounded-borders text-left'>
              <EndStageKidneyFailureDetails
                conservativeProb={Number(endStageKidneyFailureConservativeDetailsForSCHD.PRC * 100).toFixed(0)}
                invasiveProb={Number(endStageKidneyFailureInvasiveDetailsForSCHD.PRI * 100).toFixed(0)}
              />
              <div className='padding-horizontal-2'>
                <EndStageKidneyFailureAddInfo/>
              </div>
            </div>
            {/* The below section is only visible on screen */}
            <div className='grid-x text-left margin-vertical-1 hide-for-print'>
              <div className='cell bordered all-rounded-borders'>
                <details>
                  <summary className='padding-2'>
                    <h3 className='info-color text-uppercase'>
                      What is this information based on? (Show me the references)
                    </h3>
                  </summary>
                  <EndStageKidneyFailureReferences/>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
