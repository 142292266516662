import React from 'react';

export const AdditionalInfo = () =>
  <p>
    Individualized estimates of the risk of end-stage kidney failure in the next year were determined using
    multivariable prediction models developed from a population-based cohort of 11,980 adult patients with
    chronic kidney disease hospitalized with a heart attack in Alberta, Canada between 2004-2017. Treatment
    effects from invasive heart procedures were then applied to these risks for each participant to obtain
    personalized estimates of absolute risk for an individual under each of the two treatment options. Results
    from published randomized controlled trials were used to determine the effects of the treatment option on
    the outcome of end-stage kidney disease. The overall rating of the strength of evidence for the
    outcomes is “weak” (meaning the true effect might be markedly different from the estimated effect).
    The certainty of this evidence was affected by imprecision (there is a wide range of plausible estimates
    around the best estimates of the effects of treatment) and inconsistency (there are differences in effects
    between individual studies), and risk of bias (estimates for acute kidney injury requiring dialysis
    were from observational studies).
  </p>;
