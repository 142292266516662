import React from 'react';
import PropTypes from 'prop-types';
import benefitsSymbol from '../../../assets/images/icons/benefits_symbol.svg';
import {getFormattedResult} from '../../utils/utils';

const BenefitsEquationResult = ({resultDetails, className}) => <div className={`card align-center ${className}`}>
  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
    <div className='rounded inside-circle'>
      <img
        alt='Icon image'
        src={benefitsSymbol}/>
      <p className='margin-0'>
        {getFormattedResult(Number(resultDetails).toFixed(0))}%
      </p>
    </div>
  </div>
  <p>
      ({getFormattedResult(Number(resultDetails).toFixed(0))}/100)
  </p>
</div>;

export default BenefitsEquationResult;

BenefitsEquationResult.propTypes = {
  className: PropTypes.string,
  resultDetails: PropTypes.number
};
