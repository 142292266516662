import React from 'react';
import ROUTES from '../../../routes';
import BackNextButtons from '../../components/common/backNextButtons';
import {Tooltip} from '../../components/common/tooltip';
import {GLOSSARY_KEYS} from '../appConstants';

const Index = () =>
  <>
    <section>
      <div className='grid-container'>
        <h3 className='text-uppercase primary-color'>Inclusion/Exclusion Criteria:</h3>
        <div className='grid-y'>
          <div className='cell'>
            <h1 className='font-bold'>This decision aid is for:</h1>
            <ul>
              <li>
                <p>
                  Those with&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.CHRONIC_KIDNEY_DISEASE}
                    title='chronic kidney disease'/>&nbsp;
                  <span>AND</span> have recently had a&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.HEART_ATTACK}
                    title='heart attack'/>&nbsp;(non-ST
                  elevation acute coronary syndrome (ACS)).
                </p>
              </li>
              <h3 className='info-color'>OR</h3>
              <li>
                <p>
                  Those with chronic kidney disease <span>AND</span> have symptoms or signs of stable coronary
                  heart disease.
                </p>
              </li>
            </ul>
          </div>
          <div className='cell'>
            <h1 className='font-bold'>This decision aid is <span className='success-color'>NOT</span> for:</h1>
            <ul>
              <li>
                <p>Those who do not have kidney disease.</p>
              </li>
              <li>
                <p>
                  Those with&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.END_STAGE_KIDNEY_FAILURE}
                    title='end-stage kidney failure'/>&nbsp;
                  and already treated with&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.DIALYSIS}
                    title='dialysis'/>&nbsp;, kidney transplant,
                  or&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.EGFR}
                    title='eGFR'/>&nbsp;, &lt; 10mL/min/1.73m<sup>2</sup>.</p>
              </li>
              <li>
                <p>Those with an ST-elevation heart attack.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className='dark border-right'>
      <div className='grid-container text-center'>
        <h2 className='h2'>
          If you are not sure if this decision aid applies to you, please speak to your doctor.
        </h2>
      </div>
    </section>
    <BackNextButtons
      backButtonRoute={ROUTES.ROOT}
      nextButtonRoute={ROUTES.HEART_CONDITION}/>
  </>;

export default Index;
