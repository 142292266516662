import React from 'react';
import MyInformation from '../common/myInformation';
import {Tooltip} from '../common/tooltip';
import {GLOSSARY_KEYS} from '../../containers/appConstants';

const MyRiskFactors = () =>
  <>
    <section>
      <div className='grid-container'>
        <div className='grid-x small-up-2 align-stretch'>
          <div className='cell'>
            <h1>Your Health Factors</h1>
          </div>
        </div>
        <h2>
            What other health factors may affect this choice?
        </h2>
        <div className='margin-vertical-2'>
          <p>
            The presence of chronic kidney disease may affect this choice because the heart procedures may cause
            damage to your kidneys (&nbsp;
            <Tooltip
              id={GLOSSARY_KEYS.ACUTE_KIDNEY_INJURY}
              title='acute kidney injury'/>&nbsp;). This can occasionally
            cause sudden kidney failure that requires treatment with dialysis. Your doctors can take steps to prevent
            kidney injury during these procedures by limiting the amount of dye used. They can also provide additional
            hydration to protect the kidneys.
          </p>
          <p>
              For patients who do develop a kidney injury, this is most often temporary. Kidney function will most
              likely improve after several days or weeks.
              &nbsp;<Tooltip
              id={GLOSSARY_KEYS.DIALYSIS}
              title='Dialysis'/>&nbsp;
              is rarely required and usually only needed for a
              short time. However, for some patients with
              &nbsp;<Tooltip
              id={GLOSSARY_KEYS.CHRONIC_KIDNEY_DISEASE}
              title='chronic kidney disease'/>&nbsp;,
              improvement may not be complete, resulting in the development of permanent
              &nbsp;<Tooltip
              id={GLOSSARY_KEYS.END_STAGE_KIDNEY_FAILURE}
              title='(end-stage) kidney failure'/>&nbsp;
              sooner than it otherwise would have occurred.
          </p>
          <p>
              Your health care provider has entered the following information to give you more personalized information
              comparing the benefits and risks of
              &nbsp;<Tooltip
              id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
              title='invasive heart procedures'/>&nbsp;or
              &nbsp;<Tooltip
              id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
              title='optimal medication therapy'/>.
          </p>
          <div>
            <MyInformation/>
          </div>
          <p>
            Because every patient is unique, it is impossible to predict the exact outcome of your decision.
            Based on your information above, this decision aid has personalized the risks and benefits of each option
            for you. The following screens will show you what the research shows for a patient like you, to help you
            make your decision.
          </p>
        </div>
      </div>
    </section>
  </>;

export default MyRiskFactors;
