import React from 'react';
import PropTypes from 'prop-types';
import {HEART_CONDITIONS} from '../appConstants';
import HeartAttackEarlyInvasiveDetails from '../../components/HeartAttack/earlyInvasiveDetails';
import StableCoronaryHeartDiseaseEarlyInvasiveDetails
from '../../components/StableCoronaryHeartDisease/earlyInvasiveDetails';
import HeartAttackMedicationOnlyDetails from '../../components/HeartAttack/medicationOnlyDetails';
import StableCoronaryHeartDiseaseMedicationOnlyDetails
from '../../components/StableCoronaryHeartDisease/medicationOnlyDetails';

export const SummaryInitialOptions = heartCondition =>
  <section className='shadow all-rounded-borders white-bg-color margin-bottom-2'>
    <div className='grid-container'>
      <h2>
        Your Options
      </h2>
      <h3 className='info-color text-uppercase'>
        Invasive Heart Procedure plus Optimal Medication Management
      </h3>
      <p>
        Procedures to find and unblock blood vessels combined with medications that aim to protect the heart
        from further injury.
      </p>
      {
        heartCondition === HEART_CONDITIONS.HEART_ATTACK
          ? <HeartAttackEarlyInvasiveDetails/>
          : <StableCoronaryHeartDiseaseEarlyInvasiveDetails/>
      }
      <h2>OR</h2>
      <h3 className='info-color text-uppercase'>
        Optimal Medication Management
      </h3>
      <p>
        Attempt to treat with medications alone and avoid invasive heart procedures, if possible.
      </p>
      <div>
        {
          heartCondition === HEART_CONDITIONS.HEART_ATTACK
            ? <HeartAttackMedicationOnlyDetails/>
            : <StableCoronaryHeartDiseaseMedicationOnlyDetails/>
        }
      </div>
    </div>
  </section>;

SummaryInitialOptions.propTypes = {
  heartCondition: PropTypes.string.isRequired
};
