/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import benefitsInfo from '../../../../assets/images/icons/benefits_info.svg';
import BenefitsEquationResult from '../../../components/common/benefitsEquationResult';
import {_PRAIP_SWHA} from '../../../equations/stableCornaryBranch/likelihoodOfSurvivingWithoutHeartAttack/PRAIP_SWHA';
import {_PRACM_SWHA} from '../../../equations/stableCornaryBranch/likelihoodOfSurvivingWithoutHeartAttack/PRACM_SWHA';
import {
  _PRAIP_IOCP_above_30,
  _PRAIP_IOCP_below_30
} from '../../../equations/stableCornaryBranch/likelyImpactOnChestPain/PRAIP_IOCP';
import {
  _PRACM_IOCP_above_30,
  _PRACM_IOCP_below_30
} from '../../../equations/stableCornaryBranch/likelyImpactOnChestPain/PRACM_IOCP';
import {useSelector} from 'react-redux';
import {GLOSSARY_KEYS, HEALTH_FACTORS} from '../../../containers/appConstants';
import {Tooltip} from '../../common/tooltip';
import benefitsSymbol from '../../../../assets/images/icons/benefits_symbol.svg';
import {Graphs} from '../../common/graphs';

const BenefitsSummary = () => {
  const healthFactors = useSelector(state => state.healthFactors);
  const [
    survivingWithoutHeartAttackInvasiveDetails,
    setSurvivingWithoutHeartAttackInvasiveDetails
  ] = useState({});
  const [
    survivingWithoutHeartAttackConservativeDetails,
    setSurvivingWithoutHeartAttackConservativeDetails
  ] = useState({});
  const [chestPainInvasiveDetails, setChestPainInvasiveDetails] = useState({});
  const [chestPainConservativeDetails, setChestPainConservativeDetails] = useState({});

  useEffect(() => {
    setSurvivingWithoutHeartAttackInvasiveDetails(_PRAIP_SWHA(healthFactors));
    setSurvivingWithoutHeartAttackConservativeDetails(_PRACM_SWHA(healthFactors));

    if (healthFactors.egfr > 30) {
      setChestPainInvasiveDetails(_PRAIP_IOCP_above_30(healthFactors));
      setChestPainConservativeDetails(_PRACM_IOCP_above_30(healthFactors));
    }

    if (healthFactors.egfr < 30) {
      setChestPainInvasiveDetails(_PRAIP_IOCP_below_30(healthFactors));
      setChestPainConservativeDetails(_PRACM_IOCP_below_30(healthFactors));
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const renderBelowThirty = chestPain => {
    if (chestPain === HEALTH_FACTORS.DAILY || chestPain === HEALTH_FACTORS.WEEKLY) {
      return <div className='grid-x margin-top-1 align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={benefitsInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an&nbsp;
            <Tooltip
              id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
              title='invasive heart procedure'/>&nbsp;may result in&nbsp;<span className='benefits-text'>10%
            less</span>&nbsp;chest pain at&nbsp;<span className='benefits-text'>3 months</span>&nbsp;than with&nbsp;
            <Tooltip
              id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
              title='optimal medication management'/>&nbsp;alone, but&nbsp;<span className='benefits-text'>no
            difference</span>&nbsp;in chest pain symptoms remain at&nbsp;<span className='benefits-text'>one
            year</span>&nbsp;– a short term benefit that wears off over time.
          </p>
        </div>
      </div>;
    }

    if (chestPain === HEALTH_FACTORS.MONTHLY || chestPain === HEALTH_FACTORS.NONE) {
      return <div className='grid-x margin-top-1 align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={benefitsInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an&nbsp;
            <Tooltip
              id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
              title='invasive heart procedure'/>&nbsp;results in&nbsp;<span className='benefits-text'>no
            difference </span>&nbsp;in chest pain symptoms at&nbsp;<span className='benefits-text'>3 months
            or one year</span>&nbsp;than with optimal medical therapy alone.
          </p>
        </div>
      </div>;
    }
  };

  return (
    <>
      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likelihood of survival without a heart attack in the next year</h2>
        <div className='grid-x medium-up-2 margin-vertical-1'>
          <div className='cell'>
            <BenefitsEquationResult
              className='border-right'
              resultDetails={survivingWithoutHeartAttackInvasiveDetails.probability}/>
          </div>
          <div className='cell'>
            <BenefitsEquationResult
              resultDetails={survivingWithoutHeartAttackConservativeDetails.probability}/>
          </div>
        </div>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={benefitsInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              There is&nbsp;<span className='benefits-text'>no difference</span>&nbsp;in the likelihood of
              mortality without
              a heart attack in the next year with&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                title='invasive heart procedure'/>&nbsp;or&nbsp;
              <Tooltip
                id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                title='optimal medication management'/>&nbsp;
            </p>
          </div>
        </div>
      </div>

      <div className='white-bg-color margin-2 padding-2 bordered all-rounded-borders'>
        <h2>Likely impact on chest pain (at three months and one year)</h2>
        {
          healthFactors.egfr > 30 &&
          <div>
            <div className='grid-x medium-up-2 margin-vertical-1'>
              <div className='cell'>
                <div className='card align-center border-right'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Invasive Heart Procedure plus<br/> Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {Number(chestPainInvasiveDetails).toFixed(0)}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({Number(chestPainInvasiveDetails).toFixed(0)}/100)
                  </p>
                </div>
              </div>
              <div className='cell'>
                <div className='card align-center'>
                  <h4 className='margin-bottom-1 height-50-px'>
                    Optimal Medication Management
                  </h4>
                  <div className='rounded percentage-circle bg-color-blue margin-vertical-1'>
                    <div className='rounded inside-circle'>
                      <img
                        alt='Icon image'
                        src={benefitsSymbol}/>
                      <p className='margin-0'>
                        {Number(chestPainConservativeDetails).toFixed(0)}%
                      </p>
                    </div>
                  </div>
                  <p>
                    ({Number(chestPainConservativeDetails).toFixed(0)}/100)
                  </p>
                </div>
              </div>
            </div>
            <div className='grid-x margin-top-1 align-center-middle'>
              <div className='cell shrink margin-0'>
                <img
                  alt='Icon image'
                  className='margin-right-1'
                  src={benefitsInfo}/>
              </div>
              <div className='cell auto margin-0 text-left'>
                <p className='margin-0'>
                  Treatment with an&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                    title='invasive heart procedure'/>&nbsp;resulted in an additional
                  &nbsp;<span className='benefits-text'>
                    {chestPainInvasiveDetails - chestPainConservativeDetails}
                  </span>&nbsp;
                  people being chest pain-free for every&nbsp;<span className='benefits-text'>100 patients </span>
                  like you compared to&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                    title='optimal medication management'/>.
                </p>
              </div>
            </div>
          </div>
        }
        {
          healthFactors.egfr <= 30 &&
          <div className='grid-x text-center hide-for-print'>
            <div className='auto cell'>
              <h4 className='height-50-px'>Invasive Heart Procedure <br/>
                plus Optimal Medication Management</h4>
              <div className='card padding-top-1'><Graphs data={chestPainInvasiveDetails}/></div>
            </div>
            <div className='auto cell'>
              <h4 className='height-50-px'>Optimal Medication Management</h4>
              <div className='card padding-top-1'><Graphs data={chestPainConservativeDetails}/></div>
            </div>
          </div>
        }
        {
          healthFactors.egfr <= 30 && renderBelowThirty(healthFactors.chestPainSymptoms)
        }
      </div>
    </>
  );
};

export default BenefitsSummary;
