export const SUBSEQUENT_CARE = {
  FIFTY_EIGHT: 58,
  TWENTY_EIGHT: 28,
  FIFTEEN: 15,
  FORTY_THREE: 43,
  SEVENTEEN: 17,
  SIXTEEN: 16,
  EIGHT: 8,
  THREE: 3,
  TWENTY: 20,
  FOUR: 4
};
