// _PRACM_ESKF stands for Predicted Risk Assuming Conservative Management - End Stage Kidney Failure
/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {_PRAIP_ESKF} from './PRAIP_ESKF';
import {FACTOR} from './eqConstants';

export const _PRACM_ESKF = patientHealthFactors => {
  const PRIData = _PRAIP_ESKF(patientHealthFactors);
  const predictedRiskConservative = PRIData.PRI * FACTOR.conservativeMgmt;

  const endStageKidneyFailure = {
    PRC: predictedRiskConservative,
    probability: predictedRiskConservative * 100
  };

  return endStageKidneyFailure;
};
