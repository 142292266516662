import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export const Tooltip = ({id, title}) => {
  const getAnchor = () =>
    <a
      href={`/glossary/${id}`}
      rel='noreferrer'
      target='_blank'
    >
        View Definition
    </a>;

  return (
    <>
      <span
        className='tooltip-content'
        data-for={id}
        data-tip={id}>
        {title}
      </span>
      <ReactTooltip
        className='react-tooltip-clickable-link'
        delayHide={1}
        effect='float'
        event='click'
        getContent={() => getAnchor()}
        globalEventOff='click'
        id={id}
        isCapture
        wrapper='span'
      />
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
