import React, {useState} from 'react';
import {connect, useSelector} from 'react-redux';
import ROUTES from '../../../routes';
import {Link, useHistory} from 'react-router-dom';
import APPROACH_LOGO from '../../../assets/images/approach.jpg';
import SPOR_LOGO from '../../../assets/images/SPOR logo.jpg';
import CAN_SOLVE_LOGO from '../../../assets/images/Can-SOLVE logo colour.png';
import CHIR_IHSPR_LOGO from '../../../assets/images/CIHR-IHSPR-E-4c-logo.png';
import DataResetModal from '../../components/common/dataResetModal';
import {Tooltip} from '../../components/common/tooltip';
import {GLOSSARY_KEYS} from '../appConstants';

const Index = () => {
  const history = useHistory();
  const {age} = useSelector(state => state.healthFactors);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleStart = () => {
    if (age === null) {
      history.push(ROUTES.INTRODUCTION);
    }
    else {
      setShowResetModal(true);
    }
  };

  return (
    <>
      <section className='gradient'>
        <div className='grid-container'>
          <div className='grid-x align-center'>
            <div className='cell large-9'>
              <h1>
                Decision Aid for Chronic Kidney Disease & Signs of Coronary Heart Disease
              </h1>
              <p className='margin-vertical-1'>
                A decision aid to support people with&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.CHRONIC_KIDNEY_DISEASE}
                  title='chronic kidney disease'/>&nbsp;discussing treatment
                options for coronary heart disease with their care team.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='shadow top-rounded-borders'>
        <div className='grid-container'>
          <div className='grid-x align-center'>
            <div className='cell large-9 lead text-center padding-2'>
              <h2>What should you keep in mind while using the decision aid?</h2>
            </div>
          </div>
          <div className='grid-x align-center-middle'>
            <div className='auto large-4 cell border-box'>
              <ol className='crimson-list'>
                <li className='border-right'>All patients are unique.</li>
                <li>There are no wrong decisions.</li>
              </ol>
            </div>
            <div className='auto large-4 cell'>
              <p>
                This decision aid aims to help patients and their care team discuss treatment options when determining
                how to best manage coronary heart disease alongside the presence of kidney disease.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='dark border-right'>
        <div className='grid-container'>
          <div className='grid-x align-center text-center'>
            <div className='cell large-9 text-left padding-1'>
              <div>
                <h2>Let’s start the decision aid to help patients answer the question:</h2>
              </div>
              <div className='quotes'>
                &quot;Should I have an an <span className='teal-color'>invasive heart procedure in addition to
            medication management</span>, or attempt to <span className='teal-color'>treat my coronary heart
            disease with medication only?</span>&quot;
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='grid-container padding-1'>
          <div className='text-center'>
            <p>
              By proceeding you agree to
              the&nbsp;<Link to={ROUTES.DISCLAIMER}>disclaimer</Link>
              &nbsp;and&nbsp;<Link to={ROUTES.PRIVACY_POLICY}>privacy policy</Link>.
            </p>
            <button
              className='button large rounded padding-horizontal-3'
              onClick={() => handleStart()}
              type='button'>
              Start
            </button>
            <p>
              &quot;This tool is optimized for use with Google Chrome and Safari. Some sections of the printable summary
              report may not align between pages when printed using other browsers (e.g., Firefox, Microsoft Edge,
              Internet Explorer, and Opera).&quot;
            </p>
          </div>
        </div>
      </section>
      <section className='white-bg-color'>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x align-spaced align-middle medium-up-6'>
            <div className='cell'>
              <img src={APPROACH_LOGO}/>
            </div>
            <div className='cell'>
              <img src={SPOR_LOGO}/>
            </div>
            <div className='cell'>
              <img src={CAN_SOLVE_LOGO}/>
            </div>
            <div className='cell'>
              <img src={CHIR_IHSPR_LOGO}/>
            </div>
          </div>
        </div>
      </section>
      <DataResetModal
        onClose={() => setShowResetModal(false)}
        show={showResetModal}
      />
    </>
  );
};

export default connect()(Index);
