import React, {useEffect} from 'react';
import ROUTES from '../../../routes';
import {useForm} from 'react-hook-form';
import {saveNextSteps, resetNextSteps} from '../../../redux/actions/nextSteps';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {NEXT_STEPS} from '../appConstants';

const Index = () => {
  const nextSteps = useSelector(state => state.nextSteps);
  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues: nextSteps
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  const onSubmit = data => {
    dispatch(saveNextSteps(data));
    history.push(ROUTES.SUMMARY_REPORT);
  };

  const handleReset = () => {
    dispatch(resetNextSteps());
  };

  return <>
    <section>
      <div className='grid-container'>
        <div className='grid-x'>
          <div className='cell'>
            <h1>
              How comfortable are you in your choice?
            </h1>
            <p>
              Making a decision like this is not easy, and your care team wants to make sure you are comfortable
              with the decision you make. The following questions will help you and your care team understand
              how comfortable you are with your choice.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className='top-rounded-borders'>
      <div className='grid-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p className='margin-0 explanation'>
              &apos;<span className='required-field-explanation'>*</span>&apos; indicates required field
            </p>
          </div>
          <div className='form-card'>
            <p className='lead required-field'>
              Do you feel you know the potential benefits and risks of each option?
            </p>
            <div className='grid-x small-up-2'>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='yes_for_potential_benefits_and_risks'
                    name={NEXT_STEPS.KNOW_POTENTIAL_BENEFITS_AND_RISKS}
                    type='radio'
                    value={NEXT_STEPS.YES}
                    {...register(NEXT_STEPS.KNOW_POTENTIAL_BENEFITS_AND_RISKS, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='yes_for_potential_benefits_and_risks'>
                    Yes
                  </label>
                </div>
              </div>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='no_for_potential_benefits_and_risks'
                    name={NEXT_STEPS.KNOW_POTENTIAL_BENEFITS_AND_RISKS}
                    type='radio'
                    value={NEXT_STEPS.NO}
                    {...register(NEXT_STEPS.KNOW_POTENTIAL_BENEFITS_AND_RISKS, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='no_for_potential_benefits_and_risks'>
                      No
                  </label>
                </div>
              </div>
            </div>
            {errors.KNOW_POTENTIAL_BENEFITS_AND_RISKS && <span className='error'>This field is required</span>}
          </div>
          <div className='form-card'>
            <p className='lead required-field'>
            Are you clear about which benefits and risks matter most to you?
            </p>
            <div className='grid-x small-up-2'>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='yes_for_benefits_and_risk_matters_most_to_you'
                    name={NEXT_STEPS.BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU}
                    type='radio'
                    value={NEXT_STEPS.YES}
                    {...register(NEXT_STEPS.BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='yes_for_benefits_and_risk_matters_most_to_you'>
                Yes
                  </label>
                </div>
              </div>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='no_for_benefits_and_risk_matters_most_to_you'
                    name={NEXT_STEPS.BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU}
                    type='radio'
                    value={NEXT_STEPS.NO}
                    {...register(NEXT_STEPS.BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='no_for_benefits_and_risk_matters_most_to_you'>
                No
                  </label>
                </div>
              </div>
            </div>
            {errors.BENEFITS_AND_RISKS_MATTERS_MOST_TO_YOU && <span className='error'>This field is required</span>}
          </div>
          <div className='form-card'>
            <p className='lead required-field'>
            Do you have enough support and advice to make a choice?
            </p>
            <div className='grid-x small-up-2'>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='yes_for_having_Support_and_care'
                    name={NEXT_STEPS.HAVE_ENOUGH_SUPPORT_AND_ADVICE}
                    type='radio'
                    value={NEXT_STEPS.YES}
                    {...register(NEXT_STEPS.HAVE_ENOUGH_SUPPORT_AND_ADVICE, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='yes_for_having_Support_and_care'>
                Yes
                  </label>
                </div>
              </div>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='no_for_having_Support_and_care'
                    name={NEXT_STEPS.HAVE_ENOUGH_SUPPORT_AND_ADVICE}
                    type='radio'
                    value={NEXT_STEPS.NO}
                    {...register(NEXT_STEPS.HAVE_ENOUGH_SUPPORT_AND_ADVICE, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='no_for_having_Support_and_care'>
                No
                  </label>
                </div>
              </div>
            </div>
            {errors.HAVE_ENOUGH_SUPPORT_AND_ADVICE && <span className='error'>This field is required</span>}
          </div>
          <div className='form-card'>
            <p className='lead required-field'>
              Do you feel sure about the best choice for you?
            </p>
            <div className='grid-x small-up-2'>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='yes_for_being_sure_about_choice_for_you'
                    name={NEXT_STEPS.SURE_ABOUT_BEST_CHOICE_FOR_YOU}
                    type='radio'
                    value={NEXT_STEPS.YES}
                    {...register(NEXT_STEPS.SURE_ABOUT_BEST_CHOICE_FOR_YOU, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='yes_for_being_sure_about_choice_for_you'>
                Yes
                  </label>
                </div>
              </div>
              <div className='cell'>
                <div className='flex-align'>
                  <input
                    id='no_for_being_sure_about_choice_for_you'
                    name={NEXT_STEPS.SURE_ABOUT_BEST_CHOICE_FOR_YOU}
                    type='radio'
                    value={NEXT_STEPS.NO}
                    {...register(NEXT_STEPS.SURE_ABOUT_BEST_CHOICE_FOR_YOU, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='no_for_being_sure_about_choice_for_you'>
                No
                  </label>
                </div>
              </div>
            </div>
            {errors.SURE_ABOUT_BEST_CHOICE_FOR_YOU && <span className='error'>This field is required</span>}
          </div>
          <div className='grid-x'>
            <div className='cell large-9'>
              <p className='font-italic'>
                  If you answered &lsquo;no&lsquo; to any of these questions, please discuss further with
                  your doctor.<br/>
                  This information is not intended to replace the advice of a health care provider.
              </p>
            </div>
          </div>
          <div className='grid-x small-up-4 align-spaced text-center'>
            <div className='cell'>
              <Link
                className='hollow secondary button large rounded padding-horizontal-2'
                to={ROUTES.CHOICES}>Back</Link>
            </div>
            <div className='cell'>
              <input
                className='button hollow alert large rounded padding-horizontal-2'
                onClick={() => handleReset()}
                type='reset'
                value='Reset Form'/>
            </div>
            <div className='cell'>
              <input
                className='button primary large rounded padding-horizontal-2 margin-right-2'
                type='submit'
                value='Next'/>
            </div>
          </div>
        </form>
      </div>
    </section>
  </>;
};

export default Index;
