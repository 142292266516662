import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import MyPriorities from '../../components/common/myPriorities';
import HeartIcon from '../../../assets/images/Heart.png';
import PillIcon from '../../../assets/images/Pill.png';
import {Tooltip} from '../../components/common/tooltip';
import {GLOSSARY_KEYS} from '../appConstants';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return (
    <>
      <section>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell'>
              <h1>Rank your values and preferences</h1>
              <p className='margin-vertical-1'>
                This section is designed for you, as a patient, to indicate what is the most important to you.
                Four values are listed below with a symbol showing the treatment option that favours each of them.
              </p>
              <p>
                Click or tap the below priorities to rank them from 1
                (most important) to 4 (least important) to rank your preferences.
              </p>
              <p>
                <img
                  className='margin-right-1'
                  src={HeartIcon}
                />
                indicates values that favour the&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                  title='invasive heart procedure'/>&nbsp;option, and
              </p>
              <p>
                <img
                  className='margin-right-1'
                  src={PillIcon}/>
                indicates values that favour the&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                  title='optimal medication management'/>&nbsp;option.
              </p>
            </div>
          </div>
        </div>
      </section>
      <MyPriorities/>
    </>
  );
};

export default Index;
