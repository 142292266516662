/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';

const PrintStepsModal = props => {
  if (!props.show) {
    return null;
  }

  const handleSave = () => {
    props.onClose();
    window.print();
  };

  return (
    <div className='modal hide-for-print' onClick={props.onClose}>
      <div className='padding-3 width-50 white-bg-color all-rounded-borders' onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <h2>Print or Save as PDF</h2>
        </div>
        <div className='modal-content'>
          <h3 className='info-color text-uppercase'>
            Here are instructions to save this summary report as pdf
          </h3>
          <ol>
            <li>
              <p>Click the &quot;Print or Save to PDF&quot; button.</p>
            </li>
            <li>
              <p>
                Once the print dialog box shows up, change the &quot;Destination&quot; to
                be &quot;Save as PDF&quot;
              </p>
            </li>
            <li>
              <p>Click &quot;Save&quot; or &quot;Save As&quot;</p>
            </li>
          </ol>
        </div>
        <div className='modal-footer margin-top-2'>
          <button
            className='button large hollow rounded padding-horizontal-2'
            onClick={props.onClose}
            type='button'>
            Close
          </button>
          <button
            className='button large rounded padding-horizontal-2'
            onClick={() => handleSave()}
            type='button'>
            Print or Save to PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintStepsModal;

PrintStepsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
