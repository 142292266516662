// _PRACM_AHT stands for Predicted Risk Assuming Conservative Management - Acute Kidney Injury Requiring Dialysis
/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {_PRAIP_AKIRD} from './PRAIP_AKIRD';
import {FACTOR} from './eqConstants';

export const _PRACM_AKIRD = patientHealthFactors => {
  const PRIData = _PRAIP_AKIRD(patientHealthFactors);
  const predictedRiskConservative = PRIData.PRI * FACTOR.conservativeMgmt;

  const acuteKidneyInjuryRequiringDialysis = {
    PRC: predictedRiskConservative,
    probability: predictedRiskConservative * 100
  };

  return acuteKidneyInjuryRequiringDialysis;
};
