/* eslint-disable camelcase,max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {HEALTH_FACTORS} from '../../../../containers/appConstants';
import benefitsInfo from '../../../../../assets/images/icons/benefits_info.svg';
import greyDot from '../../../../../assets/images/icons/grey_dot.svg';
import greenDot from '../../../../../assets/images/icons/green_dot.svg';
import blueDot from '../../../../../assets/images/icons/blue_dot.svg';
import {Graphs} from '../../../common/graphs';
import figureBlue from '../../../../../assets/images/icons/figure_blue.svg';
import figureGreen from '../../../../../assets/images/icons/figure_green.svg';
import {Figures} from '../../../common/figures';
import staticGraphs from '../../../../containers/Utils/StaticGraphs';

export const Details = ({invasiveData, conservativeData}) => {
  const healthFactors = useSelector(state => state.healthFactors);

  // eslint-disable-next-line consistent-return
  const renderBelowThirty = chestPain => {
    if (chestPain === HEALTH_FACTORS.DAILY || chestPain === HEALTH_FACTORS.WEEKLY) {
      return <div className='grid-x margin-top-1 align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={benefitsInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an invasive heart procedure may result in&nbsp;<span className='benefits-text'>10% less</span>&nbsp;
            chest pain at&nbsp;<span className='benefits-text'>3 months</span>&nbsp;than with optimal medication management
            alone, but&nbsp;<span className='benefits-text'>no difference</span>&nbsp;in chest pain symptoms remain
            at&nbsp;<span className='benefits-text'>one year</span>&nbsp;– a short term benefit that wears off over time.
          </p>
        </div>
      </div>;
    }

    if (chestPain === HEALTH_FACTORS.MONTHLY || chestPain === HEALTH_FACTORS.NONE) {
      return <div className='grid-x margin-top-1 align-center-middle'>
        <div className='cell shrink margin-0'>
          <img
            alt='Icon image'
            className='margin-right-1'
            src={benefitsInfo}/>
        </div>
        <div className='cell auto margin-0 text-left'>
          <p className='margin-0'>
            Treatment with an invasive heart procedure results in&nbsp;<span className='benefits-text'>no difference</span>&nbsp;
            in chest pain symptoms at&nbsp;<span className='benefits-text'>3 months or one year</span>&nbsp;than with
            optimal medical therapy alone.
          </p>
        </div>
      </div>;
    }
  };

  return (
    <div className='grid-x padding-horizontal-2 padding-bottom-2 print-paddings'>
      <div className='cell'>
        <div className='padding-bottom-1'>
          <h2>Impact on chest pain</h2>
          {healthFactors.egfr < 30 &&
            <div>
              <p>
                The graphs show a best estimate of the frequency of chest pain (ranging from none to daily
                for people like you before treatment (grey), in 3 months (green), and in 1 year (blue)
                with the  different treatment options). Each circle represents the amount of chest pain
                at each time point.
              </p>
              <div className='grid-x margin-bottom-2'>
                <div className='grid-x align-center-middle medium-10'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={greyDot}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Chest pain frequency before treatment
                    </p>
                  </div>
                </div>
                <div className='grid-x align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={greenDot}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Treatment with an invasive heart procedure resulted in 10% less chest pain at 3 months
                    </p>
                  </div>
                </div>
                <div className='grid-x align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={blueDot}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Treatment with an invasive heart procedure resulted in no difference in chest pain
                      symptoms at one year
                    </p>
                  </div>
                </div>
              </div>
              <div className='grid-x text-center hide-for-print'>
                <div className='auto cell'>
                  <h4 className='height-50-px'>Invasive Heart Procedure <br/>
                    plus Optimal Medication Management</h4>
                  <div className='card padding-top-1'><Graphs data={invasiveData}/></div>
                </div>
                <div className='auto cell'>
                  <h4 className='height-50-px'>Optimal Medication Management</h4>
                  <div className='card padding-top-1'><Graphs data={conservativeData}/></div>
                </div>
              </div>
              {/* Static graphs for printing */}
              <div className='show-for-print'>
                {staticGraphs(healthFactors.chestPainSymptoms)}
              </div>
              {renderBelowThirty(healthFactors.chestPainSymptoms)}
              {
                healthFactors.egfr > 30 &&
                <div className='grid-x margin-top-1 align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={benefitsInfo}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Treatment with an invasive heart procedure resulted in an additional
                      &nbsp;<span className='benefits-text'>{invasiveData - conservativeData}</span>&nbsp;
                      people being chest pain-free for every&nbsp;<span className='benefits-text'>100 patients</span>
                      &nbsp;like you compared to optimal medication management.
                    </p>
                  </div>
                </div>
              }
            </div>
          }
          {healthFactors.egfr >= 30 &&
            <div>
              <p>
                These blocks of 100 faces show a best estimate of what happens to 100 people like you after 3
                months who chose different treatment options. Each face stands for 1 person. The colored
                areas show the estimated number of people like you affected.
              </p>
              <div className='grid-x margin-bottom-2'>
                <div className='grid-x align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={figureBlue}/>
                  </div>
                  <div className='cell auto text-left'>
                    <p className='margin-0'>
                      Patients who were chest pain free in the next 3 months are highlighted in blue.
                    </p>
                  </div>
                </div>
                <div className='grid-x align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={figureGreen}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Additional patients who were chest pain free after invasive heart procedure are highlighted
                      in green.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className='grid-x text-center'>
                  <div className='auto cell'>
                    <h4 className='height-50-px'>Invasive Heart Procedure <br/>
                      plus Optimal Medication Management</h4>
                    <div className='card padding-top-1'>
                      <Figures
                        conservative={conservativeData}
                        invasive={invasiveData}
                      />
                    </div>
                  </div>
                  <div className='auto cell'>
                    <h4 className='height-50-px'>Optimal Medication Management</h4>
                    <div className='card padding-top-1'>
                      <Figures
                        conservative={conservativeData}
                        invasive={0}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x margin-top-1 align-center-middle'>
                  <div className='cell shrink margin-0'>
                    <img
                      alt='Icon image'
                      className='margin-right-1'
                      src={benefitsInfo}/>
                  </div>
                  <div className='cell auto margin-0 text-left'>
                    <p className='margin-0'>
                      Treatment with an invasive heart procedure resulted in an additional
                      &nbsp;<span className='benefits-text'>
                        {invasiveData - conservativeData}&nbsp;people being chest pain-free
                      </span>&nbsp;for every&nbsp;<span className='benefits-text'>100 patients</span>
                      &nbsp;like you compared to optimal medication management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  conservativeData: PropTypes.string.isRequired,
  invasiveData: PropTypes.string.isRequired
};
