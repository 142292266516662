import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import menu from '../../utils/menu';
import PropTypes from 'prop-types';
import Lock from '../../../assets/images/lock.png';
import DataResetModal from '../../components/common/dataResetModal';

const Menu = ({isShown, setShowMenu}) => {
  const menusObject = menu();
  const [showResetModal, setShowResetModal] = useState(false);
  const location = useLocation();

  const renderListItem = item => item.isLocked
    ? <li
      className='locked margin-horizontal-2 text-uppercase'
      key={item.title}>
      <a>
        {item.title}
      </a>
      <img
        className='text-right'
        src={Lock}/>
    </li>
    : <li
      className='margin-horizontal-2 text-uppercase'
      key={item.title}>
      <Link
        className={`${location.pathname === item.path ? 'success-color' : ''}`}
        to={item.path}
      >
        {item.title}
      </Link>
    </li>;

  return (
    <>
      <DataResetModal
        onClose={() => setShowResetModal(false)}
        show={showResetModal}
      />
      <div className={`off-canvas position-right align-stretch ${isShown ? 'is-open' : 'invisible'}`}>
        <button
          className='close-button rounded'
          onClick={() => setShowMenu(false)}
          type='button'>
          <span aria-hidden='true'>
          &times;
          </span>
        </button>

        <ul className='vertical menu display-inline-block margin-top-2'>
          {
            menusObject.menuSectionMain.map(menuItem => renderListItem(menuItem))
          }
          <p className='sub-section-title padding-top-1 margin-bottom-0 margin-horizontal-2'>
            STEP 1: Comparing Options
          </p>
          {
            menusObject.comparingOptions.map(menuItem => renderListItem(menuItem))
          }
          <p className='sub-section-title padding-top-1 margin-bottom-0 margin-horizontal-2'>
            STEP 2: My Priorities
          </p>
          {
            menusObject.myPriorities.map(menuItem => renderListItem(menuItem))
          }
          <p className='sub-section-title padding-top-1 margin-bottom-0 margin-horizontal-2'>
            Summary
          </p>
          {
            menusObject.mySummary.map(menuItem => renderListItem(menuItem))
          }
        </ul>

        <div className='margin-top-1'>
          <ul className='vertical menu display-inline-block'>
            {
              menusObject.additionalInfo.map(menuItem => renderListItem(menuItem))
            }
            <li
              className='margin-horizontal-2 text-uppercase'
              key='reset_store'>
              <button
                className='button rounded hollow alert margin-1 padding-1 text-uppercase'
                onClick={() => {
                  setShowResetModal(true);
                  setShowMenu(false);
                }}
                type='button'>
                Reset Data
              </button>
            </li>;
          </ul>
        </div>
      </div>
    </>
  );
};

export default Menu;

Menu.propTypes = {
  isShown: PropTypes.bool.isRequired,
  setShowMenu: PropTypes.func.isRequired
};
