import React from 'react';
import PropTypes from 'prop-types';
import {RiskFigures} from '../../../common/risksFigures';
import risksInfo from '../../../../../assets/images/icons/risks_info.svg';
import figureYellow from '../../../../../assets/images/icons/figure_yellow.svg';
import figureRed from '../../../../../assets/images/icons/figure_red.svg';

export const Details = ({invasiveProb, conservativeProb}) =>
  <div className='grid-x padding-horizontal-2 padding-bottom-2 print-paddings'>
    <div className='cell'>
      <div className='padding-bottom-1'>
        <h2>
          Outcome: Likelihood of having an acute kidney injury requiring dialysis treatment during
          this hospitalization
        </h2>
        <p>
          These blocks of 100 faces show a best estimate of what happens to 100 people like you during this
          hospitalization who chose different treatment options. Each face stands for 1 person.
          The colored areas show the estimated number of people like you affected.
        </p>
      </div>
      <div className='grid-x margin-bottom-3'>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={figureYellow}/>
          </div>
          <div className='cell auto margin-0'>
            <p className='margin-0'>
              Patients who are likely to have an acute kidney injury requiring dialysis during this
              hospitalization are shown in yellow.
            </p>
          </div>
        </div>
        <div className='grid-x  align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={figureRed}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Additional patients who were harmed by an invasive heart procedure are highlighted in red.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='grid-x text-center page-break-before'>
          <div className='auto cell'>
            <h4 className='height-50-px'>Invasive Heart Procedure <br/>
              plus Optimal Medication Management</h4>
            <div className='card padding-top-1'>
              <RiskFigures
                conservativeRisk={conservativeProb}
                invasiveRisk={invasiveProb}
              />
            </div>
          </div>
          <div className='auto cell'>
            <h4 className='height-50-px'>Optimal Medication Management</h4>
            <div className='card padding-top-1'>
              <RiskFigures
                conservativeRisk={conservativeProb}
                invasiveRisk={0}
              />
            </div>
          </div>
        </div>
        <div className='grid-x margin-top-1 align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={risksInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            {
              invasiveProb - conservativeProb === 0
                ? <p className='margin-0'>
                  Treatment with an invasive heart procedure caused&nbsp;<span className='risks-text'>no additional
                  acute kidney injuries requiring dialysis for every 100 patients</span>&nbsp;like you.
                </p>
                : <p className='margin-0'>
                  Treatment with an invasive heart procedure caused&nbsp;<span className='risks-text'>
                    {invasiveProb - conservativeProb} additional
                  acute kidney injuries requiring dialysis for every 100 patients</span>&nbsp;like you compared
                  to optimal medication management.
                </p>
            }
          </div>
        </div>
      </div>
    </div>
  </div>;

Details.propTypes = {
  conservativeProb: PropTypes.string.isRequired,
  invasiveProb: PropTypes.string.isRequired
};
