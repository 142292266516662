import {HEALTH_FACTORS} from '../constants';

export const saveHealthFactors = data => ({
  type: HEALTH_FACTORS.SAVE_HEALTH_FACTORS,
  data
});

export const resetHealthFactors = () => ({
  type: HEALTH_FACTORS.RESET_HEALTH_FACTORS
});
