import React, {useEffect} from 'react';
import ROUTES from '../../../routes';
import {useSelector} from 'react-redux';
import HeartAttackMyRiskFactors from '../../components/HeartAttack/myRiskFactors';
import StableCoronaryHeartDiseaseMyRiskFactors from '../../components/StableCoronaryHeartDisease/myRiskFactors';
import {HEART_CONDITIONS} from '../appConstants';
import BackNextButtons from '../../components/common/backNextButtons';
import {useHistory, useLocation} from 'react-router-dom';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const heartCondition = useSelector(state => state.heartCondition);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return <>
    {
      heartCondition === HEART_CONDITIONS.HEART_ATTACK
        ? <HeartAttackMyRiskFactors/>
        : <StableCoronaryHeartDiseaseMyRiskFactors/>
    }
    <BackNextButtons
      backButtonRoute={ROUTES.INITIAL_OPTIONS}
      nextButtonRoute={ROUTES.WEIGHING_INFORMATION}/>
  </>;
};

export default Index;
