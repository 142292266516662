import React, {useEffect} from 'react';
import parse from 'html-react-parser';
import {useParams} from 'react-router-dom';
import {sortBy} from 'lodash';
import {GlossaryTerms} from '../../components/glossaryTerms';

const Index = () => {
  const {id} = useParams();

  useEffect(() => {
    if (id !== ':id') {
      document.getElementById(id).scrollIntoView();
    }
  }, []);

  return <>
    <section>
      <div className='grid-container'>
        <div className='grid-x'>
          <div className='cell'>
            <h1>
              Here is an alphabetical list of relevant terms and definitions
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section className='shadow all-rounded-borders white-bg-color margin-bottom-2'>
      <div className='grid-container'>
        {sortBy(GlossaryTerms, e => e.title).map(ele =>
          <div
            className='grid-x text-left margin-vertical-1'
            id={ele.id}
            key={ele.id}>
            <div className='cell bordered all-rounded-borders'>
              <details open={ele.id === id}>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>
                    {ele.title}
                  </h3>
                </summary>
                <div className='grid-container padding-horizontal-2'>
                  <div className='grid-x small-up-2 align-stretch'>
                    <p>
                      {parse(ele.description)}
                    </p>
                  </div>
                </div>
              </details>
            </div>
          </div>)}
      </div>
    </section>
  </>;
};

export default Index;
