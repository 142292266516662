import React from 'react';

export const AdditionalInfo = () =>
  <p>
    Individualized estimates of the risk of acute kidney injury requiring dialysis were determined using multivariable
    prediction models developed from a population-based cohort of 11,980 adult patients with chronic kidney
    disease hospitalized with a heart attack in Alberta, Canada between 2004-2017. Treatment effects from
    invasive heart procedures were then applied to these risks for each participant to obtain personalized
    estimates of absolute risk for an individual under each of the two treatment options. Results from
    observational studies were used to determine the effect of the treatment option on the outcome of acute
    kidney injury requiring dialysis. The overall rating of the strength of evidence for this outcome is
    “weak” (meaning the true effect might be markedly different from the estimated effect). The certainty of
    this evidence was affected by imprecision (there is a wide range of plausible estimates around the best
    estimates of the effects of treatment) and risk of bias (estimates for acute kidney injury requiring
    dialysis were from observational studies).
  </p>;
