import React from 'react';
import ROUTES from '../../../routes';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setHeartCondition} from '../../../redux/actions/heartCondition';
import {GLOSSARY_KEYS, HEART_CONDITIONS} from '../appConstants';
import {unlockNavItem} from '../../../redux/actions/unlockedNavItems';
import {Tooltip} from '../../components/common/tooltip';

const Index = () => {
  const dispatch = useDispatch();

  return <>
    <section>
      <div className='grid-container'>
        <div className='grid-x'>
          <div className='cell large-9'>
            <h1>
              Your Heart Condition:
            </h1>
            <p>
              From the following two options below, please select the heart condition that best
              describes what you are experiencing.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className='top-rounded-borders full-viewport-height'>
      <div className='grid-container'>
        <div className='grid-x align-center'>
          <div className='auto cell border-right'>
            <div className='card heart-conditions-card margin-2'>
              <div className='card-section'>
                <h3 className='primary-color text-uppercase'>Heart Attack</h3>
                <p>(non-ST elevation myocardial infarction, or unstable&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.ANGINA}
                    title='angina'/>&nbsp;)</p>
              </div>
              <div className='card-divider'>
                <Link
                  className='primary button large rounded padding-horizontal-2'
                  onClick={() => {
                    dispatch(setHeartCondition(HEART_CONDITIONS.HEART_ATTACK));
                    dispatch(unlockNavItem([ROUTES.HEALTH_FACTORS]));
                  }}
                  to={ROUTES.HEALTH_FACTORS}>Proceed</Link>
              </div>
            </div>
          </div>
          <div className='auto cell'>
            <div className='card heart-conditions-card margin-2'>
              <div className='card-section'>
                <h3 className='primary-color text-uppercase'>Stable Coronary Heart Disease</h3>
                <p>(stable&nbsp;
                  <Tooltip
                    id={GLOSSARY_KEYS.ANGINA}
                    title='angina'/>&nbsp;)</p>
              </div>
              <div className='card-divider'>
                <Link
                  className='primary button large rounded padding-horizontal-2'
                  onClick={() => {
                    dispatch(setHeartCondition(HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE));
                    dispatch(unlockNavItem([ROUTES.HEALTH_FACTORS]));
                  }}
                  to={ROUTES.HEALTH_FACTORS}>Proceed</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default Index;
