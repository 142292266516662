import React from 'react';
import subsequentCareSymbol from '../../../assets/images/icons/subsequent_care_symbol.svg';
import PropTypes from 'prop-types';
import {getFormattedResult} from '../../utils/utils';

const SubsequentEquationResult = ({resultDetails, className}) =>
  <div className={`card align-center ${className}`}>
    <div className='rounded percentage-circle bg-color-purple margin-vertical-1'>
      <div className='rounded inside-circle'>
        <img
          alt='Icon image'
          src={subsequentCareSymbol}/>
        <p className='margin-0'>{resultDetails}%</p>
      </div>
    </div>
    <p>
      ({getFormattedResult(Number(resultDetails))}/100)
    </p>
  </div>;

export default SubsequentEquationResult;

SubsequentEquationResult.propTypes = {
  className: PropTypes.string,
  resultDetails: PropTypes.number.isRequired
};
