import {GLOSSARY_KEYS} from '../containers/appConstants';

export const GlossaryTerms = [
  {
    id: GLOSSARY_KEYS.ANGIOGRAM,
    title: 'Angiogram',
    description: 'An angiogram is a special X-ray of the arteries that supply the muscle of the heart. The purpose' +
      ' of the angiogram is to show the exact location of arteries that have become narrowed. The angiogram also' +
      ' allows doctors to determine the severity of the blockage. These X-rays allow the doctor to build a moving' +
      ' picture of the arteries filling with blood. This moving picture is called the angiogram. <br><br> Dye is' +
      ' injected' +
      ' into these arteries, which then becomes visible on the X-ray. To do this, a doctor will insert a small' +
      ' flexible tube (catheter) into a blood vessel in the groin or wrist. Then, the doctor will gently push this' +
      ' catheter along the blood vessel towards the heart, until it reaches the heart arteries. Once the catheter' +
      ' reaches the heart, the dye will be injected into the arteries through the tube. Finally, several rapid' +
      ' X-rays are taken. <br><br> Depending on what the angiogram shows, it may lead to further heart procedures.' +
      ' For example, in an angioplasty, stents are used to open up a blocked vessel and restore blood flow. The' +
      ' doctors may recommend heart surgery to bypass the blockage in some cases.'
  },
  {
    id: GLOSSARY_KEYS.ANGIOPLASTY,
    title: 'Angioplasty',
    description: ' Angioplasty is a procedure used to open blocked blood vessels of the heart. Stents are often then' +
      ' inserted in the blood vessel to keep it open. This restores blood flow to the heart muscle without open-heart' +
      ' surgery. It can be done at the same time as an angiogram or later.'
  },
  {
    id: GLOSSARY_KEYS.CHRONIC_KIDNEY_DISEASE,
    title: 'Chronic Kidney Disease',
    description: 'Abnormalities of kidney function or structure that have been present for 3 months or longer.' +
      ' This includes the stages of kidney disease that precede kidney failure when dialysis or kidney transplant' +
      ' are used.'
  },
  {
    id: GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT,
    title: 'Optimal Medication Management',
    description: 'The alternative to invasive management is to treat with medications that slow the heart rate,' +
      ' lower blood pressure, prevent blood clotting, dilate the blood vessels, and lower cholesterol. These' +
      ' medications can reduce symptoms, and improve outcomes and so are standard treatments for all patients with' +
      ' stable coronary heart disease. This is typically referred to as optimal medication management.'
  },
  {
    id: GLOSSARY_KEYS.DIALYSIS,
    title: 'Dialysis',
    description: 'A treatment for people with kidney failure. When kidneys fail, they do not filter blood adequately,' +
      ' and wastes and toxins build up in the body. Dialysis does some of the functions that kidneys would usually' +
      ' perform, removing waste products and excess fluid from the blood. It can be done in different ways, including' +
      ' hemodialysis (removing blood from the body, passing it through a filter to remove wastes, and returning it to' +
      ' the body) and peritoneal dialysis (adding fluid into the abdomen then draining it out to remove the wastes' +
      ' within in). <br><br>' +
      ' Dialysis can have an impact on your lifestyle due to the time required to complete treatments and there' +
      ' can be related symptoms.'
  },
  {
    id: GLOSSARY_KEYS.HEART_ATTACK,
    title: 'Heart Attack',
    description: 'A life threatening condition where the heart muscle is damaged because of a sudden blockage in the' +
      ' blood vessel that supplies the heart muscle (known as a coronary artery). The heart muscle is damaged because' +
      ' it is not getting enough blood flow. The medical term for a heart attack is “myocardial infarction”.'
  },
  {
    id: GLOSSARY_KEYS.MEDICATIONS,
    title: 'Medication',
    description: 'A drug used to prevent or treat a disease.'
  },
  {
    id: GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE,
    title: 'Invasive Heart Procedure',
    description: ' Invasive procedures involve deliberate access into the body through an incision (cut), puncture' +
      ' (pierce) through the skin, often into a blood vessel.  Invasive heart procedures include an angiogram,' +
      ' angioplasty, or heart surgery.'
  },
  {
    id: GLOSSARY_KEYS.ANGINA,
    title: 'Angina',
    description: 'A type of chest pain caused by too little blood flow to the heart. It is a symptom of coronary' +
      ' heart disease and often described as pressure, heaviness, or tightness in the chest, although not all people' +
      ' experience these symptoms. Some patients with chronic kidney disease experience no chest pain and some' +
      ' experience different symptoms instead, particularly women.'
  },
  {
    id: GLOSSARY_KEYS.END_STAGE_KIDNEY_FAILURE,
    title: 'End-Stage Kidney Failure',
    description: 'The most severe stage of kidney disease when the kidneys no longer work well enough to meet' +
      ' the body\'s needs. Kidneys filter wastes and excess fluids from the body, which are excreted in the urine.' +
      ' When the kidneys fail, dangerous levels of fluid, electrolytes and wastes can build up in the body.' +
      ' With end-stage kidney failure, you need dialysis or a kidney transplant to stay alive. However, you can also' +
      ' choose to opt for conservative care to manage your symptoms — aiming for the best quality of life during your' +
      ' remaining time.'
  },
  {
    id: GLOSSARY_KEYS.EGFR,
    title: 'EGFR',
    description: 'Estimated Glomerular Filtration Rate. The result of a blood test that provides a measure of how' +
      ' well the kidneys are working. eGFR greater than 60 mL/min/1.73m<sup>2</sup> is considered normal and values' +
      ' less than' +
      ' 60 mL/min/1.73m<sup>2</sup> level represent chronic kidney disease (lower values represent less kidney' +
      ' function). Kidney failure is present when eGFR is < 15 mL/min/1.73m<sup>2</sup>.'
  },
  {
    id: GLOSSARY_KEYS.ALBUMINURIA,
    title: 'Albuminuria',
    description: 'The amount of a protein (called albumin) detected in the urine. Higher values indicate more' +
      ' damage present in the kidneys.'
  },
  {
    id: GLOSSARY_KEYS.CORONARY_ARTERY_BYPASS_SURGERY,
    title: 'Coronary artery bypass (Heart) surgery',
    description: 'A surgical procedure that restores blood flow to your heart muscle by diverting the flow of blood' +
      ' around a section of a blocked blood vessel in your heart. Blood vessels, or grafts, used for the bypass may' +
      ' be pieces of a vein from your leg, an artery from your wrist, or an artery within your chest. Your doctor' +
      ' attaches one end of the graft above the blockage and the other end below the blockage.'
  },
  {
    id: GLOSSARY_KEYS.NON_INVASIVE_TESTS,
    title: 'Non-invasive tests',
    description: 'Tests that are done without breaking the skin or physically entering the body. Examples include' +
      ' standard x-rays, CT scans, MRI scans, ECGs, and Holter monitoring (monitoring electric tracing of the' +
      ' heart from outside the body).'
  },
  {
    id: GLOSSARY_KEYS.ACUTE_KIDNEY_INJURY,
    title: 'Acute kidney injury',
    description: 'A sudden episode of kidney failure or kidney damage that happens within a few hours or a few days.' +
      ' It can occur after invasive heart procedures due to the dye involved, or damage from the procedure itself.' +
      ' When severe it may cause kidney failure requiring treatment with dialysis. This is most often temporary and' +
      ' kidney function can improve over several days to weeks. For some patients with chronic kidney disease,' +
      ' improvement may not be complete, resulting in development of permanent (end-stage) kidney failure sooner' +
      ' than it otherwise would have occurred.'
  },
  {
    id: GLOSSARY_KEYS.MULTIVARIABLE_PREDICTION_MODEL,
    title: 'Multivariable prediction model',
    description: 'A calculation that estimates an individual\'s probability or risk that a specific event will' +
      ' occur in the future based on multiple characteristics or pieces of information for that person. These' +
      ' calculations can be used to provide personalized estimates of the risk of an outcome in the future, rather' +
      ' than just an estimate of the average risk of all people in a population.'
  },
  {
    id: GLOSSARY_KEYS.RANDOMIZED_TRIALS,
    title: 'Randomized trials',
    description: 'A research study that enables balance of factors that can influence outcomes between two or more' +
      ' groups receiving different treatments. Randomized controlled trials provide the strongest (least biased)' +
      ' evidence of the true effect of an intervention.'
  },
  {
    id: GLOSSARY_KEYS.RISK_FACTOR,
    title: 'Risk factor',
    description: 'Something that increases a person\'s chances of developing a disease or specific health' +
      ' outcome in the future.'
  }
];
