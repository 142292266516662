import React from 'react';
import PropTypes from 'prop-types';
import {HEART_CONDITIONS} from '../appConstants';
import HeartAttackMyRiskFactors from '../../components/HeartAttack/myRiskFactors';
import StableCoronaryHeartDiseaseMyRiskFactors from '../../components/StableCoronaryHeartDisease/myRiskFactors';
import WeighingInfo from '../../components/common/weighingInfo';

export const SummaryHealthFactors = heartCondition =>
  <>
    <section className='white-bg-color margin-bottom-2'>
      <div className='grid-container'>
        {
          heartCondition === HEART_CONDITIONS.HEART_ATTACK
            ? <HeartAttackMyRiskFactors/>
            : <StableCoronaryHeartDiseaseMyRiskFactors/>
        }
        <div>
          <h1>Helping you weigh the information</h1>
          <p>
        In the following screens you will see three main categories of information: potential benefits,
        potential harms, and what treatments might happen next for each option.
          </p>
          <p>
        Weighing the information from each of these three categories can help you make an informed
        decision about which treatment option is best for you.
          </p>
        </div>
        <WeighingInfo/>
      </div>
    </section>
  </>;

SummaryHealthFactors.propTypes = {
  heartCondition: PropTypes.string.isRequired
};
