import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          James M., Tonelli M, Ghali W, Knudtson M, Faris P, Manns B. et al. Renal outcomes associated with
          invasive versus conservative management of acute coronary syndrome: propensity matched cohort study.
          BMJ 2013, 347(jul05 1), f4151-f4151. doi: 10.1136/bmj.f4151
        </p>
      </li>
      <li>
        <p>
          Ma B, Allen DW, Graham MM, Har BJ, Tyrrell B, Tan Z, Spertus JA, Brown JR, Matheny ME, Hemmelgarn BR,
          Pannu N, James MT. Comparative Performance of Prediction Models for Contrast-Associated Acute Kidney
          Injury After Percutaneous Coronary Intervention. Circ Cardiovasc Qual Outcomes. 2019 Nov;12(11):e005854.
        </p>
      </li>
    </ol>
  </div>;
