import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          James M., Tonelli M, Ghali W, Knudtson M, Faris P, Manns B. et al. Renal outcomes associated with
          invasive versus conservative management of acute coronary syndrome: propensity matched cohort study.
          BMJ 2013, 347(jul05 1), f4151-f4151. doi: 10.1136/bmj.f4151
        </p>
      </li>
      <li>
        <p>
          Bangalore S, Maron DJ, O&apos;Brien SM, Fleg JL, Kretov EI, Briguori C, Kaul U, Reynolds HR, Mazurek T,
          Sidhu MS, Berger JS, Mathew RO, Bockeria O, Broderick S, Pracon R, Herzog CA, Huang Z, Stone GW,
          Boden WE, Newman JD, Ali ZA, Mark DB, Spertus JA, Alexander KP, Chaitman BR, Chertow GM, Hochman JS;
          ISCHEMIA-CKD Research Group. Management of Coronary Disease in Patients with Advanced Kidney Disease.
          N Engl J Med. 2020 Apr 23;382(17):1608-1618.
        </p>
      </li>
    </ol>
  </div>;
