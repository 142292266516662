import React from 'react';
import {CHOICES} from '../appConstants';
import {useSelector} from 'react-redux';

export const SummaryChoices = () => {
  const choices = useSelector(state => state.choices);
  const allChoices = [
    CHOICES.INVASIVE_HEART_PROCEDURE,
    CHOICES.OPTIMAL_MEDICATION_MANAGEMENT,
    CHOICES.DISCUSS_WITH_DOCTOR,
    CHOICES.DISCUSS_WITH_FAMILY,
    CHOICES.NEED_MORE_INFO
  ];

  return <section className='shadow all-rounded-borders white-bg-color margin-bottom-2'>
    <div className='grid-container'>
      <div className='cell large-9'>
        <h1>Initial Choices</h1>
        <p>
          What option are you leaning towards?  Check off one (or more) of the following five items. This will
          help you and your care team understand what you might still need to help make your care decision.
        </p>
        <div className='cell large-9 margin-vertical-2'>
          <table className='unstriped width-100 table-print-styles'>
            <tbody>
              <tr>
                <td className='padding-1 bordered font-bold'>
                Choices
                </td>
                <td className='padding-1 bordered width-10 text-center font-bold'>
                Checkmark
                </td>
              </tr>
              {
                allChoices.map(choice => <tr key={choice}>
                  <td className='padding-1 bordered'>
                    {choice}
                  </td>
                  <td className='padding-1 bordered width-10 text-center'>
                    {choices && choices.includes(choice) ? '✓' : ''}
                  </td>
                </tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>;
};
