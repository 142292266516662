import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const BackNextButtons = ({backButtonRoute, nextButtonRoute}) =>
  <div>
    <section>
      <div className='grid-container'>
        <div className='grid-x small-up-2 align-center text-center'>
          <div className='cell'>
            <Link
              className='hollow secondary button large rounded padding-horizontal-2'
              to={backButtonRoute}>Back</Link>
          </div>
          <div className='cell'>
            <Link
              className='primary button large rounded padding-horizontal-2'
              to={nextButtonRoute}>Next</Link>
          </div>
        </div>
      </div>
    </section>
  </div>;

export default BackNextButtons;

BackNextButtons.propTypes = {
  backButtonRoute: PropTypes.string.isRequired,
  nextButtonRoute: PropTypes.string.isRequired
};
