import React from 'react';
import {useHistory} from 'react-router-dom';

const Index = () => {
  const history = useHistory();

  return (
    <section>
      <div className='grid-container'>
        <h2 className='font-bold'>Disclaimer</h2>
        <p>
          The information, content, and material presented in this decision aid is not intended to replace advice or
          prescriptions from a health care provider, but rather to improve the communication between the latter
          and the patient and to help patients and health care providers arrive at shared decisions based on
          evidence and values. Certain situations can necessitate urgent decisions to use one treatment option
          over another.
        </p>
        <p>
          The University of Calgary, its administrators, employees, agents, faculty, and students shall not be liable
          for any damages, claims, liabilities, costs or obligations arising from the use or misuse of the material
          contained in this web site, whether such obligations arise in contract, negligence, equity or statute law.
          The University of Calgary does not guarantee or warrant the quality, accuracy, completeness, timeliness,
          appropriateness or suitability of the information provided.
        </p>

        <p>The Shared Decision-Making authors/developer:</p>
        <ul>
          <li>Dr. Stephen Wilton , MD, MSC, FRCPC</li>
          <li>Dr. Michelle Graham, MD, FRCPC</li>
          <li>Dr. Matthew James , MD, PhD, FRCPC</li>
          <li>Krystina Lewis, RN, MN, PhD, CCN(C)</li>
          <li>Todd Wilson, PhD</li>
          <li>Julie Babione , BSc , MSc</li>
          <li>Developed by <a
            href='https://tactica.ca/'
            rel='noreferrer'
            target='_blank'>Tactica Interactive</a>
          </li>
        </ul>
        <div className='text-center margin-3'>
          <button
            className='hollow secondary button large rounded padding-horizontal-3'
            onClick={() => history.goBack()}
            type='button'>
            Back
          </button>
        </div>
      </div>
    </section>
  );
};

export default Index;
