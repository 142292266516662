import React from 'react';
import CatheterInsertionImage from '../../../assets/images/Catheter insertion drawing_v01_FINAL-01.png';
import {Tooltip} from '../common/tooltip';
import {GLOSSARY_KEYS} from '../../containers/appConstants';

const EarlyInvasiveDetails = () =>
  <>
    <div className='grid-container padding-horizontal-2'>
      <div className='grid-x small-up-2 align-stretch'>
        <div className='cell'>
          <h2>Invasive Heart Procedures</h2>
        </div>
      </div>
      <p>
        An&nbsp;
        <Tooltip
          id={GLOSSARY_KEYS.ANGIOGRAM}
          title='angiogram'/>&nbsp;
        is a special X-ray of the arteries that supply the muscle of the heart. These X-rays allow
        the doctor to build a moving picture of the arteries filling with blood. This moving picture is called the
        angiogram. The purpose of the angiogram is to show the exact location of arteries that have become
        narrowed. The angiogram also allows doctors to determine the severity of the blockage.
      </p>
      <p>
        Dye is injected into these arteries, which then becomes visible on the X-ray. To do this, a doctor will
        insert a small flexible tube (catheter) into a blood vessel in the groin or wrist. Then, the doctor will
        gently push this catheter along the blood vessel towards the heart, until it reaches the heart arteries.
        Once the catheter reaches the heart, the dye will be injected into the arteries through the tube.
        Finally, several rapid X-rays are taken.
      </p>
      <p>
        Depending on what the moving picture shows, an angiogram may lead to further heart procedures.
        For example, in an&nbsp;
        <Tooltip
          id={GLOSSARY_KEYS.ANGIOPLASTY}
          title='angioplasty'/>&nbsp;, stents are used to open up a blocked vessel and restore blood flow.
        The doctor may even recommend open heart surgery to bypass the blockage in some cases.
      </p>
    </div>
    <div className='padding-horizontal-2'>
      <p>
        The following illustration shows an overview of an angiogram:
      </p>
      <div className='text-center'>
        <img
          alt='angiogram'
          className='img-print-styles avoid-page-break-inside'
          src={CatheterInsertionImage}
          width={700}/>
      </div>
    </div>
  </>;

export default EarlyInvasiveDetails;
