import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {REDUX_STORE} from '../constants';
import heartCondition from './../reducers/heartCondition';
import healthFactors from './healthFactors';
import unlockedNavItems from './unlockedNavItems';
import initialOptions from './initialOptions';
import myPriorities from './myPriorities';
import choices from './choices';
import nextSteps from './nextSteps';

const createRootReducer = (history: History) => {
  const rootReducerFn = combineReducers({
    unlockedNavItems,
    heartCondition,
    healthFactors,
    initialOptions,
    myPriorities,
    choices,
    nextSteps,
    router: connectRouter(history)
  });

  return (state: Parameters<typeof rootReducerFn>[0], action: Parameters<typeof rootReducerFn>[1]) =>
    // eslint-disable-next-line no-undefined
    rootReducerFn(action.type === REDUX_STORE.RESET_REDUX_STORE ? undefined : state, action);
};

export default createRootReducer;
