// _PRACM_IOCP stands for Predicted Risk Assuming Conservative Management - Impact On Chest Pain
/* eslint-disable camelcase */

import {HEALTH_FACTORS} from '../../../containers/appConstants';

export const _PRACM_IOCP_above_30 = patientHealthFactors => {
  const eGFR = patientHealthFactors.egfr;
  const chestPain = patientHealthFactors.chestPainSymptoms;
  let OMM = 0;

  if (eGFR >= 30) {
    if (chestPain === HEALTH_FACTORS.DAILY) {
      OMM = 6;
    }
    else if (chestPain === HEALTH_FACTORS.WEEKLY) {
      OMM = 15;
    }
    else if (chestPain === HEALTH_FACTORS.MONTHLY) {
      OMM = 52;
    }
    else if (chestPain === HEALTH_FACTORS.NONE) {
      OMM = 89;
    }
  }

  return OMM;
};

export const _PRACM_IOCP_below_30 = patientHealthFactors => {
  const eGFR = patientHealthFactors.egfr;
  const chestPain = patientHealthFactors.chestPainSymptoms;
  let OMM = {};

  if (eGFR < 30) {
    if (chestPain === HEALTH_FACTORS.DAILY) {
      OMM = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 85
        },
        {
          dataLabel: '3 months',
          dataValue: 77
        },
        {
          dataLabel: '1 year',
          dataValue: 77
        }
      ];
    }
    else if (chestPain === HEALTH_FACTORS.WEEKLY) {
      OMM = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 55
        },
        {
          dataLabel: '3 months',
          dataValue: 47
        },
        {
          dataLabel: '1 year',
          dataValue: 47
        }
      ];
    }
    else if (chestPain === HEALTH_FACTORS.MONTHLY) {
      OMM = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 20
        },
        {
          dataLabel: '3 months',
          dataValue: 12
        },
        {
          dataLabel: '1 year',
          dataValue: 12
        }
      ];
    }
    else if (chestPain === HEALTH_FACTORS.NONE) {
      OMM = [
        {
          dataLabel: 'Before Treatment',
          dataValue: 0
        },
        {
          dataLabel: '3 months',
          dataValue: 0
        },
        {
          dataLabel: '1 year',
          dataValue: 0
        }
      ];
    }
  }

  return OMM;
};
