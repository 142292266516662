export const COEFFICIENTS = {
  age: 0.0410,
  sex: 0.3662,
  eGFR: -0.0207,
  eGFRc2: 0.0165,
  hemo: -0.0230,
  albuminuriaMild: 0.2149,
  albuminuriaHEavy: 0.0938,
  charlsonCHF: 0.6630,
  charlsonCOPD: 0.3162,
  liverDis: 0.6160,
  charlsonPVD: 0.2693,
  diabetes: 0.0496,
  priorInv: -0.1351
};

export const BASELINES = {
  age: 73.32,
  eGFR: 53.87,
  hemo: 131.68
};

export const FACTOR = {
  conservativeMgmt: 1 / 1.0
};
