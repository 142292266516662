import React from 'react';
import TriangleIcon from '../../../assets/images/Triangle.png';
import Square from '../../../assets/images/Rectangle.png';
import Circle from '../../../assets/images/Ellipse.png';
import WeighInformation from '../../../assets/images/weigh-information.png';

const WeighingInfo = () =>
  <>
    <div className='margin-auto width-75 hide-for-print'>
      <div className='grid-y all-rounded-borders shadow'>
        <div className='cell'>
          <div className='text-center h2 margin-vertical-2'>
            <div className='margin-vertical-1 flex-align'>
              <div className='grid-x align-center'>
                <div className='cell small-2 icon'>
                  <img src={TriangleIcon}/>
                </div>
                <div className='cell small-8 align-self-middle'>
                  <h3 className='text-uppercase margin-bottom-0'>
                    Potential benefits of the treatment options
                  </h3>
                </div>
              </div>
            </div>
            <div className='symbols h4'>+</div>
            <div className='margin-vertical-1'>
              <div className='grid-x align-center'>
                <div className='cell small-2 icon'>
                  <img src={Square}/>
                </div>
                <div className='cell small-8 align-self-middle'>
                  <h3 className='text-uppercase margin-bottom-0'>
                    Potential harms of the treatment options
                  </h3>
                </div>
              </div>
            </div>
            <div className='symbols h4'>+</div>
            <div className='margin-vertical-1'>
              <div className='grid-x align-center'>
                <div className='cell small-2 icon'>
                  <img src={Circle}/>
                </div>
                <div className='cell small-8  align-self-middle'>
                  <h3 className='text-uppercase margin-bottom-0'>
                    What treatment might happen next with each option
                  </h3>
                </div>
              </div>
            </div>
            <div className='symbols h4'>=</div>
          </div>
        </div>
        <div className='cell dark round-borders'>
          <div className='text-center margin-vertical-2'>
            <h3 className='text-uppercase font-bold'>
                your informed decision
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div className='text-center show-for-print'>
      <img
        alt='angiogram'
        className='img-print-styles'
        src={WeighInformation}
        width={700}/>
    </div>
  </>;

export default WeighingInfo;
