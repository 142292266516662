import React from 'react';
import {AdditionalInfo} from './additionalInfo';

export const References = () =>
  <div className='padding-horizontal-2 padding-bottom-2'>
    <AdditionalInfo/>
    <h4>References:</h4>
    <ol>
      <li>
        <p>
          Maron DJ, Hochman JS, Reynolds HR, Bangalore S, O&apos;Brien SM, Boden WE, Chaitman BR, Senior R,
          López-Sendón J, Alexander KP, Lopes RD, Shaw LJ, Berger JS, Newman JD, Sidhu MS, Goodman SG, Ruzyllo W,
          Gosselin G, Maggioni AP, White HD, Bhargava B, Min JK, Mancini GBJ, Berman DS, Picard MH, Kwong RY,
          Ali ZA, Mark DB, Spertus JA, Krishnan MN, Elghamaz A, Moorthy N, Hueb WA, Demkow M, Mavromatis K,
          Bockeria O, Peteiro J, Miller TD, Szwed H, Doerr R, Keltai M, Selvanayagam JB, Steg PG, Held C,
          Kohsaka S, Mavromichalis S, Kirby R, Jeffries NO, Harrell FE Jr, Rockhold FW, Broderick S, Ferguson
          TB Jr, Williams DO, Harrington RA, Stone GW, Rosenberg Y; ISCHEMIA Research Group. Initial Invasive
          or Conservative Strategy for Stable Coronary Disease. N Engl J Med. 2020 Apr 9;382(15):1395-1407.
        </p>
      </li>
      <li>
        <p>
          Bangalore S, Maron DJ, O&apos;Brien SM, Fleg JL, Kretov EI, Briguori C, Kaul U, Reynolds HR, Mazurek T,
          Sidhu MS, Berger JS, Mathew RO, Bockeria O, Broderick S, Pracon R, Herzog CA, Huang Z, Stone GW,
          Boden WE, Newman JD, Ali ZA, Mark DB, Spertus JA, Alexander KP, Chaitman BR, Chertow GM, Hochman JS;
          ISCHEMIA-CKD Research Group. Management of Coronary Disease in Patients with Advanced Kidney Disease.
          N Engl J Med. 2020 Apr 23;382(17):1608-1618.
        </p>
      </li>
    </ol>
  </div>;
