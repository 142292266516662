import {HEALTH_FACTORS} from '../constants';

const initialState = {
  diagnosis: '',
  chestPainSymptoms: '',
  age: null,
  sex: '',
  egfr: null,
  hemoglobin: null,
  albuminuria: '',
  clinicalHistory: []
};

export default function score(state = initialState, action) {
  switch (action.type) {
  case HEALTH_FACTORS.SAVE_HEALTH_FACTORS: {
    return action.data;
  }

  case HEALTH_FACTORS.RESET_HEALTH_FACTORS: {
    return initialState;
  }

  default:
    return state;
  }
}
