import React, {useEffect} from 'react';
import ROUTES from '../../../routes';
import {useSelector} from 'react-redux';
import {GLOSSARY_KEYS, HEART_CONDITIONS} from '../appConstants';
import BackNextButtons from '../../components/common/backNextButtons';
import {useHistory, useLocation} from 'react-router-dom';
import HeartAttackEarlyInvasiveDetails from '../../components/HeartAttack/earlyInvasiveDetails';
import StableCoronaryHeartDiseaseEarlyInvasiveDetails
from '../../components/StableCoronaryHeartDisease/earlyInvasiveDetails';
import HeartAttackMedicationOnlyDetails from '../../components/HeartAttack/medicationOnlyDetails';
import StableCoronaryHeartDiseaseMedicationOnlyDetails
from '../../components/StableCoronaryHeartDisease/medicationOnlyDetails';
import {Tooltip} from '../../components/common/tooltip';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const heartCondition = useSelector(state => state.heartCondition);
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);
  return <>
    <section>
      <div className='grid-container'>
        <div className='grid-x'>
          <div className='cell large-9'>
            <h1>
              Your Options:
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section className='shadow all-rounded-borders white-bg-color margin-bottom-2'>
      <div className='grid-container'>
        <h2>Invasive Heart Procedure plus Optimal Medication Management</h2>
        <p>Procedures to find and unblock blood vessels combined with&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.MEDICATIONS}
            title='medications'/>
          &nbsp;protect the heart from further coronary disease.
        </p>
        <div className='grid-x text-left margin-vertical-1'>
          <div className='cell bordered all-rounded-borders'>
            <details>
              <summary className='padding-2'>
                <h3 className='info-color text-uppercase'>
                  Details
                </h3>
              </summary>
              {
                heartCondition === HEART_CONDITIONS.HEART_ATTACK
                  ? <HeartAttackEarlyInvasiveDetails/>
                  : <StableCoronaryHeartDiseaseEarlyInvasiveDetails/>
              }
            </details>
          </div>
        </div>
      </div>
      <div className='grid-container margin-vertical-3'>
        <h2>Optimal Medication Management</h2>
        <p>Attempt to treat with medications alone and avoid&nbsp;
          <Tooltip
            id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
            title='invasive heart procedures'/>, if possible.
        </p>
        <div className='grid-x text-left margin-vertical-1'>
          <div className='cell bordered all-rounded-borders'>
            <details>
              <summary className='padding-2'>
                <h3 className='info-color text-uppercase'>
                  Details
                </h3>
              </summary>
              {
                heartCondition === HEART_CONDITIONS.HEART_ATTACK
                  ? <HeartAttackMedicationOnlyDetails/>
                  : <StableCoronaryHeartDiseaseMedicationOnlyDetails/>
              }
            </details>
          </div>
        </div>
      </div>
    </section>
    <BackNextButtons
      backButtonRoute={ROUTES.PROCEED_ON_PAPER}
      nextButtonRoute={ROUTES.MY_RISK_FACTORS}/>
  </>;
};

export default Index;
