import React from 'react';
import PropTypes from 'prop-types';
import subsequentCareInfo from '../../../../../assets/images/icons/subsequent_care_info.svg';
import figureBlue from '../../../../../assets/images/icons/figure_blue.svg';
import figurePurple from '../../../../../assets/images/icons/figure_purple.svg';
import {SubsequentCareFigures} from '../../../common/subsequentCareFigures';

export const Details = ({invasive, conservative}) =>
  <div className='grid-x padding-horizontal-2 padding-bottom-2 print-paddings page-break-before'>
    <div className='cell'>
      <div className='padding-bottom-1'>
        <h2>
          Outcome: Likelihood of going on to have heart surgery
        </h2>
        <p>
          These blocks of 100 faces show a best estimate of what might happen next to 100 people like you who
          chose different treatment options. Each face stands for 1 person. The colored areas show the estimated
          number of people like you affected.
        </p>
      </div>
      <div className='grid-x margin-bottom-2'>
        <div className='grid-x align-center-middle medium-8'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={figureBlue}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Patients who are likely to go on to have heart surgery.
            </p>
          </div>
        </div>
        <div className='grid-x align-center-middle medium-8'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={figurePurple}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Additional patients who went on to have heart surgery.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='grid-x text-center'>
          <div className='auto cell'>
            <h4 className='height-50-px'>Invasive Heart Procedure <br/>
              plus Optimal Medication Management</h4>
            <div className='card padding-top-1'>
              <SubsequentCareFigures
                conservative={conservative}
                invasive={invasive}/>
            </div>
          </div>
          <div className='auto cell'>
            <h4 className='height-50-px'>Optimal Medication Management</h4>
            <div className='card padding-top-1'>
              <SubsequentCareFigures
                conservative={conservative}
                invasive={0}/>
            </div>
          </div>
        </div>
        <div className='grid-x margin-top-1 align-center-middle'>
          <div className='cell shrink margin-0'>
            <img
              alt='Icon image'
              className='margin-right-1'
              src={subsequentCareInfo}/>
          </div>
          <div className='cell auto margin-0 text-left'>
            <p className='margin-0'>
              Treatment with an invasive heart procedure resulted in&nbsp;<span className='subsequent-care-text'>
              13 more heart surgeries per 100 patients</span>&nbsp;like you compared to optimal medication management.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>;

Details.propTypes = {
  conservative: PropTypes.number.isRequired,
  invasive: PropTypes.number.isRequired
};
