import React from 'react';

export const SummaryAbout = () =>
  <section className='shadow all-rounded-borders white-bg-color margin-bottom-2 page-break-before'>
    <div className='grid-container'>
      <div className='cell large-9'>
        <h1>About this Tool</h1>
        <p>
          This decision aid was developed to provide patients with coronary heart disease and chronic kidney disease
          (CKD) information about their treatment options, share personalized risks and benefits of treatment options,
          and communicate the treatment preferences. The decision aid is intended to be used by patients and health
          care providers together within a shared decision-making framework to improve knowledge exchange of
          benefit/risk information, and communication of patient values and preferences.
        </p>
        <p>
          This decision aid was developed by the Can-SOLVE CKD Network APPROACH research team in collaboration
          with key stakeholders including patients, physicians, nurses, and health services researchers from
          across Canada.
        </p>
        <p>
          This work is a funded by the Can-SOLVE CKD Chronic Disease Network,
          supported by the Canadian Institutes of Health Research Strategic Patient
          Oriented Research (SPOR) grant 20R26070 and the Alberta
          Provincial Project for Outcomes Assessment in Coronary Heart Disease (APPROACH).
        </p>
      </div>
    </div>
  </section>;
