export const COEFFICIENTS = {
  age: 0.0116,
  sex: -0.0785,
  nstemi: 0.5363,
  eGFR: -0.0050,
  hemo: -0.0010,
  albuminuriaMild: 0.0214,
  albuminuriaHEavy: 0.2143,
  charlsonCHF: 0.2150,
  charlsonCOPD: 0.0279,
  liverDis: 0.1320,
  charlsonPVD: 0.1659,
  diabetes: 0.1740,
  priorInv: 0.6608
};

export const BASELINES = {
  age: 77.14,
  eGFR: 51.41,
  hemo: 126.74
};

export const FACTOR = {
  conservativeMgmt: 1 / 0.79
};
