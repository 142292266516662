import React from 'react';
import {Details as AngioplastyDetails} from './Angioplasty/details';
import {References as AngioplastyReferences} from './Angioplasty/references';
import {AdditionalInfo as AngioplastyAddInfo} from './Angioplasty/additionalInfo';
import {Details as HeartSurgeryDetails} from './HeartSurgery/details';
import {References as HeartSurgeryReferences} from './HeartSurgery/references';
import {AdditionalInfo as HeartSurgeryAddInfo} from './HeartSurgery/additionalInfo';
import subsequentCareInfo from '../../../../assets/images/icons/subsequent_care_info.svg';
import subsequentCareSymbol from '../../../../assets/images/icons/subsequent_care_symbol.svg';
import {SUBSEQUENT_CARE} from '../../../equations/subsequentCareEqConstants';
import {Tooltip} from '../../common/tooltip';
import {GLOSSARY_KEYS} from '../../../containers/appConstants';

export const SubsequentCareDetails = () =>
  <>
    <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
      <div className='grid-container'>
        <div className='text-center'>
          <h2>How likely you are to go on to have an angioplasty (stent) procedure</h2>
          <div className='grid-x medium-up-2 margin-vertical-1'>
            <div className='cell'>
              <div className='card align-center border-right'>
                <h4 className='margin-bottom-1 height-50-px'>
                  Invasive Heart Procedure plus<br/> Optimal Medication Management
                </h4>
                <div className='rounded percentage-circle bg-color-purple margin-vertical-1'>
                  <div className='rounded inside-circle'>
                    <img
                      alt='Icon image'
                      src={subsequentCareSymbol}/>
                    <p className='margin-0'>{SUBSEQUENT_CARE.FIFTY_EIGHT}%</p>
                  </div>
                </div>
                <p>
                  ({SUBSEQUENT_CARE.FIFTY_EIGHT}/100)
                </p>
              </div>
            </div>
            <div className='cell'>
              <div className='card align-center'>
                <h4 className='margin-bottom-1 height-50-px'>
                  Optimal Medication Management
                </h4>
                <div className='rounded percentage-circle bg-color-purple margin-vertical-1'>
                  <div className='rounded inside-circle'>
                    <img
                      alt='Icon image'
                      src={subsequentCareSymbol}/>
                    <p className='margin-0'>{SUBSEQUENT_CARE.TWENTY_EIGHT}%</p>
                  </div>
                </div>
                <p>
                  ({SUBSEQUENT_CARE.TWENTY_EIGHT}/100)
                </p>
              </div>
            </div>
          </div>
          <div className='grid-x align-center-middle'>
            <div className='cell shrink margin-0'>
              <img
                alt='Icon image'
                className='margin-right-1'
                src={subsequentCareInfo}/>
            </div>
            <div className='cell auto margin-0 text-left'>
              <p className='margin-0'>
                Treatment with an&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                  title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                30 more angioplasty procedures per 100 patients</span>&nbsp;like you compared to&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                  title='optimal medication management'/>.
              </p>
            </div>
          </div>
        </div>
        {/* The below section is only visible on screen, not while printing */}
        <div className='grid-x text-left margin-vertical-1 hide-for-print'>
          <div className='cell bordered all-rounded-borders'>
            <details>
              <summary className='padding-2'>
                <h3 className='info-color text-uppercase'>What does this look like?</h3>
              </summary>
              <AngioplastyDetails
                conservative={SUBSEQUENT_CARE.TWENTY_EIGHT}
                invasive={SUBSEQUENT_CARE.FIFTY_EIGHT}
              />
            </details>
          </div>
        </div>
        {/* The below section is only visible while printing */}
        <div className='margin-vertical-2 padding-vertical-2 show-for-print bordered all-rounded-borders text-left'>
          <AngioplastyDetails
            conservative={SUBSEQUENT_CARE.TWENTY_EIGHT}
            invasive={SUBSEQUENT_CARE.FIFTY_EIGHT}
          />
          <div className='padding-horizontal-2'>
            <AngioplastyAddInfo/>
          </div>
        </div>
        {/* The below section is only visible on screen */}
        <div className='grid-x text-left margin-vertical-1 hide-for-print'>
          <div className='cell bordered all-rounded-borders'>
            <details>
              <summary className='padding-2'>
                <h3 className='info-color text-uppercase'>
                  What is this information based on? (Show me the references)
                </h3>
              </summary>
              <AngioplastyReferences/>
            </details>
          </div>
        </div>
      </div>
    </section>
    <section className='bordered all-rounded-borders white-bg-color margin-bottom-2'>
      <div className='grid-container'>
        <div className='text-center'>
          <h2>How likely you are to go on to have heart surgery</h2>
          <div className='grid-x medium-up-2 margin-vertical-1'>
            <div className='cell'>
              <div className='card align-center border-right'>
                <h4 className='margin-bottom-1 height-50-px'>
                  Invasive Heart Procedure plus<br/> Optimal Medication Management
                </h4>
                <div className='rounded percentage-circle bg-color-purple margin-vertical-1'>
                  <div className='rounded inside-circle'>
                    <img
                      alt='Icon image'
                      src={subsequentCareSymbol}/>
                    <p className='margin-0'>{SUBSEQUENT_CARE.TWENTY_EIGHT}%</p>
                  </div>
                </div>
                <p>
                  ({SUBSEQUENT_CARE.TWENTY_EIGHT}/100)
                </p>
              </div>
            </div>
            <div className='cell '>
              <div className='card align-center'>
                <h4 className='margin-bottom-1 height-50-px'>
                  Optimal Medication Management
                </h4>
                <div className='rounded percentage-circle bg-color-purple margin-vertical-1'>
                  <div className='rounded inside-circle'>
                    <img
                      alt='Icon image'
                      src={subsequentCareSymbol}/>
                    <p className='margin-0'>{SUBSEQUENT_CARE.FIFTEEN}%</p>
                  </div>
                </div>
                <p>
                  ({SUBSEQUENT_CARE.FIFTEEN}/100)
                </p>
              </div>
            </div>
          </div>
          <div className='grid-x align-center-middle'>
            <div className='cell shrink margin-0'>
              <img
                alt='Icon image'
                className='margin-right-1'
                src={subsequentCareInfo}/>
            </div>
            <div className='cell auto margin-0 text-left'>
              <p className='margin-0'>
                Treatment with an&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.INVASIVE_HEART_PROCEDURE}
                  title='invasive heart procedure'/>&nbsp;resulted in&nbsp;<span className='subsequent-care-text'>
                13 more heart surgeries per 100 patients</span>&nbsp;like you compared to&nbsp;
                <Tooltip
                  id={GLOSSARY_KEYS.OPTIMAL_MEDICATION_MANAGEMENT}
                  title='optimal medication management'/>.
              </p>
            </div>
          </div>
          {/* The below section is only visible on screen, not while printing */}
          <div className='grid-x text-left margin-vertical-1 hide-for-print'>
            <div className='cell bordered all-rounded-borders'>
              <details>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>What does this look like?</h3>
                </summary>
                <HeartSurgeryDetails
                  conservative={SUBSEQUENT_CARE.FIFTEEN}
                  invasive={SUBSEQUENT_CARE.TWENTY_EIGHT}
                />
              </details>
            </div>
          </div>
          {/* The below section is only visible while printing */}
          <div className='margin-vertical-2 padding-vertical-2 show-for-print bordered all-rounded-borders text-left'>
            <HeartSurgeryDetails
              conservative={SUBSEQUENT_CARE.FIFTEEN}
              invasive={SUBSEQUENT_CARE.TWENTY_EIGHT}
            />
            <div className='padding-horizontal-2'>
              <HeartSurgeryAddInfo/>
            </div>
          </div>
          {/* The below section is only visible on screen */}
          <div className='grid-x text-left margin-vertical-1 hide-for-print'>
            <div className='cell bordered all-rounded-borders'>
              <details>
                <summary className='padding-2'>
                  <h3 className='info-color text-uppercase'>
                    What is this information based on? (Show me the references)
                  </h3>
                </summary>
                <HeartSurgeryReferences/>
              </details>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
