import {NEXT_STEPS} from '../constants';
const initialState = null;

export default function heartCondition(state = initialState, action) {
  switch (action.type) {
  case NEXT_STEPS.SAVE_NEXT_STEPS: {
    return action.nextSteps;
  }

  case NEXT_STEPS.RESET_NEXT_STEPS: {
    return initialState;
  }

  default:
    return state;
  }
}
