import {UNLOCK_NAV_ITEMS} from '../constants';
import ROUTES from '../../routes';

const initialState = [
  ROUTES.ROOT,
  ROUTES.ABOUT_THIS_TOOL,
  ROUTES.GLOSSARY,
  ROUTES.DISCLAIMER,
  ROUTES.PRIVACY_POLICY,
  ROUTES.INTRODUCTION,
  ROUTES.HEART_CONDITION
];

export default function unlockedNavItems(state = initialState, action) {
  switch (action.type) {
  case UNLOCK_NAV_ITEMS.SET_UNLOCK_ITEM: {
    return [...state, ...action.pages.filter(ele => !state.includes(ele))];
  }

  case UNLOCK_NAV_ITEMS.RESET_UNLOCK_ITEM: {
    return initialState;
  }

  default:
    return state;
  }
}
