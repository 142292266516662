import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {HEART_CONDITIONS} from '../appConstants';
import ROUTES from '../../../routes';
import {
  SubsequentCareDetails as HASubsequentCareDetails
} from '../../components/HeartAttack/SubsequentCare/subsequentCareDetails';
import {
  SubsequentCareDetails as SCHDSubsequentCareDetails
} from '../../components/StableCoronaryHeartDisease/SubsequentCare/subsequentCareDetails';
import {Link, useHistory, useLocation} from 'react-router-dom';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);
  const heartCondition = useSelector(state => state.heartCondition);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, []);

  return (
    <>
      <section>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell'>
              <h1>
                What treatment might happen next with each option
              </h1>
              <h3 className='text-uppercase primary-color'>
                Your Personalized Comparisons - What might happen next:
              </h3>
              <p>
                The decision aid has personalized your risk factors based on your submitted health factor information
                (age, sex, medical conditions, and lab tests). It will compare the likelihood of potential additional
                treatments you may receive with the two treatment options.
              </p>
              <p>
                This might happen in the same procedure, soon after your angiogram, or at a later date.
              </p>
            </div>
          </div>
        </div>
      </section>
      {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
        <HASubsequentCareDetails/>
      }
      {heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
        <SCHDSubsequentCareDetails/>
      }
      <div>
        <section>
          <div className='grid-container'>
            <div className='grid-x small-up-2 align-center text-center'>
              <div className='cell'>
                <Link
                  className='hollow secondary button large rounded padding-1 padding-horizontal-2'
                  to={ROUTES.RISKS_OVERVIEW}>Back</Link>
              </div>
              <div className='cell'>
                <Link
                  className='primary button rounded large padding-1 padding-horizontal-2'
                  to={ROUTES.DECISION_AID_SUMMARY}>Next</Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Index;
