import React from 'react';

const ProceedOnPaper = () =>
  <>
    <section className='top-rounded-borders'>
      <div className='grid-container'>
        <h2>What should you keep in mind using the decision aid?</h2>
        <p>
            All patients are unique. There are no wrong decisions.
        </p>
        <p>
            This decision aid aims to help patients and doctors discuss treatment choices when determining how to
            best manage a heart attack alongside the presence of kidney disease.
        </p>
      </div>
    </section>
    <section className='dark border-right'>
      <div className='grid-container'>
        <div className='cell large-9 text-left padding-1'>
          <h2 className='white-color'>Let’s start the decision aid to help you answer the question:</h2>
          <p className='quotes'>
            &quot;Should I have an&nbsp;<span className='teal-color'>invasive heart procedure in addition to optimal
            medication management</span>&nbsp;after my heart attack, or attempt to treat it with&nbsp;
            <span className='teal-color'>&nbsp;optimal medication</span>&nbsp;only?&quot;
          </p>
          <p className='white-color'>
            Be sure to write down questions for your doctor as you go so you know what you want to ask.
          </p>
        </div>
      </div>
    </section>
  </>;

export default ProceedOnPaper;
