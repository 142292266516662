// _PRAIP_AKIRD stands for Predicted Risk Assuming Invasive Procedure - Acute Kidney Injury Requiring Dialysis

/* eslint-disable camelcase,max-len,no-bitwise,no-trailing-spaces,max-statements */
// noinspection UnnecessaryLocalVariableJS

import {HEALTH_FACTORS} from '../../../containers/appConstants';
import {COEFFICIENTS, BASELINES} from './eqConstants';

export const _PRAIP_AKIRD = patientHealthFactors => {
  const age = patientHealthFactors.age;
  const sex = patientHealthFactors.sex === HEALTH_FACTORS.MALE ? 1 : 0;
  const nstemi = patientHealthFactors.diagnosis === HEALTH_FACTORS.NSTEMI ? 1 : 0;
  const eGFR = patientHealthFactors.egfr;
  const haemoglobin = patientHealthFactors.hemoglobin;
  const albuminuria_mild = patientHealthFactors.albuminuria === HEALTH_FACTORS.MILD ? 1 : 0;
  const albuminuria_heavy = patientHealthFactors.albuminuria === HEALTH_FACTORS.HEAVY ? 1 : 0;
  const diabetes = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.DIABETES_MELLITUS) ? 1 : 0;
  const prior_invasive = patientHealthFactors.clinicalHistory.includes(HEALTH_FACTORS.PRIOR_CARDIAC_CATHETERIZATION) ? 1 : 0;

  const AEBX = -6.9303 +
    COEFFICIENTS.age * (age - BASELINES.age) +
    COEFFICIENTS.sex * sex +
    COEFFICIENTS.nstemi * nstemi +
    COEFFICIENTS.eGFR * (eGFR - BASELINES.eGFR) +
    COEFFICIENTS.hemo * (haemoglobin - BASELINES.hemo) +
    COEFFICIENTS.albuminuriaMild * albuminuria_mild +
    COEFFICIENTS.albuminuriaHEavy * albuminuria_heavy +
    COEFFICIENTS.diabetes * diabetes +
    COEFFICIENTS.priorInv * prior_invasive;

  const predictedRiskInvasive = Math.pow(Math.exp(Math.abs(AEBX)), Math.sign(AEBX)) / (1 + Math.pow(Math.exp(Math.abs(AEBX)), Math.sign(AEBX)));

  const acuteKidneyInjuryRequiringDialysis = {
    PRI: predictedRiskInvasive,
    probability: predictedRiskInvasive * 100
  };

  return acuteKidneyInjuryRequiringDialysis;
};
