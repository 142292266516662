import React from 'react';
import {useSelector} from 'react-redux';
import {HEALTH_FACTORS, HEALTH_FACTORS_VALUES, HEART_CONDITIONS} from '../../containers/appConstants';

const MyInformation = () => {
  const {
    diagnosis,
    age,
    sex,
    egfr,
    hemoglobin,
    albuminuria,
    clinicalHistory,
    chestPainSymptoms
  } = useSelector(state => state.healthFactors);
  const heartCondition = useSelector(state => state.heartCondition);

  let CH;

  if (Array.isArray(clinicalHistory)) {
    CH = clinicalHistory.map(element => `${HEALTH_FACTORS_VALUES[`${element}`]}, `);
  }
  else {
    CH = HEALTH_FACTORS_VALUES[`${clinicalHistory}`];
  }

  return (
    <>
      <div className='grid-x margin-3 shadow all-rounded-borders white-bg-color padding-2 my-health-factors-print-styles avoid-page-break-inside'>
        <div className='cell'>
          <h4 className='info-color'>Age:&nbsp;<span className='font-normal black-color'>{age}</span></h4>
        </div>
        <div className='cell'>
          <h4 className='info-color '>Sex:&nbsp;<span className='font-normal black-color'>{sex}</span></h4>
        </div>
        {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
          <div className='cell'>
            <h4 className='info-color'>
              Diagnosis:&nbsp;
              <span className='font-normal black-color'>
                {diagnosis === HEALTH_FACTORS.NSTEMI
                  ? HEALTH_FACTORS_VALUES.NSTEMI
                  : HEALTH_FACTORS_VALUES.Unstable_Angina}
              </span>
            </h4>
          </div>
        }
        {
          heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
          <div className='cell'>
            <h4 className='info-color'>Frequency of chest pain, tightness, or angina symptoms:&nbsp;
              <span className='font-normal black-color'>
                {chestPainSymptoms}
              </span></h4>
          </div>
        }
        <div className='cell'>
          <h4 className='info-color'>eGFR:&nbsp;<span className='font-normal black-color'>{egfr}</span></h4>
        </div>
        <div className='cell'>
          <h4 className='info-color'>Hemoglobin:&nbsp;<span className='font-normal black-color'>{hemoglobin}</span></h4>
        </div>
        <div className='cell'>
          <h4 className='info-color'>Albuminuria:&nbsp;<span className='font-normal black-color'>
            {HEALTH_FACTORS_VALUES[albuminuria]}
          </span></h4>
        </div>
        <div className='cell'>
          <h4 className='info-color'>Clinical History:&nbsp;<span className='font-normal black-color'>{CH}</span></h4>
        </div>
      </div>
    </>
  );
};

export default MyInformation;
