/* eslint-disable max-len */
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {resetHealthFactors, saveHealthFactors} from '../../../redux/actions/healthFactors';
import {Link, useHistory, useLocation} from 'react-router-dom';
import ROUTES from '../../../routes';
import {HEALTH_FACTORS, HEART_CONDITIONS} from '../appConstants';
import {unlockNavItem, resetUnlockedNavItems} from '../../../redux/actions/unlockedNavItems';

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const heartCondition = useSelector(state => state.heartCondition);
  const healthFactors = useSelector(state => state.healthFactors);
  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues: healthFactors
  });
  const unlockedNavItems = useSelector(state => state.unlockedNavItems);

  useEffect(() => {
    if (!unlockedNavItems.includes(location.pathname)) {
      history.push([...unlockedNavItems].pop());
    }
  }, [healthFactors]);

  const onSubmit = data => {
    dispatch(saveHealthFactors(data));
    dispatch(unlockNavItem([
      ROUTES.PROCEED_ON_PAPER,
      ROUTES.INITIAL_OPTIONS,
      ROUTES.MY_RISK_FACTORS,
      ROUTES.WEIGHING_INFORMATION,
      ROUTES.RISKS_OVERVIEW,
      ROUTES.BENEFITS_OVERVIEW,
      ROUTES.SUBSEQUENT_CARE_OVERVIEW,
      ROUTES.DECISION_AID_SUMMARY,
      ROUTES.MY_PRIORITIES,
      ROUTES.CHOICES,
      ROUTES.NEXT_STEPS,
      ROUTES.SUMMARY_REPORT
    ]));
    history.push(ROUTES.PROCEED_ON_PAPER);
  };

  const handleReset = () => {
    dispatch(resetHealthFactors());
    // reset the nav items as well while resetting the form
    dispatch(resetUnlockedNavItems());
    // Also add the Forms routes to unloacked nav items so that users can stay on the same page
    dispatch(unlockNavItem([ROUTES.HEALTH_FACTORS]));
  };

  return (
    <>
      <section>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell'>
              <h1>Your Health Factors</h1>
              <p>The following risk factor information will help doctors provide more personalized information
                about the benefits and risks (potential harms) of an invasive heart procedure or optimal medication
                management.
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <p className='margin-0 explanation'>
                &apos;<span className='required-field-explanation'>*</span>&apos; indicates required field
              </p>
            </div>
            <div className='grid-x align-center'>
              {heartCondition === HEART_CONDITIONS.STABLE_CORONARY_HEART_DISEASE &&
                <div className='cell form-card small-10'>
                  <p className='lead required-field'>Frequency of chest pain, tightness, or angina symptoms</p>
                  <div className='flex-align'>
                    <input
                      id={HEALTH_FACTORS.NONE}
                      name={HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS}
                      type='radio'
                      value={HEALTH_FACTORS.NONE}
                      {...register(HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS, {required: true})}
                    />
                    <label
                      className='margin-0 padding-1 width-100'
                      htmlFor={HEALTH_FACTORS.NONE}>
                      None
                    </label>
                  </div>
                  <div className='flex-align'>
                    <input
                      id={HEALTH_FACTORS.MONTHLY}
                      name={HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS}
                      type='radio'
                      value={HEALTH_FACTORS.MONTHLY}
                      {...register(HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS, {required: true})}
                    />
                    <label
                      className='margin-0 padding-1 width-100'
                      htmlFor={HEALTH_FACTORS.MONTHLY}>
                      Monthly
                    </label>
                  </div>
                  <div className='flex-align'>
                    <input
                      id={HEALTH_FACTORS.WEEKLY}
                      name={HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS}
                      type='radio'
                      value={HEALTH_FACTORS.WEEKLY}
                      {...register(HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS, {required: true})}
                    />
                    <label
                      className='margin-0 padding-1 width-100'
                      htmlFor={HEALTH_FACTORS.WEEKLY}>
                      Weekly
                    </label>
                  </div>
                  <div className='flex-align'>
                    <input
                      id={HEALTH_FACTORS.DAILY}
                      name={HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS}
                      type='radio'
                      value={HEALTH_FACTORS.DAILY}
                      {...register(HEALTH_FACTORS.CHEST_PAIN_SYMPTOMS, {required: true})}
                    />
                    <label
                      className='margin-0 padding-1 width-100'
                      htmlFor={HEALTH_FACTORS.DAILY}>
                      Daily
                    </label>
                  </div>
                  {errors.chestPainSymptoms && <span className='error'>This field is required</span>}
                </div>
              }
              {heartCondition === HEART_CONDITIONS.HEART_ATTACK &&
                <div className='cell form-card small-10'>
                  <p className='lead required-field'>Diagnosis</p>
                  <div className='flex-align'>
                    <input
                      id={HEALTH_FACTORS.NSTEMI}
                      name={HEALTH_FACTORS.DIAGNOSIS}
                      type='radio'
                      value={HEALTH_FACTORS.NSTEMI}
                      {...register(HEALTH_FACTORS.DIAGNOSIS, {required: true})}
                    />
                    <label
                      className='margin-0 padding-1 width-100'
                      htmlFor={HEALTH_FACTORS.NSTEMI}>
                      Non-ST Elevation Myocardial Infarction (heart attack)
                    </label>
                  </div>
                  <div className='flex-align'>
                    <input
                      id={HEALTH_FACTORS.UNSTABLE_ANGINA}
                      name={HEALTH_FACTORS.DIAGNOSIS}
                      type='radio'
                      value={HEALTH_FACTORS.UNSTABLE_ANGINA}
                      {...register(HEALTH_FACTORS.DIAGNOSIS, {required: true})}
                    />
                    <label
                      className='margin-0 padding-1 width-100'
                      htmlFor={HEALTH_FACTORS.UNSTABLE_ANGINA}>
                      Unstable Angina
                    </label>
                  </div>
                  {errors.diagnosis && <span className='error'>This field is required</span>}
                </div>
              }
              <div className='cell form-card small-10'>
                <div className='flex-align'>
                  <p className='lead required-field margin-right-2 margin-bottom-0'>
                    Age (18 - 105)
                  </p>
                  <input
                    type='number'
                    {...register('age', {valueAsNumber: true, required: true, min: 18, max: 105})}/>
                </div>
                {
                  errors.age && errors.age.type === 'min' &&
                  <span className='error'>Entered age must be greater than or equal to 18</span>
                }
                {
                  errors.age && errors.age.type === 'max' &&
                  <span className='error'>Entered age must be smaller than or equal to 105</span>
                }
              </div>
              <div className='cell form-card small-10'>
                <p className='lead required-field'>Sex</p>
                <div className='flex-align'>
                  <input
                    id='female'
                    name='sex'
                    type='radio'
                    value={HEALTH_FACTORS.FEMALE}
                    {...register('sex', {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='female'>
                    Female
                  </label>
                </div>
                <div className='flex-align'>
                  <input
                    id='male'
                    name='sex'
                    type='radio'
                    value={HEALTH_FACTORS.MALE}
                    {...register('sex', {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor='male'>
                    Male
                  </label>
                </div>
                {errors.sex && <span className='error'>This field is required</span>}
              </div>
              <div className='cell form-card small-10'>
                <div className='flex-align'>
                  <p className='lead required-field margin-right-2 margin-bottom-0'>
                  eGFR (10 -130)
                  </p>
                  <div className='grid-x align-middle'>
                    <input
                      type='text'
                      {...register('egfr', {valueAsNumber: true, required: true, validate: value => !isNaN(value), min: 10, max: 130})}/>
                    <span>(mL/min/1.73m<sup>2</sup>)</span>
                  </div>
                </div>
                {errors.egfr && <span className='error'>This field is required and it expects a number</span>}
                {
                  errors.egfr && errors.egfr.type === 'min' &&
                  <span className='error'>Entered eGFR must be greater than or equal to 10</span>
                }
                {
                  errors.egfr && errors.egfr.type === 'max' &&
                  <span className='error'>Entered eGFR must be smaller than or equal to 130</span>
                }
              </div>
              <div className='cell form-card small-10'>
                <div className='flex-align'>
                  <p className='lead required-field margin-right-2 margin-bottom-0'>
                  Hemoglobin (40 - 180)
                  </p>
                  <div className='grid-x align-middle'>
                    <input
                      className='small-12 medium-3'
                      type='text'
                      {...register('hemoglobin', {valueAsNumber: true, required: true, validate: value => !isNaN(value), min: 40, max: 180})}/>
                    <span>(g/L)</span>
                  </div>
                </div>
                {errors.hemoglobin && <span className='error'>This field is required and it expects a number</span>}
                {
                  errors.hemoglobin && errors.hemoglobin.type === 'min' &&
                  <span className='error'>Entered hemoglobin must be greater than or equal to 40</span>
                }
                {
                  errors.hemoglobin && errors.hemoglobin.type === 'max' &&
                  <span className='error'>Entered hemoglobin must be smaller than or equal to 180</span>
                }
              </div>
              <div className='cell form-card small-10'>
                <p className='lead required-field'>Albuminuria</p>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.NORMAL}
                    name={HEALTH_FACTORS.ALBUMINURIA}
                    type='radio'
                    value={HEALTH_FACTORS.NORMAL}
                    {...register(HEALTH_FACTORS.ALBUMINURIA, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.NORMAL}>
                    Normal / None Known (Albumin/Creatinine ratio &lt; 3mg/mmoL or urine dipstick NEGATIVE)
                  </label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.MILD}
                    name={HEALTH_FACTORS.ALBUMINURIA}
                    type='radio'
                    value={HEALTH_FACTORS.MILD}
                    {...register(HEALTH_FACTORS.ALBUMINURIA, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.MILD}>
                    Mild (Albumin/Creatinine ratio 3-30mg/mmoL or urine dipstick TRACE/1+)
                  </label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.HEAVY}
                    name={HEALTH_FACTORS.ALBUMINURIA}
                    type='radio'
                    value={HEALTH_FACTORS.HEAVY}
                    {...register(HEALTH_FACTORS.ALBUMINURIA, {required: true})}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.HEAVY}>
                    Heavy (Albumin/Creatinine ratio &gt; 30mg/mmoL or urine dipstick &gt;= 2+)
                  </label>
                </div>
                {errors.albuminuria && <span className='error'>This field is required</span>}
              </div>
              <div className='cell form-card small-10'>
                <p className='lead'>Clinical History</p>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.DIABETES_MELLITUS}
                    type='checkbox'
                    value={HEALTH_FACTORS.DIABETES_MELLITUS}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.DIABETES_MELLITUS}>Diabetes Mellitus</label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.CONGESTIVE_HEART_FAILURE}
                    type='checkbox'
                    value={HEALTH_FACTORS.CONGESTIVE_HEART_FAILURE}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.CONGESTIVE_HEART_FAILURE}>Congestive Heart Failure</label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.CHRONIC_PULMONARY_DISEASE}
                    type='checkbox'
                    value={HEALTH_FACTORS.CHRONIC_PULMONARY_DISEASE}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.CHRONIC_PULMONARY_DISEASE}>Chronic Obstructive Pulmonary Disease</label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.LIVER_DISEASE}
                    type='checkbox'
                    value={HEALTH_FACTORS.LIVER_DISEASE}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.LIVER_DISEASE}>Liver Disease</label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.MYOCARDIAL_INFARCTION}
                    type='checkbox'
                    value={HEALTH_FACTORS.MYOCARDIAL_INFARCTION}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.MYOCARDIAL_INFARCTION}>Previous Myocardial Infarction</label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.PERIPHERAL_VASCULAR_DISEASE}
                    type='checkbox'
                    value={HEALTH_FACTORS.PERIPHERAL_VASCULAR_DISEASE}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.PERIPHERAL_VASCULAR_DISEASE}>Peripheral Vascular Disease</label>
                </div>
                <div className='flex-align'>
                  <input
                    id={HEALTH_FACTORS.PRIOR_CARDIAC_CATHETERIZATION}
                    type='checkbox'
                    value={HEALTH_FACTORS.PRIOR_CARDIAC_CATHETERIZATION}
                    {...register(HEALTH_FACTORS.CLINICAL_HISTORY)}
                  />
                  <label
                    className='margin-0 padding-1 width-100'
                    htmlFor={HEALTH_FACTORS.PRIOR_CARDIAC_CATHETERIZATION}>Prior Cardiac Catheterization, Angioplasty,
                      or Coronary Artery Bypass Surgery</label>
                </div>
              </div>
            </div>
            <section>
              <div className='grid-container'>
                <div className='grid-x small-up-4 align-spaced text-center'>
                  <div className='cell'>
                    <Link
                      className='hollow secondary button large rounded padding-horizontal-2'
                      to={ROUTES.HEART_CONDITION}>Back</Link>
                  </div>
                  <div className='cell'>
                    <input
                      className='button hollow alert large rounded padding-horizontal-2'
                      onClick={() => handleReset()}
                      type='reset'
                      value='Reset Form'/>
                  </div>
                  <div className='cell'>
                    <input
                      className='button success large rounded padding-horizontal-2 margin-right-2'
                      type='submit'
                      value='Next'/>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </div>
      </section>
    </>
  );
};

export default Index;
